import React, { useRef, useState } from 'react';
import { FormControl, FormGroup, Grid, Input } from '@material-ui/core';
import { MdAttachFile } from 'react-icons/md';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import emailjs from 'emailjs-com';
import AttachFile from '@material-ui/icons/AttachFile'
import Button from 'reactstrap/lib/Button';
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert"


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles({
    form: {
        padding: "20px"
    },
    inputs: {
        margin: "15px",
    },
    fileAttach: {},
    buttons: {
        display: "flex",
        justifyContent: "space-evenly",
        marginTop: "20px"
    }
});


const feedbackValues = {
    name: "",
    email: "",
    feedback: "",
    file: ""
}


const FormComponent = ({ setOpenPopUp }) => {

    const [feedback, setFeedback] = useState(feedbackValues);
    const [photo, setPhoto] = useState("");
    const [sent, setSent] = useState(false);
    const [pic, setPic] = useState('ab');
    const [attach, setAttach] = useState();
    const [error,setError] = useState("");
    const formRef = useRef();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleClick = () => {
        setOpen(true);
        setTimeout(() => {
            setOpenPopUp();
        }, 3000);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    const handleChange = (e) => {
        setFeedback({ ...feedback, [e.target.name]: e.target.value });
        console.log(feedback);
    }


    const sendMail = (e) => {
        e.preventDefault();
        if(!validateEmail(feedback.email)){
            setError("Please Enter a Valid Email");
            return;
        }
        const data = new FormData();
        console.log(attach);
        data.append("file", attach);
        data.append("upload_preset", "feedback");
        data.append("cloud_name", "feedbackk");
        fetch("https://api.cloudinary.com/v1_1/feedbackk/image/upload", {
            method: "post",
            body: data
        }).then(res => res.json()).then(data => {
            console.log(data);
            emailjs.send(
                "service_lnt1p82",
                "template_npa3aun",
                {
                    name: feedback.name,
                    email: feedback.email,
                    feedback: feedback.feedback,
                    media: data.secure_url
                },
                "user_1i77O9W3FPe2wGxfXAZV4"
            ).then((s) => {
                setSent(true);
                handleClick();
                console.log(s);
            });
        })
    }

    return (
        <form ref={formRef} onSubmit={sendMail} className={classes.form}>
            <Grid container className="form_feed">
                <Grid item xs={6}>

                    <Input
                        className={classes.inputs}
                        name='name'
                        placeholder='Enter your name'
                        onChange={handleChange}
                    />

                    <Input
                        className={classes.inputs}
                        name='email'
                        placeholder='Enter your email'
                        onChange={handleChange}
                    />

                    <Input
                        className={classes.inputs}
                        name='feedback'
                        placeholder='Enter your feedback'
                        onChange={handleChange}
                    />

                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={(e) => setAttach(e.target.files[0])}
                    />
                    <label htmlFor="raised-button-file" className={classes.inputs}>
                        <span variant="raised">
                            <AttachFile className={classes.fileAttach} /> Attach File
                        </span>

                    </label>
                        <p style={{
                            color:"red",
                            textAlign:"center"
                        }}>{error}</p>
                    <div className={classes.buttons}>
                        <Button type='submit' color="primary">Send</Button>
                        <Button color="danger" onClick={setOpenPopUp}>
                            Cancel
                        </Button>
                    </div>
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            Your Feedback has succesfully sent to the Admin Panel , Thankyou!
                        </Alert>
                    </Snackbar>
                </Grid>

            </Grid>

        </form>
    )
}

export default FormComponent;


