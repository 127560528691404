import React from "react";
import { useEffect } from "react";
import { toast, ToastContainer, Zoom } from "react-toastify";

const customToast = (
  success,
  error,
  warning,
  headingText,
  subHeading,
  anchorText,
  url,
  info
) => {
  if (success === true) {
    toast.success(
      <div className="d-flex flex-col">
        <h1
          style={{
            color: "#979797",
            fontSize: "14px",
          }}
          className="pb-2 font-bold"
        >
          {headingText}
        </h1>
        {/* <span>{res.data.message}</span> */}
        <span
          className="pb-1"
          style={{ fontSize: "13px", }}
        >
          {subHeading}
        </span>
        <a
          href={url}
          style={{
            color: "#3107CB",
            textDecoration: "none",
            fontSize: "13px",
          }}
          onClick={() => {
            if (anchorText == "Logout") {
             localStorage.clear()
            }
          }}
          className="font-bold"
        >
          {anchorText}
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
      }
    );
  } else if (error === true) {
    toast.error(
      <div className="d-flex flex-col">
        <h1
          style={{
            color: "#979797",
            fontSize: "14px",
          }}
          className="pb-2 font-bold"
        >
          {headingText}
        </h1>
        {/* <span>{res.data.message}</span> */}
        <span
          className="pb-1"
          style={{ fontSize: "13px",  }}
        >
          {subHeading}
        </span>
        <a
          href={url}
          style={{
            color: "#3107CB",
            textDecoration: "none",
            fontSize: "13px",
          }}
          className="font-bold"
        >
          {anchorText}
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
      }
    );
  } else if (warning === true) {
    toast.warn(
      <div className="d-flex flex-col">
        <h1
          style={{
            color: "#979797",
            fontSize: "14px",
          }}
          className="pb-2 font-bold"
        >
          {headingText}
        </h1>
        {/* <span>{res.data.message}</span> */}
        <span
          className="pb-1"
          style={{ fontSize: "13px", }}
        >
          {subHeading}
        </span>
        <a
          href={url}
          style={{
            color: "#3107CB",
            textDecoration: "none",
            fontSize: "13px",
          }}
          className="font-bold"
        >
          {anchorText}
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
      }
    );
  } else if (info === true) {
    toast.info(
      <div className="d-flex flex-col">
        <h1
          style={{
            color: "#979797",
            fontSize: "14px",
          }}
          className="pb-2 font-bold"
        >
          {headingText}
        </h1>
        {/* <span>{res.data.message}</span> */}
        <span
          className="pb-1"
          style={{ fontSize: "13px",  }}
        >
          {subHeading}
        </span>
        <a
          href={url}
          style={{
            color: "#3107CB",
            textDecoration: "none",
            fontSize: "13px",
          }}
          className="font-bold"
        >
          {anchorText}
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
      }
    );
  }
};

export default customToast;
