import { memeUsers } from "network/ApiAxios";
import { saveFileLimit } from "network/ApiAxios";
import { fetchUserActivityLogs } from "network/ApiAxios";
import { findOrganizationDetails } from "network/ApiAxios";
import { fetchAppVersions } from "network/ApiAxios";
import { fetchInactiveSessionData } from "network/ApiAxios";
import { getAllAdmins } from "network/ApiAxios";

export const getAllAdminsAction = (orgToken) => async (dispatch) => {
  dispatch({ type: "GET_ALL_ADMINS_REQUEST" });
  try {
    const data = await getAllAdmins(orgToken);
    // console.log(data);
    dispatch({ type: "GET_ALL_ADMINS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_ALL_ADMINS_FAILED", payload: error });
  }
};

export const getAuditActions = (orgToken) => async (dispatch) => {
  dispatch({ type: "GET_AUDIT_DATA_REQUEST" });
  try {
    try {
      const { data } = await fetchInactiveSessionData(orgToken);
      dispatch({
        type: "GET_AUDIT_DATA_SUCCESS",
        payload: data.message.slice(0, 60),
      });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 429 || error.response.status === 403)
      ) {
        dispatch({
          type: "GET_AUDIT_DATA_FAILED",
          payload: error.response.message,
        });
      }
    }
  } catch (error) {
    dispatch({ type: "GET_AUDIT_DATA_FAILED", payload: error });
  }
};

export const getLogsActions = (adminId) => async (dispatch) => {
  dispatch({ type: "GET_LOGS_DATA_REQUEST" });
  try {
    const { data } = await fetchUserActivityLogs(adminId);
    console.log(data, "logging");
    dispatch({
      type: "GET_LOGS_DATA_SUCCESS",
      payload: data.logs,
    });
  } catch (error) {
    dispatch({ type: "GET_LOGS_DATA_FAILED", payload: error });
  }
};

// export const getForwardsDataAction = (orgToken) => async (dispatch) => {
// 	dispatch({ type: "GET_FORWARDS_DATA_REQUEST", loading: true });
// 	try {
// 		const response = await forwards(orgToken);
// 		dispatch({
// 			type: "GET_FORWARDS_DATA_SUCCESS",
// 			payload: response,
// 			loading: false,
// 		});
// 	} catch (error) {
// 		dispatch({
// 			type: "GET_FORWARDS_DATA_FAILED",
// 			payload: error,
// 			loading: true,
// 		});
// 	}
// };

export const setUserId = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "SET_USER_ID_SUCCESS", payload: userId });
  } catch (error) {
    dispatch({ type: "SET_USER_ID_FAILED", payload: error });
  }
};
export const setCurrentDate = (currentDate) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CURRENT_DATE_SUCCESS", payload: currentDate });
  } catch (error) {
    dispatch({ type: "SET_CURRENT_DATE_FAILED", payload: error });
  }
};

export const saveSuccessfulPaymentDetails =
  (planSelected, totalUsers) => async (dispatch) => {
    try {
      dispatch({
        type: "SET_PAYMENT_SUCCESS",
        planSelected: planSelected,
        totalUsers: totalUsers,
      });
    } catch (error) {
      dispatch({
        type: "SET_PAYMENT_FAILED",
        payload: error,
      });
    }
  };

export const setAppVersions = () => async (dispatch) => {
  try {
    try {
      const { data } = await fetchAppVersions();
      dispatch({
        type: "SET_APP_VERSION_SUCCESS",
        payload: data,
      });
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 429 || error.response.status === 403)
      ) {
        dispatch({
          type: "SET_APP_VERSION_FAILED",
          payload: error.response.message,
        });
      }
    }
  } catch (error) {
    dispatch({ type: "SET_APP_VERSION_FAILED", payload: error });
  }
};

export const setOnboardUsers = (orgToken) => async (dispatch) => {
  dispatch({ type: "SET_OB_USERS_REQUEST" });
  try {
    const { data } = await memeUsers(orgToken);
    dispatch({
      type: "SET_OB_USERS_SUCCESS",
      payload: data.users,
    });
  } catch (error) {
    dispatch({ type: "SET_OB_USERS_FAILED", payload: error });
  }
};

export const setFileSize =
  (fileSize, adminEmail, orgToken) => async (dispatch) => {
    dispatch({
      type: "SET_FSL_REQUEST",
      fs: JSON.parse(localStorage.getItem("user"))?.fileSizeLimit,
    });
    try {
      const { data } = await saveFileLimit(fileSize, adminEmail, orgToken);
      dispatch({ type: "SET_FSL_SUCCESS", payload: data, fs: fileSize });
    } catch (error) {
      dispatch({ type: "SET_FSL_FAILED", payload: error });
    }
  };

export const savePaymentInfo =
  (purchasedPlan, amountReceived) => async (dispatch) => {
    try {
      dispatch({
        type: "SET_PAYMENT_INFO_SUCCESS",
        payload: purchasedPlan,
        amountReceived,
      });
    } catch (error) {
      dispatch({ type: "SET_PAYMENT_INFO_FAILED", payload: error });
    }
  };

export const getPaymentDetails = (orgToken) => async (dispatch) => {
  try {
    const data = await findOrganizationDetails(orgToken);

    dispatch({
      type: "SET_PAYMENT_INFO_REQUEST",
      plan: data.msg.planPurchased,
      amt: data.msg.subscriptionAmountPaid,
    });
  } catch (error) {
    dispatch({ type: "SET_PAYMENT_INFO_FAILED", payload: error });
  }
};
