// This function generates a random orgToken which consists of:
// Length: 8 Characters long
// Includes: Alphabets & Numbers only [Include atleast one capital alphabet, others can vary randomly]
// No special symbols

function shuffleString(str) {
  const array = str.split("");
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.join("");
}

const generateRandomOrgToken = () => {
  const length = 8;
  const capitalAlphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseAlphabets = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";

  let orgToken = capitalAlphabets.charAt(
    Math.floor(Math.random() * capitalAlphabets.length)
  );

  orgToken += numbers.charAt(Math.floor(Math.random() * numbers.length));

  orgToken += lowercaseAlphabets.charAt(
    Math.floor(Math.random() * lowercaseAlphabets.length)
  );

  for (let i = 3; i < length; i++) {
    const characters = lowercaseAlphabets + numbers + capitalAlphabets;
    orgToken += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  orgToken = shuffleString(orgToken);

  return orgToken;
};

export default generateRandomOrgToken;
