/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import "./Sidebar.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import FormComponent from "./FormComponent";
import Feedback from "./Feedback";
import { Tooltip, colors } from "@material-ui/core";
import { Generate_keys } from "../../network/ApiAxios";
import { connect } from "react-redux";
import { AiFillHome, AiOutlineShareAlt } from "react-icons/ai";
import { RiSecurePaymentFill, RiUserSharedFill } from "react-icons/ri";
import { MdSettings } from "react-icons/md";
import { IoPricetagSharp, IoShareSocial, IoTimeSharp } from "react-icons/io5";
import { TbFileReport } from "react-icons/tb";
import { getPaymentDetails } from "components/redux/adminActions";

const user = JSON.parse(localStorage.getItem("user"));
let email = user?.email;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    openPopUp: false,
    whichPlan: this.props.whichPlanSubscribed,
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.createKeys.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  async componentDidMount() {
    this.props.fetchPaymentDetails(user?.orgToken);
  }

  componentDidUpdate() {
    this.state.whichPlan =
      JSON.parse(localStorage.getItem("keys"))?.planPurchased &&
      JSON.parse(localStorage.getItem("keys"))?.planPurchased;
    // console.log(this.state.whichPlan, "plan");
  }

  createKeys() {
    console.log("Clicked Enable");
    if (email) {
      Generate_keys(email);
    }
  }

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  setOpenPopUp = () => {
    console.log("sdes");
    this.setState({
      openPopUp: !this.state.openPopUp,
    });
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    // console.log(this.state.whichPlan, "plan");
    return routes.map((prop, key) => {
      return prop.layout === "/admin" ? (
        <NavItem key={key}>
          <NavLink
            to={`${
              (this.state.whichPlan === "basic" ||
                this.state.whichPlan === "undefined") &&
              prop.name === "App Settings"
                ? prop.layout + "/index"
                : this.state.whichPlan === "premium"
                ? prop.layout + prop.path
                : (this.state.whichPlan === "basic" ||
                    this.state.whichPlan === "undefined") &&
                  prop.name === "Track Forwards"
                ? prop.layout + "/index"
                : prop.layout + prop.path
            }`}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName={`${
              this.state.whichPlan === "basic" &&
              (prop.name === "App Settings" || prop.name === "Track Forwards")
                ? ""
                : "active"
            }`}
            style={{ fontSize: "14px" }}
          >
            <i className="">
              {prop.name === "Dashboard" ? (
                <AiFillHome
                  color="#47BE9B"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : prop.name === "App Settings" ? (
                <MdSettings
                  color="#47BE9B"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : prop.name === "Employee Invites" ? (
                <RiUserSharedFill
                  color="#47BE9B"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : prop.name === "Track Forwards" ? (
                <IoShareSocial
                  style={{ width: "22px", height: "22px" }}
                  color="#47BE9B"
                />
              ) : prop.name === "Audit & Sessions" ? (
                <IoTimeSharp
                  color="#47BE9B"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : prop.name === "Subscriptions" ? (
                <IoPricetagSharp
                  color="#47BE9B"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : prop.name === "Credits & Payments" ? (
                <RiSecurePaymentFill
                  color="#47BE9B"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : prop.name === "Log Management" ? (
                <TbFileReport
                  color="#47BE9B"
                  style={{ width: "22px", height: "22px" }}
                />
              ) : (
                ""
              )}
            </i>
            {/* {console.log(prop.path, prop.layout, "hey")} */}
            {prop.name}
            {(prop.name === "Track Forwards" || prop.name === "App Settings") &&
            this.state.whichPlan === "basic" ? (
              <Tooltip title="Upgrade to premium plan to access this">
                <button type="button" className="premium_button">
                  Premium
                </button>
              </Tooltip>
            ) : (
              (prop.name === "Track Forwards" ||
                prop.name === "App Settings") && (
                <button type="button" className="premium_button">
                  Premium
                </button>
              )
            )}
          </NavLink>
        </NavItem>
      ) : null;
    });
  };

  createApiLinks = (routes) => {
    return routes.map((prop, key) => {
      return prop.layout === "/admin" && prop.api ? (
        <NavItem key={key}>
          <NavLink
            to={`${
              (this.state.whichPlan === "basic" ||
                this.state.whichPlan === "undefined") &&
              prop.name === "App Settings"
                ? prop.layout + "/index"
                : this.state.whichPlan === "premium"
                ? prop.layout + prop.path
                : (this.state.whichPlan === "basic" ||
                    this.state.whichPlan === "undefined") &&
                  prop.name === "Track Forwards"
                ? prop.layout + "/index"
                : ""
            }`}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName={`${
              this.state.whichPlan === "basic" &&
              (prop.name === "App Settings" || prop.name === "Track Forwards")
                ? ""
                : "active"
            }`}
          >
            <i className={prop.icon} />
            {prop.name}
            {prop.name === "Track Forwards" && (
              <button
                type="button"
                style={{
                  backgroundColor: "#FF4500",
                  color: "white",
                  marginLeft: "10px",
                  fontSize: "10px",
                  padding: "0px 5px",
                  borderRadius: "5px",
                  opacity: "0.8",
                }}
              >
                Premium
              </button>
            )}
          </NavLink>
        </NavItem>
      ) : null;
    });
  };

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/adminTest/edit-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <div className="input-group">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </div>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}
            <h6 className="navbar-heading text-muted">Documentation</h6>
            {/* Navigation */}
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink href="https://arishti.in/privacy.html" target="_blank">
                  <i className="ni ni-key-25" />
                  Privacy Policies
                </NavLink>
              </NavItem>
              {/* <NavItem>
								<NavLink href=''>
									<i className='ni ni-single-copy-04' />
									APIs Documentation
								</NavLink>
							</NavItem> */}
              {/* <NavItem>
								<NavLink href=''>
									<i className='ni ni-send' />
									Report/Write to us!
								</NavLink>
							</NavItem> */}
              <Button
                onClick={() => this.setOpenPopUp()}
                openPopUp={this.openPopUp}
                setOpenPopUp={this.setOpenPopUp}
                // color="primary"
                className="feedback_button bg-meme"
              >
                Feedback
              </Button>
              {/* <Button onClick={()=>this.createKeys()} openPopUp={this.openPopUp} 
              setOpenPopUp={this.setOpenPopUp  } color="primary" className="feedback_button">
                Enable
              </Button> */}
              {this.state.openPopUp && (
                <Feedback
                  openPopUp={this.state.openPopUp}
                  setOpenPopUp={this.setOpenPopUp}
                />
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => {
  const { whichPlanSubscribed } = state.setPaymentInfoReducer;
  return { whichPlanSubscribed };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPaymentDetails: (orgToken) => dispatch(getPaymentDetails(orgToken)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
