import React from "react";
import { Checkbox } from "@material-ui/core";

const FileTypeCheckbox = ({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  color,
}) => {
  return (
    <div className="d-flex align-items-center">
      <Checkbox
        color={color}
        id={id}
        name={name}
        label={label}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <p style={{ fontSize: "14px", fontWeight: "400" }}>{label}</p>
    </div>
  );
};

export default FileTypeCheckbox;
