import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import "../../styles/Map.css";
const MapComponent = ({ longitude, latitude, google }) => {
  const mapStyle = {
    width: "250px",
    height: "110px",
    borderRadius: "20px",
    overflow: "hidden",
    marginTop: "-50px",

    "@media (min-width: 768px) and (max-width: 1024px)": {
      width: "50px",
      height: "50px",
    },
  };
  return (
    <div
      style={{ position: "absolute", width: "0", height: "0" }}
      className="mapVisibility"
    >
      <Map
        google={google}
        zoom={14}
        initialCenter={{ lat: latitude, lng: longitude }}
        style={{
          width: "250px",
          height: "110px",
          borderRadius: "20px",
          overflow: "hidden",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          marginTop: "-50px",
        }}
      >
        <Marker
          name={"Location"}
          position={{ lat: latitude, lng: longitude }}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(MapComponent);
