export const getDataReducer = (state = { data: {} }, action) => {
	switch (action.type) {
		case "GET_DATA_REQ":
			return {
				...state,
				loading: true,
			};
		case "GET_DATA_SUCCESS":
			return {
				data: action.payload,
				loading: false,
			};
		case "GET_DATA_FAIL":
			return {
				loading: false,
				error: true,
			};
		case "LOGOUT":
			return {
				data: null,
			};
		default:
			return state;
	}
};

export const userReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case "LOAD_USER_REQUEST":
			return {
				loading: true,
				isAuthenticated: false,
			};
		case "LOAD_USER_SUCCESS":
			return {
				...state,
				loading: false,
				isAuthenticated: true,
				user: action.payload,
			};

		case "LOGOUT_SUCCESS":
			return {
				loading: false,
				user: null,
				isAuthenticated: false,
			};
		case "LOAD_USER_FAIL":
			return {
				loading: false,
				isAuthenticated: false,
				user: null,
				error: action.payload,
			};
		default:
			return state;
	}
};

// export const userProfileReducer = (state = {}, action) => {
// 	switch (action.type) {
// 		case "USER_UPDATE_PROFILE_REQUEST":
// 			return { loading: true };
// 		case "USER_UPDATE_PROFILE_SUCCESS":
// 			return { loading: false, success: true, userInfo: action.payload };
// 		case "USER_UPDATE_PROFILE_FAIL":
// 			return { loading: false, error: action.payload };
// 		default:
// 			return state;
// 	}
// };
