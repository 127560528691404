export const getAdminReducer = (state = { adminData: [] }, action) => {
  switch (action.type) {
    case "GET_ALL_ADMINS_REQUEST":
      return { ...state, loading: true };
    case "GET_ALL_ADMINS_SUCCESS":
      return { loading: false, adminData: action.payload };
    case "GET_ALL_ADMINS_FAILED":
      return { loading: true, error: action.payload };
    default:
      return state;
  }
};

export const getAuditReducer = (state = { auditData: [] }, action) => {
  switch (action.type) {
    case "GET_AUDIT_DATA_REQUEST":
      return { ...state, loading: true };
    case "GET_AUDIT_DATA_SUCCESS":
      return { loading: false, auditData: action.payload };
    case "GET_AUDIT_DATA_FAILED":
      return { loading: true, error: action.payload };
    default:
      return state;
  }
};

// export const getForwardsDataReducer = (state = { forwards: [] }, action) => {
// 	switch (action.type) {
// 		case "GET_FORWARDS_DATA_REQUEST":
// 			return { ...state, loading: true };
// 		case "GET_FORWARDS_DATA_SUCCESS":
// 			return { ...state, loading: false, forwards: action.payload };
// 		case "GET_FORWARDS_DATA_FAILED":
// 			return { ...state, loading: true, error: action.payload };
// 		default:
// 			return state;
// 	}
// };

export const setUserIdReducer = (state = { userId: "" }, action) => {
  switch (action.type) {
    case "SET_USER_ID_SUCCESS":
      return { ...state, userId: action.payload };
    case "SET_USER_ID_FAILED":
      return { ...state, userId: action.payload };
    default:
      return state;
  }
};
export const setCurrentDateReducer = (state = { currentDate: "" }, action) => {
  switch (action.type) {
    case "SET_CURRENT_DATE_SUCCESS":
      return { ...state, currentDate: action.payload };
    case "SET_CURRENT_DATE_FAILED":
      return { ...state, currentDate: action.payload };
    default:
      return state;
  }
};

export const setAppVersionReducer = (state = { appVersion: "" }, action) => {
  switch (action.type) {
    case "SET_APP_VERSION_SUCCESS":
      return { ...state, appVersion: action.payload };
    case "SET_APP_VERSION_FAILED":
      return { ...state, appVersion: action.payload };
    default:
      return state;
  }
};

export const setOnboardUsersReducer = (
  state = { onBoardedUsers: [] },
  action
) => {
  switch (action.type) {
    case "SET_OB_USERS_REQUEST":
      return { ...state, loading: true };
    case "SET_OB_USERS_SUCCESS":
      return { ...state, onBoardedUsers: action.payload, loading: false };
    case "SET_OB_USERS_FAILED":
      return { ...state, onBoardedUsers: action.payload, loading: true };
    default:
      return state;
  }
};

export const setFileSizeReducer = (
  state = {
    fsl: "",
    fs: JSON.parse(localStorage.getItem("user"))?.fileSizeLimit,
  },
  action
) => {
  switch (action.type) {
    case "SET_FSL_SUCCESS":
      return { ...state, fsl: action.payload, fs: action.fs };
    case "SET_FSL_FAILED":
      return { ...state, fsl: action.payload };
    default:
      return state;
  }
};

export const setPaymentInfoReducer = (
  state = {
    whichPlanSubscribed: JSON.parse(localStorage.getItem("keys"))
      ?.planPurchased,
    amountReceived: JSON.parse(localStorage.getItem("keys"))
      ?.subscriptionAmountPaid,
  },
  action
) => {
  switch (action.type) {
    case "SET_PAYMENT_INFO_REQUEST":
      return {
        ...state,
        whichPlanSubscribed: action.plan,
        amountReceived: action.amt,
      };
    case "SET_PAYMENT_INFO_SUCCESS":
      return {
        ...state,
        whichPlanSubscribed: action.payload,
        amountReceived: action.amountReceived,
      };
    case "SET_PAYMENT_INFO_FAILED":
      return { ...state, whichPlanSubscribed: action.payload };
    default:
      return state;
  }
};

export const saveSuccessfulPaymentDetailsReducer = (
  state = { planSelected: "", totalUsers: "" },
  action
) => {
  switch (action.type) {
    case "SET_PAYMENT_SUCCESS":
      return {
        ...state,
        planSelected: action.planSelected,
        totalUsers: action.totalUsers,
      };
    case "SET_PAYMENT_FAILED":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const logReducer = (state = { loading: true, logs: [] }, action) => {
  switch (action.type) {
    case "GET_LOGS_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        logs: action.payload,
      };
    case "GET_LOGS_DATA_FAILED":
      return {
        ...state,
        error: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
