import { useState } from "react";
import { Collapse } from "reactstrap";
import MapComponent from "views/examples/MapComponent";

const greenColor = "#47BE9B";
const fontSize = "0.8rem";
const fontWeight = 700;

const toggleDetails = (logId, setOpenDetails) => {
  // console.log(logId);
  setOpenDetails((prevState) => ({
    ...prevState,
    [logId]: !prevState[logId],
  }));
};
const answerBasedOnAction = (userAction, item, openDetails, setOpenDetails) => {
  if (userAction === "PASSWORD_UPDATE") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Changed account password
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b> Account
                password changed by{" "}
                <b style={{ color: greenColor }}>{item.targetId.adminEmail}</b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "WEBAPP_ACTIVATE") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Activated Web Application
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b> Web App
                activated by{" "}
                <b style={{ color: greenColor }}>{item.targetId.adminEmail}</b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "WEBAPP_DEACTIVATE") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Deactivated Web Application
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b> Web App
                deactivated by{" "}
                <b style={{ color: greenColor }}>{item.targetId.adminEmail}</b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "USER_REMOTE_WIPE") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Remotely Wiped User
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b>
                <b style={{ color: greenColor }}>
                  {item.targetId.adminEmail}
                </b>{" "}
                remotely wiped{" "}
                <b style={{ color: "#FF0000" }}>
                  {item.targetId.targetAdminEmail}
                </b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div>
            </div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "ADMIN_PERMISSION_UPDATE") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Updated admin permissions
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "200px" }}
          >
            <div className="d-flex flex-col">
              <div style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b>
                <b style={{ color: greenColor }}>
                  {item.targetId.adminEmail}
                </b>{" "}
                updated the admin permissions for{" "}
                <b style={{ color: "#FF0000" }}>{item.update.email}</b>
                <div className="d-flex flex-col">
                  <b style={{ fontWeight: fontWeight }}>Permissions Given: </b>
                  <div className="pl-2">
                    <li>
                      Remote Wipe:{" "}
                      {item.update.remoteWipe === true ? "Enabled" : "Disabled"}
                    </li>
                    <li>
                      User Deletion:{" "}
                      {item.update.deleteUser === true ? "Enabled" : "Disabled"}
                    </li>
                    <li>
                      FSL:{" "}
                      {item.update.fslPermission === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                    <li>
                      File Sharing Restriction:{" "}
                      {item.update.fileSharingPrevention === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                    <li>
                      Group Settings:{" "}
                      {item.update.groupSettings === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                  </div>
                </div>
              </div>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "FILE_SHARING_RESTRICTION") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Updated File Type Limiter
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "220px" }}
          >
            <div className="d-flex flex-col">
              <div style={{ fontSize: fontSize }}>
                <p style={{ fontSize: fontSize }} className="pt-2">
                  <b style={{ fontWeight: fontWeight }}>Activity: </b>
                  <b style={{ color: greenColor }}>
                    {item.targetId.adminEmail}
                  </b>{" "}
                  updated the file sharing restrictions
                </p>
                <div className="d-flex flex-col">
                  <b style={{ fontWeight: fontWeight }}>
                    File Sharing Permissions:{" "}
                  </b>
                  <div className="pl-2">
                    <li>
                      PNG:{" "}
                      {item.update.fileSharingPrevention.PNG === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                    <li>
                      PDF:{" "}
                      {item.update.fileSharingPrevention.PDF === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                    <li>
                      JPG:{" "}
                      {item.update.fileSharingPrevention.JPG === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                    <li>
                      WAV:{" "}
                      {item.update.fileSharingPrevention.WAV === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                    <li>
                      MP4:{" "}
                      {item.update.fileSharingPrevention.MP4 === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                    <li>
                      HEIC:{" "}
                      {item.update.fileSharingPrevention.HEIC === true
                        ? "Enabled"
                        : "Disabled"}
                    </li>
                  </div>
                </div>
              </div>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "ADMIN_ADDED") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Invited new admin
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b> Invited{" "}
                <b style={{ color: greenColor }}>{item.update?.email}</b> as new
                Admin
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "FSL_UPDATE") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Updated file size limit
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b> FSL Updated
                to {item?.update?.fslUpdate} MB by{" "}
                <b style={{ color: greenColor }}>{item.targetId.adminEmail}</b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div className="bg-red" style={mapStyle}></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "ADMIN_DELETED") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Deleted admin
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b>{" "}
                <b style={{ color: greenColor }}>{item.targetId.adminEmail}</b>{" "}
                deleted{" "}
                <b style={{ color: greenColor }}>
                  {item.targetId?.targetAdminEmail}
                </b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "USER_DELETED") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Deleted the user
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b>
                <b style={{ color: greenColor }}>
                  {item.targetId.adminEmail}
                </b>{" "}
                deleted the user:{" "}
                <b style={{ color: "#FF0000" }}>
                  {item.targetId.targetAdminEmail}
                </b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "GROUP_SETTINGS_UPDATED") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Updated the group settings
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b> Group:
                <b style={{ color: "#FF0000" }}>
                  {item.update.lockUnlockGroup}
                </b>{" "}
                by{" "}
                <b style={{ color: greenColor }}>{item.targetId.adminEmail}</b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
          {/* <div></div> */}
        </Collapse>
      </>
    );
  } else if (userAction === "MFA_SETTINGS_UPDATED") {
    return (
      <>
        <h1
          onClick={() => {
            toggleDetails(item._id, setOpenDetails);
          }}
          className="cursor-pointer"
        >
          <b>{item.targetId.adminEmail}</b> Updated two factor authentication
        </h1>
        <Collapse isOpen={openDetails[item._id]}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: "130px" }}
          >
            <div className="d-flex flex-col">
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Activity: </b> MFA{" "}
                <b style={{ color: "#FF0000" }}>
                  {item.update.mfaEnabled === true ? "enabled" : "disabled"}
                </b>{" "}
                by{" "}
                <b style={{ color: greenColor }}>{item.targetId.adminEmail}</b>
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Browser: </b>
                {item.browser}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>IP Address: </b>
                {item.ipAddress}
              </p>
              <p style={{ fontSize: fontSize }}>
                <b style={{ fontWeight: fontWeight }}>Location: </b>
                {item.location.city}, {item.location.countryName}
              </p>
            </div>
            <MapComponent
              latitude={item.location.latitude}
              longitude={item.location.longitude}
            />

            {/* MAP */}
          </div>
        </Collapse>
      </>
    );
  } else {
  }
};

export { answerBasedOnAction };
