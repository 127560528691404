// this file will convert utc to ist timestamp as mongodb stores time in utc format
const istConversion = (dateToBeConverted) => {
  const inputTimestamp = new Date(dateToBeConverted);
  const istOptions = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  //   const istTimeString = inputTimestamp.toLocaleString("en-IN", istOptions);

  const istTimeString = inputTimestamp
    .toLocaleString("en-IN", istOptions)
    .replace(",", " at")
    .replace(/\./, "")
    .replace(" am", " AM")
    .replace(" pm", " PM");

  return istTimeString;
};

// this file will convert utc to ist timestamp as mongodb stores time in utc format
const findExpiryDate = (dateToBeConverted) => {
  const inputTimestamp = new Date(dateToBeConverted);
  const istOptions = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  //   const istTimeString = inputTimestamp.toLocaleString("en-IN", istOptions);

  const istTimeString = inputTimestamp.toLocaleString("en-IN", istOptions);

  return istTimeString;
};

export { istConversion, findExpiryDate };
