import axios from "axios";
import config from "../config";
import emailjs from "emailjs-com";
import generateIdempotencyKey from "functions/generateIdempotencyKey";
// import getUserLocation from "functions/getUserLocation";
const https = require("http");

// const agent = new https.Agent({
//   rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

// instance.defaults.httpsAgent = agent;

let recaptchaReadyPromise;
if (!recaptchaReadyPromise) {
  recaptchaReadyPromise = new Promise((resolve) => {
    window.initializeRecaptcha = () => {
      resolve();
    };
  });
}

const captcha = config.recaptcha;

instance.interceptors.request.use(async (config) => {
  await recaptchaReadyPromise;
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  // console.log(captcha, "captcha");
  const recaptchaToken = await window.grecaptcha.execute(captcha);
  // console.log(recaptchaToken, "recaptchatoken");

  const idempotencyKey = generateIdempotencyKey();
  // const getLocation = await getUserLocation();
  config.headers["Idempotency-Key"] = idempotencyKey;
  // config.headers["latitude"] = getLocation.latitude;
  // config.headers["longitude"] = getLocation.longitude;
  config.headers["recaptcha-token"] = recaptchaToken;

  return config;
});

export const HeaderAnalytics = async (org_token) =>
  await instance.post("MEMEUsers/HeaderAnalytics", { org_token });

export const applyCoupoun = async (code, org_token) =>
  await instance.post("/coupon/redeem", {
    token: code,
    org_token,
  });

export const getUser = async () =>
  await instance.get("/MEMEUserOperations/getUser");

export const register = async (
  name,
  email,
  number,
  orgToken,
  password,
  phone,
  agency,
  role
) =>
  await instance.post("users/register", {
    name,
    email,
    number,
    orgToken,
    password,
    phone,
    agency,
    role,
  });

export const findOrganizationDetails = async (orgToken) => {
  const { data } = await instance.post(
    "MEMEUserOperations/getOrganizationDetails",
    {
      orgToken,
    }
  );
  return data;
};

export const getCouponsUsed = async (userId) => {
  const { data } = await instance.post("/coupon/fetchCoupons", { userId });
  return data;
};

export const updateRegisterModel = async (
  name,
  email,
  orgToken,
  password,
  token,
  id
) => {
  const { data } = await instance.post("users/updateRegisterModel", {
    name,
    email,
    orgToken,
    password,
    token,
    id,
  });
  return data;
};

export const receiveFeedback = async (name, email, feedback, img) => {
  const { data } = await instance.post("users/sendFeedback", {
    name,
    email,
    feedback,
    img,
  });
  return data;
};

export const sendPrivacyDetails = async (email, policyAcceptedStatus) => {
  const { data } = await instance.post("users/sendPrivacyDetails", {
    email,
    policyAcceptedStatus,
  });
  return data;
};

export const getPolicyStatus = async (email) => {
  const { data } = await instance.post("users/getPrivacyDetails", { email });
  return data;
};

export const getAllAdmins = async (orgToken) => {
  const { data } = await instance.post("users/getAllAdmins", { orgToken });
  return { data };
};

export const deleteNormalAdmin = async (id, adminId) => {
  const { data } = await instance.post("/users/deleteNormalAdmin", {
    id,
    adminId,
  });
  return data;
};

export const confirmRegister = async (id) =>
  await instance.post(`users/confirm/${id}`);

export const newAdmin = async (id) =>
  await instance.post(`users/newAdmin/${id}`);

export const forgotPassword = async (email) =>
  await instance.post("users/forgotpassword", { email });

export const confirmReset = async (id, password) =>
  await instance.post(`users/resetpass/${id}`, { password });

export const Generate_keys = async (email) =>
  await instance.post("users/keygen", { email });

// export const confirmRegister = async id => (
//     await instance.post(`users/confirm/${id}`)
// );
export const login = async (email, password) =>
  await instance.post("users/login", { email, password });

export const loginCheck = async (email, password) =>
  await instance.post("users/loginCheck", {
    email,
    password,
  });

export const logout = async (token) =>
  await instance.post("users/logout", { token });

export const activeSessionDate = async (id) =>
  await instance.post("users/activeSessionDate", { id });

export const deleteActiveSession = async (id) =>
  await instance.post("users/deleteActiveSession", { id });

export const fetchAppVersions = async () =>
  await instance.post("appVersion/getAppDetails");

export const inactiveSessionLogs = async (
  id,
  login,
  client,
  agent,
  name,
  pic,
  orgToken,
  city,
  country
) =>
  await instance.post("/users/inactiveSessionLogs", {
    id,
    login,
    logout,
    client,
    agent,
    name,
    pic,
    orgToken,
    city,
    country,
  });

export const exportAuditData = async (orgToken, duration) => {
  const response = await instance.post("/users/export-data", {
    orgToken,
    duration,
  });
  return response.data;
};

export const fetchInactiveSessionData = async (orgToken) =>
  await instance.post("/users/fetchInactiveSessionData", { orgToken });

export const fetchUserActivityLogs = async (adminId) =>
  await instance.post("/users/fetchUserActivityLogs", { adminId });

export const editUserData = async (userID, name) =>
  await instance.post("/users/edit", {
    userID,
    name,
  });

export const setupTwoFA = async (userId) =>
  await instance.post("/users/setupTwoFA", { userId });

export const disableTwoFA = async (userId) =>
  await instance.post("/users/disableTwoFA", { userId });

export const editAddedBy = async (email) =>
  await instance.post("/users/editAddedBy", { email });

export const getUserInfo = async (email) =>
  await instance.post("/users/getuserinfo", { email });

export const getFileSize = async (orgToken) =>
  await instance.post("/users/getFileSize", { orgToken });

export const fileSharingRestriction = async (
  png,
  pdf,
  jpg,
  mp4,
  heic,
  wav,
  orgToken,
  userId
) =>
  await instance.post("/users/fileSharingRestriction", {
    png,
    pdf,
    jpg,
    mp4,
    heic,
    wav,
    orgToken,
    userId,
  });

export const hashPassword = async (email, pass) =>
  await instance.post("/users/convertHashedPassword", { email, pass });

export const changeCurrentPassword = async (userId, email, pass) =>
  await instance.post("/users/changeCurrentPassword", { userId, email, pass });

export const getTwoFa = async (email) =>
  await instance.post("/users/getTwoFa", { email });

export const sendOtp = async (userID) =>
  await instance.post("/users/otp/request", { userID });

export const sendOtpFor2FA = async (userID) =>
  await instance.post("/users/otp/twoFactor", { userID });

export const sendOtpToNewEmail = async (email, userId) =>
  await instance.post("/users/otp/requestOtpForChangePasswd", {
    email,
    userId,
  });

export const sendOtpToUpdate = async (email, userId) =>
  await instance.post("/users/otp/requestOtpForUpdate", { email, userId });

export const sendOtpToEnable = async (email, userId) =>
  await instance.post("/users/otp/requestOtpForEnable2FA", { email, userId });

export const sendOtpToDisable = async (email, userId) =>
  await instance.post("/users/otp/requestOtpForDisable2FA", { email, userId });

export const verifyOtp = async (userID, otp) =>
  await instance.post("/users/otp/verify", { userID, otp });

// USE THIS - wont send mail to Kanak
export const verifiedOtp = async (userID, otp) =>
  await instance.post("/users/otp/verifyOtp", { userID, otp });

// merge of /verifyOtp and /login
export const verifyAndLogin = async (userID, otp, email, password) => {
  const response = await instance.post("/users/verifyAndLogin", {
    userID,
    otp,
    email,
    password,
  });
  return response;
};

export const validateLogin = async (userId, token) => {
  console.log("hello ");
  const response = await instance.post("/users/verifyLogin", {
    userId,
    token,
  });
  return response;
};
export const verifiedOtpThroughToken = async (userID, otp, token, password) =>
  await instance.post("/users/otp/tokenVerifiedOtp", {
    userID,
    otp,
    token,
    password,
  });

export const verifyRecaptcha = async (value) =>
  await instance.post("/users/verifyRecaptcha", { value });

export const sendEmployeeInvite = async (emails, AdminEmail, orgToken) =>
  await instance.post("MEMEUserOperations/sendEmployeeInvites", {
    emails,
    AdminEmail,
    orgToken,
  });

export const updateRollOutVersion = async (email) =>
  await instance.post("MEMEUserOperations/updateVersion", { email });
export const sendAdminInvite = async (
  email,
  AdminEmail,
  orgToken,
  adminPermissions,
  orgName,
  addedBy
) =>
  await instance.post("MEMEUserOperations/sendAdminInvite", {
    email,
    AdminEmail,
    orgToken,
    adminPermissions,
    orgName,
    addedBy,
  });

// Reinvite admin user
export const reinviteAdminUser = async (email, id) => {
  const { data } = await instance.post("MEMEUserOperations/reInviteAdmin", {
    email,
    id,
  });
  return data;
};

export const checkEmail = async (emails, AdminEmail) =>
  await instance.post("MEMEUserOperations/checkEmail", {
    emails,
    AdminEmail,
  });
export const checkNewAdminEmail = async (email) =>
  await instance.post("MEMEUserOperations/checkNewAdminEmail", { email });
export const userCount = async (org_token) =>
  await instance.post("MEMEUsers/HeaderAnalytics", { org_token });
export const memeUsers = async (org_token) =>
  await instance.post("MEMEUsers/all", { org_token });
export const groupInfo = async (orgToken) =>
  await instance.post("MEMEUsers/getGroupInfo", { orgToken });
export const lockGroup = async (groupId, value, groupName, orgToken, adminId) =>
  await instance.post("MEMEUsers/updateGroupSettings", {
    groupId,
    value,
    groupName,
    orgToken,
    adminId,
  });
export const remoteWipe = async (user_id, wipe, adminId) =>
  await instance.post("MEMEUsers/RemoteWipeTrue", { user_id, wipe, adminId });

export const deleteInvitedUser = async (user_id) => {
  try {
    await instance.post("MEMEUsers/deleteInvitedUser", { user_id });
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const purchasePlan = async (
  orgToken,
  basic,
  premium,
  enterprise,
  userLimit,
  totalAmount
) => {
  await instance.post("/MEMEUserOperations/saveSubscription", {
    orgToken,
    basic,
    premium,
    enterprise,
    userLimit,
    totalAmount,
  });
};
export const buyPlans = async (
  orgToken,
  basic,
  premium,
  enterprise,
  userLimit,
  totalAmount,
  orgId,
  currentUserLimit,
  email
) => {
  const response = await instance.post("/payments/create-checkout-session", {
    orgToken,
    basic,
    premium,
    enterprise,
    userLimit,
    totalAmount,
    orgId,
    currentUserLimit,
    email,
  });
  return response.data;
};

export const fetchStripePayments = async (email, orgToken) => {
  const response = await instance.post("/payments/fetchStripePayments", {
    email,
    orgToken,
  });
  return response;
};

export const updateAdminPermissions = async (
  email,
  deleteUser,
  fsl,
  groupSettings,
  wipe,
  fileSharingPrevention,
  userId
) => {
  const response = await instance.post(
    "/MEMEUserOperations/updateAdminPermissions",
    {
      email,
      deleteUser,
      fsl,
      groupSettings,
      wipe,
      fileSharingPrevention,
      userId,
    }
  );
  return response;
};

export const saveUserToBeDeleted = async (
  email,
  orgToken,
  name,
  userId,
  invitation_timestamp,
  addedBy,
  adminId
) => {
  try {
    await instance.post("MEMEUsers/saveUserToBeDeleted", {
      email,
      orgToken,
      name,
      userId,
      invitation_timestamp,
      addedBy,
      adminId,
    });
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteKMSPairs = async (asymKey, symKey, orgToken, adminId) => {
  try {
    const response = await instance.post("MEMEUsers/deleteKMSKeys", {
      asymKey,
      symKey,
      orgToken,
      adminId,
    });
    return response;
  } catch (error) {}
};

export const saveDataToDB = async (key, orgToken) => {
  try {
    await instance.post("/MEMEUserOperations/saveDataToDb", {
      key,
      orgToken,
    });
    return "ok";
  } catch (error) {
    console.log(error);
  }
};

export const saveAsymDataToDB = async (asymKey, orgToken) => {
  try {
    await instance.post("/MEMEUserOperations/saveAsymDataToDb", {
      asymKey,
      orgToken,
    });
    return "ok";
  } catch (error) {
    console.log(error);
  }
};

export const saveAsymServerPKDataToDB = async (
  asymServerPublicKey,
  orgToken,
  adminId
) => {
  try {
    await instance.post("/MEMEUserOperations/saveAsymServerPKDataToDB", {
      asymServerPublicKey,
      orgToken,
      adminId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const saveFileLimit = async (fileSize, orgToken, userId) => {
  try {
    const res = await instance.post("/MEMEUserOperations/fileSize", {
      fileSize,
      orgToken,
      userId,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveProfilePicture = async (AdminEmail, picture) => {
  try {
    const res = await instance.post("/MEMEUserOperations/saveProfilePicture", {
      AdminEmail,
      picture,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const forwards = async (org_token) =>
  await instance.post("MEMEUsers/Forwards", { org_token });
export const messageChain = async (forward_message_ID, orignal_sender) =>
  await instance.post("MEMEUsers/messageChain", {
    forward_message_ID,
    orignal_sender,
  });
export const getIDS = async (userIDS) =>
  await instance.post("MEMEUsers/getIDS", {
    userIDS,
  });
export const test = (email) => {
  for (var i = 0; i < email.length; i++) {
    // Trim the excess whitespace.
    email[i] = email[i].replace(/^\s*/, "").replace(/\s*$/, "");
    console.log("email is:" + email[i]);
    var templateParams = {
      email: email[i],
    };
    emailjs
      .send(
        "service_ejfmbcd",
        "template_z33d9h8",
        templateParams,
        "user_9sal2DGcu28eUmxfTOX8v"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  }
};
