import { useEffect } from "react";

export default function useClickOutside(ref, fun) {
  useEffect(() => {
    const listener = (e) => {
      console.log(ref, e.target, "hello");
      if (!ref.current || ref.current.contains(e.target)) {
        console.log("hii");
        return;
      }
      console.log("hii2");

      fun();
    };
    console.log("hii3");
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref]);
}
