import React, { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "../../assets/css/new.css";
import Form from "react-bootstrap/Form";
import Cards from "@material-tailwind/react/Card";
import Paragraph from "@material-tailwind/react/Paragraph";
import H6 from "@material-tailwind/react/Heading6";
import { FaFileExport } from "react-icons/fa";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Privacy from "../../assets/img/PrivacyPolicy/Privacy.svg";
import newVersion from "../../assets/img/updates/newUpdates.svg";
import { Link, Navigate, NavLink, useHistory, useNavigate } from "react-router-dom";

import {
  Card,
  CardHeader,
  Row,
  Table,
  Modal,
  Input,
  FormGroup,
  InputGroupText,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import {
  checkNewAdminEmail,
  deleteInvitedUser,
  findOrganizationDetails,
  getFileSize,
  groupInfo,
  lockGroup,
  memeUsers,
  remoteWipe,
  saveUserToBeDeleted,
  sendAdminInvite,
} from "../../network/ApiAxios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import ButtonComp from "./Button";
import ReactPaginate from "react-paginate";
import { getDataAction } from "components/redux/dataaction";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminsAction } from "components/redux/adminActions";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import { AiFillStar } from "react-icons/ai";
import customToast from "./ToastComponent";
import { FcGenericSortingAsc } from "react-icons/fc";
import { ThreeDots } from "react-loader-spinner";
import subs from "../../assets/img/subscription/subs1.svg";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { getPaymentDetails } from "components/redux/adminActions";
import { getLogsActions } from "components/redux/adminActions";
import { MdCancel } from "react-icons/md";
import { Radio } from "@material-ui/core";
import { generateFileNameForUserExport } from "functions/generateFileName";
import { istConversion } from "functions/timeConversion";
import { generateFileNameForGroupExport } from "functions/generateFileName";
import Heading6 from "@material-tailwind/react/Heading6";

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  let orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
  let loggedInUserName = JSON.parse(localStorage.getItem("user"))?.name;
  const clientIp = JSON.parse(localStorage.getItem("ip"));
  const orgName = JSON.parse(localStorage.getItem("user"))?.orgName;
  let userId = JSON.parse(localStorage.getItem("user"))?._id;
  let superAdmin = JSON.parse(localStorage.getItem("user"))?.isSuperAdmin;
  // ADMIN PERMISSION - WHETHER HE CAN DELETE OR REMOTE WIPE OR NOT
  const permission = JSON.parse(localStorage.getItem("user"))?.adminPermissions;
  let defaultFileSize = JSON.parse(localStorage.getItem("fsl"));
  const isPremiumPurchased = JSON.parse(
    localStorage.getItem("keys")
  )?.planPurchased;
  const { adminData } = useSelector((state) => state.getAdminReducer);
  const { whichPlanSubscribed } = useSelector(
    (state) => state.setPaymentInfoReducer
  );
  const { data } = useSelector((state) => state.getDataReducer);
  const doesKeysExist = JSON.parse(localStorage.getItem("keys"));
  const [adminPermissions, setAdminPermissions] = useState({
    delete: false,
    wipe: false,
    FSL: false,
    groupSettings: false,
  });
  const [modal, setModal] = useState(false);
  const [usersList, setUsersList] = useState(true);
  const [groupList, setGroupList] = useState(false);
  const [lockBtnLoader, setLockBtnLoader] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userExport, setUserExport] = useState(false);
  const [groupExport, setGroupExport] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [selectedGroupFormat, setSelectedGroupFormat] = useState("");
  const [selectUserType, setSelectUserType] = useState({
    all: false,
    pending: false,
    wiped: false,
    onboarded: false,
  });
  const [selectGroupType, setSelectGroupType] = useState({
    locked: false,
    unlocked: false,
    all: false,
  });
  const [alphabeticalOrder, setAlphabeticalOrder] = useState(true);
  const [deptOrder, setDeptOrder] = useState(false);
  const [email, setEmail] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [currGrpPage, setCurrGrpPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [adminModal, setAdminModal] = useState(false);
  const [groupMembersModal, setGroupMembersModal] = useState(false);
  const [userForWipe, setUserForWipe] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [doReRender, setDoReRender] = useState(0);
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  const [deleteU, setDeleteU] = useState(false);
  const [terms, setTerms] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchGroupTerm, setSearchGroupTerm] = useState("");
  const [searchedGroupTerm, setSearchedGroupTerm] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState([]);
  const [groups, setGroups] = useState([]);
  const [popupGroupMembers, setPopupGroupMembers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const dispatch = useDispatch();
  const [warning, setWarining] = useState(false);
  const history = useHistory();

  let fixedFileSharingCustomButtons = []
  const naviagteToAppSettings = () => {
    setWarining(false);
    history.push('/admin/integrations');
  };

  const closeWarning = () => {
    console.log("hello")
    setWarining(false);
  };
  const toggle = () => setModal(!modal);
  const toggleDelete = () => {
    setDeleteModal(!deleteModal);
    setDeleteU(false);
  };
  const newAdmin = () => setAdminModal(!adminModal);
  const toggleGroupMembers = () => {
    // setPopupGroupMembers(g);
    setGroupMembersModal(!groupMembersModal);
  };
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleUserExport = () => {
    setUserExport(!userExport);
    setSelectedFormat("");
    setSelectUserType("");
  };
  const toggleGroupExport = () => {
    setGroupExport(!groupExport);
    setSelectedGroupFormat("");
    setSelectGroupType("");
  };

  // console.log(users);
  // console.log("DELETING USER", deletingUser);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  useEffect(() => {
    dispatch(getAllAdminsAction(orgToken));
    dispatch(getPaymentDetails(orgToken));
    dispatch(getLogsActions(userId));
    const f = async () => {
      const getOrg = await findOrganizationDetails(orgToken);
      if (getOrg.success === true) {
        localStorage.setItem("keys", JSON.stringify(getOrg.msg));

        fixedFileSharingCustomButtons = Object.entries(
          getOrg.msg.fileSharingRestriction
        ).filter(([key, value]) => value)
        console.log("orgdetaisl", getOrg.msg.fileSharingRestriction,)

        if (fixedFileSharingCustomButtons.length <= 0)
          setWarining(true);
      }
    };
    f();
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      setLoadingUsers(true);
      try {
        const response = await memeUsers(orgToken);
        // console.log("useEffect rann for 1st time", response);
        const { data } = response;
        setLoadingUsers(false);
        // console.log("users data", data.users);
        if (data.success) {
          setUsers(data.users);
        }

        if (!defaultFileSize || !doesKeysExist) {
          const fileSize = await getFileSize(orgToken);
          // console.log(data, "heyo");
          if (fileSize.data.success === true) {
            localStorage.setItem("fsl", JSON.stringify(fileSize.data.fsl));
            localStorage.setItem(
              "keys",
              JSON.stringify(fileSize.data.organization)
            );
          }
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 429 || error.response.status === 403)
        ) {
          customToast(
            "",
            true,
            "",
            "Too many requests",
            `${error.response.message}`,
            "Reload the page",
            ""
          );
        }
      }

      // dispatch({ type: "SET_PAYMENT_INFO_REQUEST" });
    };
    orgToken && runAsync();
  }, [orgToken]);

  useEffect(() => {
    const f = async () => {
      setLoadingUsers(true);
      const grp = await groupInfo(orgToken);
      // console.log(grp, "gge");
      setLoadingUsers(false);
      if (grp.data.success === true) {
        setGroups(grp.data.groups);
      }
    };
    f();
  }, [groupList, lockBtnLoader]);
  // console.log(groups, "gg");

  useEffect(() => {
    console.log("Rerendered");
  }, [doReRender]);

  const allowAccessFn = async () => {
    var tempUsers = users;
    tempUsers[
      tempUsers.findIndex((item) => item.user_id === userForWipe.user_id)
    ].remote_wipe_request = !userForWipe.remote_wipe_request;
    setUsers(tempUsers);
  };

  // Function to find group admin details
  const findGroupAdmins = (group) => {
    return group.admin.map((g) => g);
  };

  // function to find group member details
  const findGroupMembers = (group) => {
    return group.members.map((member) => member.Name);
  };

  // Function to download pdf/excel of users
  const downloadExportedData = async () => {
    if (usersList === true) {
      try {
        if (users.length === 0) {
          customToast(
            true,
            "",
            "",
            `Data do not exists`,
            "Data will be generated when you invite new users.",
            "",
            ""
          );
        }

        const fileName = generateFileNameForUserExport(selectUserType);
        if (selectedFormat === "pdf") {
          const doc = new jsPDF();
          const columns = ["Name", "Email Address", "User Status"];
          const headerText = "MessageMe Platform User Status Report";
          // doc.setFontSize(16);
          doc.text(headerText, doc.internal.pageSize.width / 2, 15, "center");

          // doc.addImage(img, "PNG", 160, 10, 40, 40);
          // console.log(
          //   orgName,
          //   orgToken,
          //   istConversion(loginTime).trim().substring(0, 20),
          //   loggedInUserName,
          //   data.users.toString(),
          //   clientIp
          // );

          const date = new Date();
          const keyValues = [
            { key: "Organization Name", value: orgName },
            { key: "Organization Token", value: orgToken },
            { key: "Log Generated at", value: istConversion(date) },
            {
              key: "Log Generated by",
              value:
                superAdmin === true
                  ? loggedInUserName + " (Super Admin)"
                  : loggedInUserName + " (Admin)",
            },
            { key: "Total Number of Users", value: data.users.toString() },
            { key: "Log Generated IP", value: clientIp },
          ];
          const maxKeyWidth = Math.max(
            ...keyValues.map(({ key }) => doc.getTextWidth(key))
          );
          // doc.setFillColor(350, 350, 350); // Greyish background color
          let yPos = 10;

          for (const { key, value } of keyValues) {
            doc.setFont("bold");
            doc.setFontSize(12);
            doc.setFillColor(230, 230, 230); // Greyish background color

            doc.setDrawColor(0);

            // Adjusted the width to cover the full width
            // doc.rect(15, yPos + 10, 180, 10, "F");
            doc.rect(15, yPos + 10, 180, 10, "F");
            doc.setTextColor(0, 0, 0);
            doc.text(`${key}:`, 16, yPos + 16);
            doc.setTextColor(100, 100, 100);
            const keyWidth = doc.getTextWidth(`${key}:`);
            doc.text(`${value}`, 18 + keyWidth, yPos + 16);

            // doc.text(`${key}:`, 16, yPos + 16);

            // Calculate the width of the key and use it to adjust the starting position of the value
            // const keyWidth = doc.getTextWidth(`${key}:`);
            // doc.text(value, 50 + keyWidth, yPos + 16);

            yPos += 10;
          }
          yPos += 5;
          // filtration of user selection choice
          if (selectUserType.all) {
            const rows = users.map((record) => [
              record.Name ? record.Name : "Not Onboarded Yet",
              record.Email,
              record.Name
                ? "Onboarded"
                : record.remote_wipe_request === true
                  ? "Wiped"
                  : "Pending",
            ]);
            doc.setFillColor(255, 255, 255);
            doc.autoTable({
              startY: yPos + 10,
              head: [columns],
              body: rows,
            });
          } else if (selectUserType.onboarded) {
            // console.log("hi");
            const filteredUsers = users.filter((u) => u.Name);
            // console.log(filteredUsers, "ff");
            const rows = filteredUsers.map((record) => [
              record.Name,
              record.Email,
              "Onboarded",
            ]);

            doc.autoTable({
              startY: yPos + 10,
              head: [columns],
              body: rows,
            });
          } else if (selectUserType.wiped) {
            const remoteWipeUsers = users.filter(
              (u) => u.remote_wipe_request === true
            );

            const rows = remoteWipeUsers.map((record) => [
              record.Name,
              record.Email,
              "Wiped",
            ]);

            doc.autoTable({
              startY: yPos + 10,
              head: [columns],
              body: rows,
            });
          } else if (selectUserType.pending) {
            const pendingUsers = users.filter((u) => !u.Name);

            const rows = pendingUsers.map((record) => [
              "Not Onboarded Yet",
              record.Email,
              "Pending",
            ]);

            doc.autoTable({
              startY: yPos + 10,
              head: [columns],
              body: rows,
            });
          }

          doc.save(`${fileName}.pdf`);
        } else if (selectedFormat === "excel") {
          // console.log("hii");
          if (selectUserType.all) {
            const filteredData = users.map((record) => ({
              Name: record.Name ? record.Name : "Not Onboarded Yet",
              "Email Address": record.Email,
              "User Status": record.Name
                ? "Onboarded"
                : record.remote_wipe_request === true
                  ? "Wiped"
                  : "Pending",
            }));

            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              "MessageMe User Status Report"
            );

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          } else if (selectUserType.pending) {
            const pendingUsers = users.filter((u) => !u.Name);
            const filteredData = pendingUsers.map((record) => ({
              Name: "Not Onboarded Yet",
              "Email Address": record.Email,
              "User Status": "Pending",
            }));

            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              "MessageMe User Status Report"
            );

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          } else if (selectUserType.onboarded) {
            const pendingUsers = users.filter((u) => u.Name);
            const filteredData = pendingUsers.map((record) => ({
              Name: record.Name,
              "Email Address": record.Email,
              "User Status": "Onboarded",
            }));

            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              "MessageMe User Status Report"
            );

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          } else if (selectUserType.wiped) {
            const wipedUsers = users.filter(
              (u) => u.remote_wipe_request === true
            );
            const filteredData = wipedUsers.map((record) => ({
              Name: record.Name,
              "Email Address": record.Email,
              "User Status": "Wiped",
            }));

            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              "MessageMe User Status Report"
            );

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        if (groups.length === 0) {
          customToast(
            true,
            "",
            "",
            `Data do not exists`,
            "Data will be generated when you create new groups on MessageMe App.",
            "",
            ""
          );
        }

        const fileName = generateFileNameForGroupExport(selectGroupType);
        if (selectedGroupFormat === "pdf") {
          const doc = new jsPDF();
          const columns = ["Group Name", "Admin", "Members", "Group Status"];
          const headerText = "MessageMe Platform Group Status Report";
          // doc.setFontSize(16);
          doc.text(headerText, doc.internal.pageSize.width / 2, 15, "center");

          // doc.addImage(img, "PNG", 160, 10, 40, 40);
          // console.log(
          //   orgName,
          //   orgToken,
          //   istConversion(loginTime).trim().substring(0, 20),
          //   loggedInUserName,
          //   data.users.toString(),
          //   clientIp
          // );

          const date = new Date();
          const keyValues = [
            { key: "Organization Name", value: orgName },
            { key: "Organization Token", value: orgToken },
            { key: "Log Generated at", value: istConversion(date) },
            {
              key: "Group Log's Generated by",
              value:
                superAdmin === true
                  ? loggedInUserName + " (Super Admin)"
                  : loggedInUserName + " (Admin)",
            },
            { key: "Total Number of Users", value: data.users.toString() },
            { key: "Log Generated IP", value: clientIp },
          ];
          const maxKeyWidth = Math.max(
            ...keyValues.map(({ key }) => doc.getTextWidth(key))
          );

          let yPos = 10;

          for (const { key, value } of keyValues) {
            doc.setFont("bold");
            doc.setFontSize(12);
            doc.setFillColor(230, 230, 230); // Greyish background color

            doc.setDrawColor(0);
            doc.rect(15, yPos + 10, 180, 10, "F");
            doc.setTextColor(0, 0, 0);
            doc.text(`${key}:`, 16, yPos + 16);
            doc.setTextColor(100, 100, 100);
            const keyWidth = doc.getTextWidth(`${key}:`);
            doc.text(`${value}`, 18 + keyWidth, yPos + 16);

            yPos += 10;
          }
          yPos += 5;
          // filtration of user selection choice
          if (selectGroupType.all) {
            const rows = groups.map((record) => [
              record.group_name,
              findGroupAdmins(record).join(", "),
              findGroupMembers(record).join(", "),
              record.isGroupLocked === false ? "Unlocked" : "Locked",
            ]);
            doc.setFillColor(255, 255, 255);
            doc.autoTable({
              startY: yPos + 10,
              head: [columns],
              body: rows,
            });
          } else if (selectGroupType.locked) {
            // console.log("hi");
            const filteredGroups = groups.filter(
              (u) => u.isGroupLocked === true
            );
            // console.log(filteredUsers, "ff");
            const rows = filteredGroups.map((record) => [
              record.group_name,
              findGroupAdmins(record).join(", "),
              findGroupMembers(record).join(", "),
              "Locked",
            ]);

            doc.autoTable({
              startY: yPos + 10,
              head: [columns],
              body: rows,
            });
          } else if (selectGroupType.unlocked) {
            const unlockedGroups = groups.filter(
              (u) => u.isGroupLocked === false
            );

            const rows = unlockedGroups.map((record) => [
              record.group_name,
              findGroupAdmins(record).join(", "),
              findGroupMembers(record).join(", "),
              "Unlocked",
            ]);

            doc.autoTable({
              startY: yPos + 10,
              head: [columns],
              body: rows,
            });
          }

          doc.save(`${fileName}.pdf`);
        } else if (selectedGroupFormat === "excel") {
          // console.log("hii");
          if (selectGroupType.all) {
            const filteredData = groups.map((record) => ({
              "Group Name": record.group_name,
              "Group Admin": findGroupAdmins(record).join(", "),
              "Group Members": findGroupMembers(record).join(", "),
              "Group Status":
                record.isGroupLocked === false ? "Unlocked" : "Locked",
            }));

            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              "MessageMe Group Status Report"
            );

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          } else if (selectGroupType.locked) {
            const lockedGroups = groups.filter((u) => u.isGroupLocked === true);
            const filteredData = lockedGroups.map((record) => ({
              "Group Name": record.group_name,
              "Group Admin": findGroupAdmins(record).join(", "),
              "Group Members": findGroupMembers(record).join(", "),
              "User Status": "Locked",
            }));

            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              "MessageMe Group Status Report"
            );

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          } else if (selectGroupType.unlocked) {
            const unlockedGroups = groups.filter(
              (u) => u.isGroupLocked === false
            );
            const filteredData = unlockedGroups.map((record) => ({
              "Group Name": record.group_name,
              "Group Admin": findGroupAdmins(record).join(", "),
              "Group Members": findGroupMembers(record).join(", "),
              "Group Status": "Unlocked",
            }));

            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              "MessageMe Group Status Report"
            );

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const remoteWipeFn = async () => {
    toggle();
    if (userForWipe?.Name === null || !userForWipe?.Name) {
      customToast(
        "",
        true,
        "",
        "User not registered",
        "The user has not done registration yet",
        "",
        ""
      );
    }
    await remoteWipe(
      userForWipe.user_id,
      !userForWipe.remote_wipe_request,
      userId
    )
      .then((res) => {
        console.log("remote wipe done");
        if (res.data.result) {
          console.log("remote wipe got response");

          customToast(
            true,
            "",
            "",
            "User Data Wiped",
            "User will no longer be able to access the app and data",
            "",
            ""
          );
          var tempUsers = users;
          tempUsers[
            tempUsers.findIndex((item) => item.user_id === userForWipe.user_id)
          ].remote_wipe_request = !userForWipe.remote_wipe_request;
          setUsers(tempUsers);
        } else {
          customToast(
            "",
            true,
            "",
            "Remote wipe failed",
            "Some error occurred, try again",
            "",
            ""
          );
        }
      })
      .catch((err) => {
        customToast(
          "",
          true,
          "",
          "Some error occurred",
          "Try again within few minutes",
          "Go to Dashboard",
          "/admin/index"
        );
      });
    setDoReRender((doReRender + 1) % 2);
  };

  // DESC
  // This function is replica of the above one, I have just disabled the toggle setting
  // so if user clicks delete then data will be deleted, also remote wipe will be called
  const remoteWipeFnn = async () => {
    // COMMENTED CAUSE CREATING 2 POPUPS
    if (userForWipe?.Name === null || !userForWipe?.Name) {
      return;
      // toast.error(
      // 	<span>
      // 		<i className='fas fa-times'></i>&nbsp;&nbsp; This user has not
      // 		registered yet
      // 	</span>,
      // 	{
      // 		position: "top-right",
      // 		autoClose: 5000,
      // 		hideProgressBar: false,
      // 		closeOnClick: true,
      // 		pauseOnHover: true,
      // 		draggable: true,
      // 		progress: undefined,
      // 	}
      // );
    }
    await remoteWipe(
      userForWipe?.user_id,
      !userForWipe?.remote_wipe_request,
      userId
    )
      .then((res) => {
        console.log("remote wipe done");
        if (res.data.result) {
          console.log("remote wipe got response");
          customToast(
            true,
            "",
            "",
            "User Data Wiped",
            "User will no longer be able to access the app and data",
            "",
            ""
          );
          var tempUsers = users;
          tempUsers[
            tempUsers.findIndex(
              (item) => item?.user_id === userForWipe?.user_id
            )
          ].remote_wipe_request = !userForWipe.remote_wipe_request;
          setUsers(tempUsers);
        } else {
          customToast(
            "",
            true,
            "",
            "Remote wipe failed",
            "Some error occurred, try again",
            "",
            ""
          );
        }
      })
      .catch((err) => {
        customToast(
          "",
          true,
          "",
          "Some error occurred",
          "Try again within few minutes",
          "Go to Dashboard",
          "/admin/index"
        );
      });
    setDoReRender((doReRender + 1) % 2);
  };

  const grabId = async () => {
    var tempUsers = users;
    tempUsers[
      tempUsers.findIndex((item) => item.user_id === deletingUser.user_id)
    ]._id = !deleteUser._id;
    setDeletingUser(tempUsers);
  };

  const deleteUser = async () => {
    toggleDelete();
    console.log(deletingUser, "log");
    try {
      if (userForWipe?.remote_wipe_request === false) {
        remoteWipeFnn();
      }
      // before deleting the user we are going to save the user in user_deleted collection
      await saveUserToBeDeleted(
        deletingUser.Email,
        orgToken,
        deletingUser?.Name,
        deletingUser?.user_id,
        deletingUser.invitation_timestamp,
        deletingUser.addedBy,
        userId // userId of the one who is deleting the users
      );
      const response = await deleteInvitedUser(deletingUser._id);
      if (response === "ok") {
        console.log("deleted user got response");

        customToast(
          true,
          "",
          "",
          "User Deleted",
          "User has been removed from your organization",
          "Invite More",
          "/admin/employee-invite"
        );
        var tempUsers = users;
        tempUsers[
          tempUsers.findIndex((item) => item.user_id === deletingUser.user_id)
        ]._id = !deleteUser._id;
        setDeletingUser(tempUsers);
        setUsers(users.filter((data) => data._id !== deletingUser._id));
        dispatch(getDataAction());
      } else {
        customToast(
          "",
          true,
          "",
          "User Deletion Failed",
          "Operation failed, try again later",
          "Go to Dashboard",
          "/admin/index"
        );
      }
      dispatch(getDataAction());
    } catch (error) {
      customToast(
        "",
        true,
        "",
        "Try Again",
        "Something went wrong",
        "Go to Dashboard",
        "/admin/index"
      );
    }
    setDoReRender((doReRender + 1) % 2);
  };

  const handleClick = (user) => {
    setUserForWipe(user);
    toggle();
  };

  const handleDeleteUser = (user) => {
    setDeletingUser(user);
    toggleDelete();
  };
  const handleNewAdmin = (email) => {
    // email should be sent
    setAdminModal(email);
    newAdmin();
  };

  const addNewAdmin = async () => {
    newAdmin();
    // try {
    // console.log("hola", email);

    const AdminEmail = JSON.parse(localStorage.getItem("user"))?.email;
    const orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
    var returnedEmail = "";
    // checking for if entered email already exists
    console.log(email, "email");
    if (!validateEmail(email)) {
      setError("Please Enter a Valid Email");

      customToast(
        "",
        true,
        "",
        "Email Address not found",
        "Enter correct email address",
        "Go to Dashboard",
        "/admin/index"
      );
    } else {
      await checkNewAdminEmail(email).then((res) => {
        console.log(res, "resss log");
        if (res.data.success === 1) {
          customToast(
            "",
            true,
            "",
            "Email Address already exist",
            "Kindly use another email address",
            "Go to Dashboard",
            "/admin/index"
          );
        }
        // } else if (res.data.success === true) {
        // 	toast.success(
        // 		<span>
        // 			<i className='fas fa-times'></i>&nbsp;&nbsp;{res.data.message}
        // 		</span>,
        // 		{
        // 			position: "top-right",
        // 			autoClose: 5000,
        // 			hideProgressBar: false,
        // 			closeOnClick: true,
        // 			pauseOnHover: true,
        // 			draggable: true,
        // 			progress: undefined,
        // 		}
        // 	);
        // }
        returnedEmail = res.data.email;
      });
    }

    // sends email to new users
    if (returnedEmail?.length > 0) {
      await sendAdminInvite(
        returnedEmail,
        AdminEmail,
        orgToken,
        adminPermissions,
        orgName,
        userId
      )
        .then((res) => {
          if (res.data.success === 2) {
            // console.log("ressstruee", res);

            customToast(
              true,
              "",
              "",
              "New Admin Added",
              "New admin with designated privileges have been added",
              "Go to Profile",
              "/adminTest/edit-profile"
            );
          } else if (res.data.success === 1) {
            console.log("resss111", res);
            customToast(
              "",
              true,
              "",
              "Some error occurred",
              `${res.data.message}`,
              "",
              ""
            );
          }
          // window.location.reload();

          // } else {
          // 	toast.error(
          // 		<span>
          // 			<i className='fas fa-times'></i>&nbsp;&nbsp;Failed to send
          // 			Email
          // 		</span>,
          // 		{
          // 			position: "top-right",
          // 			autoClose: 5000,
          // 			hideProgressBar: false,
          // 			closeOnClick: true,
          // 			pauseOnHover: true,
          // 			draggable: true,
          // 			progress: undefined,
          // 		}
          // 	);
          // }
        })
        .catch(() => {
          customToast(
            "",
            true,
            "",
            "Some error occurred",
            `Try again later`,
            "",
            ""
          );
        });
    }

    setEmail("");
    // }
    // catch (error) {
    // 	toast.error(
    // 		<span>
    // 			<i className='fas fa-times'></i>&nbsp;&nbsp;Failed to send email!
    // 		</span>,
    // 		{
    // 			position: "top-right",
    // 			autoClose: 5000,
    // 			hideProgressBar: false,
    // 			closeOnClick: true,
    // 			pauseOnHover: true,
    // 			draggable: true,
    // 			progress: undefined,
    // 		}
    // 	);
    // }
  };

  const searchHandler = async () => {
    if (groupList === true) {
      var searchedGroupName = groups.filter((g) =>
        g.group_name.toUpperCase().includes(searchGroupTerm.toUpperCase())
      );
      setSearchedGroupTerm(searchedGroupName);
    } else {
      var searchedEmail = users.filter((u) =>
        u.Email.toUpperCase().includes(searchTerm.toUpperCase())
      );

      setSearchedTerm(searchedEmail);
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrPage(selectedPage);
    setSearchTerm([]);
    setSearchTerm("");
  };
  const handleGroupPageClick = ({ selected: selectedPage }) => {
    setCurrGrpPage(selectedPage);
    setSearchGroupTerm([]);
    setSearchGroupTerm("");
  };
  const PER_PAGE = 10;
  const offset = currPage * PER_PAGE;
  const PER_PAGE_GROUPS = 10;
  const offsetGroups = currGrpPage * PER_PAGE_GROUPS;
  // console.log(offset, "offset");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const currentGroupPageData = groups
    .slice(offsetGroups, offsetGroups + PER_PAGE_GROUPS)
    .sort((a, b) =>
      alphabeticalOrder
        ? a.group_name.toUpperCase() > b.group_name.toUpperCase()
          ? 1
          : -1
        : a.dept > b.dept
          ? 1
          : -1
    )
    .map((group) => (
      <tr key={group.group_id}>
        <td
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={group?.display_picture.url}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          />
        </td>

        <td
          style={{
            color: "black",
          }}
        >
          {group.group_name && (
            <div>
              <h2 style={{ fontSize: "15px" }}>{group.group_name}</h2>
              <p style={{ fontSize: "11px" }}>
                Created on {formatDate(group.date)} at{" "}
                {new Date(group.date).toLocaleString("en-IN", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </p>
            </div>
          )}
        </td>

        <td
          style={{
            color: "black",
          }}
        >
          {group.createdBy}
        </td>

        <td>
          <div className="d-flex">
            {group.members.slice(0, 3).map((it, index) => (
              <img
                src={it?.display_picture.url}
                alt=""
                key={it.user_id}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  toggleGroupMembers();
                  setPopupGroupMembers(group);
                }}
              />
            ))}
            {group.members.length > 3 && (
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight: "5px",
                  backgroundColor: "gray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                +
              </div>
            )}
          </div>
        </td>

        <td
          style={{
            // paddingRight: "-1px",
            alignItems: "center",
          }}
        >
          {group.isGroupLocked === true ? (
            <Button
              onClick={() => {
                handleLockGroup(group);
              }}
              variant="light"
              style={{ width: "120px" }}
              disabled={lockBtnLoader[group.group_id]}
              key={group.group_id}
            >
              {lockBtnLoader[group.group_id] === true ? (
                <div className="d-flex align-items-center justify-content-center">
                  <ThreeDots
                    height="20"
                    width="20"
                    radius="9"
                    color="#3107cb"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                "Unlock Group"
              )}
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleLockGroup(group);
              }}
              disabled={lockBtnLoader[group.group_id]}
              style={{ width: "120px" }}
              key={group.group_id}
            >
              {lockBtnLoader[group.group_id] === true ? (
                <div className="d-flex align-items-center justify-content-center">
                  <ThreeDots
                    height="20"
                    width="20"
                    radius="9"
                    color="#ffffff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                " Lock Group"
              )}
            </Button>
          )}
        </td>
      </tr>
    ));

  const currentPageData = users
    .slice(offset, offset + PER_PAGE)
    .sort((a, b) =>
      alphabeticalOrder
        ? a.Name > b.Name
          ? 1
          : -1
        : a.Department > b.Department
          ? 1
          : -1
    )
    .map((user, idx) => (
      <tr key={user.user_id}>
        <td
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              user?.display_picture.url === "null"
                ? "https://cdn.boldomatic.com/resource/web/v2/images/profile-dummy-2x.png"
                : user?.display_picture.url
            }
            alt=""
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          />
        </td>

        <td
          style={{
            color: "black",
          }}
        >
          {user.Name && user.remote_wipe_request === true ? (
            <h2>
              {user.Name}
              <button
                type="button"
                style={{
                  backgroundColor: "#0070C0",
                  color: "white",
                  marginLeft: "10px",
                  fontSize: "12px",
                  padding: "0px 5px",
                  borderRadius: "5px",
                  opacity: "0.8",
                }}
              >
                Wiped
              </button>
            </h2>
          ) : user.Name &&
            user.remote_wipe_request === false &&
            user.is_deleted === false ? (
            <h2>{user.Name}</h2>
          ) : user.Name && user.is_deleted === true ? (
            <h2>
              {user.Name}
              <button
                type="button"
                style={{
                  backgroundColor: "#0070C0",
                  color: "white",
                  marginLeft: "10px",
                  fontSize: "12px",
                  padding: "0px 5px",
                  borderRadius: "5px",
                  opacity: "0.8",
                }}
              >
                User Deleted
              </button>
            </h2>
          ) : (
            <h2 style={{ color: "gray" }}>
              Not Onboarded Yet
              <button
                type="button"
                style={{
                  backgroundColor: "#FF4500",
                  color: "white",
                  marginLeft: "10px",
                  fontSize: "12px",
                  padding: "0px 5px",
                  borderRadius: "5px",
                  opacity: "0.8",
                }}
              >
                Pending
              </button>
            </h2>
          )}
        </td>

        <td
          style={{
            color: "black",
          }}
        >
          {user.Email}
        </td>

        <td
          style={
            {
              // backgroundColor: "red",
            }
          }
        >
          {user.remote_wipe_request ? (
            <Button
              disabled={user.remote_wipe_request}
              onClick={() => {
                setDisable(true);
                handleClick(user);
              }}
            >
              Remote Wipe
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleClick(user);
                setDisable(true);
              }}
              disabled={
                !user.Name || superAdmin === false
                  ? permission?.remoteWipe === false
                  : ""
              }
            >
              Remote Wipe
            </Button>
          )}
        </td>

        {/* This is for DELETE USER functionality */}
        <td
          style={{
            paddingRight: "-1px",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              handleDeleteUser(user);
              setDisable(true);
            }}
            disabled={
              superAdmin === false ? permission.deleteUser === false : ""
            }
          >
            Delete User
          </Button>
        </td>
      </tr>
    ));
  // console.log(currentPageData, "currentPageData");
  const pageCount = Math.ceil(users.length / PER_PAGE);
  const pageCountGroup = Math.ceil(groups.length / PER_PAGE_GROUPS);

  // console.log(searchedTerm, "searchedTerm");
  // console.log(searchTerm, "searchTerm");

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }))(Tooltip);

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: "red",
      color: "white",
      fontSize: 14,
    },
  }));
  const CustomTooltip = (props) => {
    const classes = useStyles();

    return (
      <Tooltip {...props} classes={{ tooltip: classes.tooltip }}>
        {props.children}
      </Tooltip>
    );
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    console.log(name, checked, "e");
    // console.log(name, checked);
    setAdminPermissions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleChangeUserExport = (event) => {
    const { name, checked } = event.target;
    console.log(name, checked, "e");
    // console.log(name, checked);
    setSelectUserType((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const handleChangeGroupExport = (event) => {
    const { name, checked } = event.target;
    console.log(name, checked, "e");
    // console.log(name, checked);
    setSelectGroupType((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // console.log(permission.deleteUser === false, "p");

  const handleLockGroup = async (group) => {
    // setLockBtnLoader(true);
    setLockBtnLoader((prevLoadingStates) => ({
      ...prevLoadingStates,
      [group.group_id]: true,
    }));

    const { data } = await lockGroup(
      group.group_id,
      group.isGroupLocked,
      group.group_name,
      orgToken,
      userId
    );
    // setLockBtnLoader(false);
    setLockBtnLoader((prevLoadingStates) => ({
      ...prevLoadingStates,
      [group.group_id]: false, // Assuming itemData has a unique 'id' property
    }));
    if (data.success === true) {
      customToast(
        true,
        "",
        "",
        "Group Settings Changed",
        `${data.msg}`,
        "Check MeMe Application",
        ""
      );
      setSearchGroupTerm("");
    } else {
      customToast(
        "",
        true,
        "",
        `Error`,
        "Some error occurred, kindly try after some time",
        "",
        ""
      );
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {/* MODAL FOR REMOTE WIPE FUNCTIONALITY */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "100%",
              height: "50% !important",
            }}
            src={
              "https://ubiq.co/analytics-blog/wp-content/uploads/2020/07/create-project-management-dashboard.jpeg"
            }
            alt="Card Image"
          />

          {/* <CardBody> */}
          <H6 color="gray">Remote Wipe Caution!</H6>
          <p className="text-gray">
            {userForWipe?.Name
              ? "This will permanently revoke the access of application for " +
              userForWipe?.Name
              : "This User Has Not Registered Yet"}
          </p>
          <p className="text-gray">
            This feature should only be used under the supervision of Enterprise
            Executives. The employee will no longer be having access to his/her
            application data hereafter.
          </p>
          <p className="text-gray mt-2 mb-2">
            <b>The above action will:</b>
          </p>
          {/* </CardBody> */}

          <div
            style={{
              marginLeft: "20px",
              top: "-15px",
            }}
          >
            <div className="d-flex align-items-center mb-2 text-gray">
              <IoCheckmarkCircle size={20} color="#3107cb" className="mr-1" />{" "}
              Log out the user immediately, once the user is online
            </div>
            <div className="d-flex align-items-center mb-2 text-gray">
              <IoCheckmarkCircle size={20} color="#3107cb" className="mr-1" />
              Revoke full MessageMe™ access on employee device
            </div>
            <div className="d-flex align-items-center text-gray mb-2">
              <IoCheckmarkCircle size={20} color="#3107cb" className="mr-1" />
              Delete the Security keys from mobile
            </div>
          </div>
          <div
            style={{
              marginLeft: "25px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  // checked={disable}
                  onChange={(e) => {
                    // console.log(e.target.checked);
                    if (e.target.checked) {
                      setDisable(false);
                    } else if (!e.target.checked) {
                      setDisable(true);
                    }
                    // console.log("disable", disable, "setDisable", setDisable);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  name="delete"
                  size="small"
                  color="primary"
                  value={disable}
                />
              }
              label="Accept Terms and Conditions"
              style={{
                color: "#141414",
                fontWeight: "300",
                // paddingRight: "4rem",
              }}
            />
          </div>

          {disable ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginLeft: "20px",
                // paddingBottom: "10px",
              }}
              className="btns"
            >
              <ButtonComp text={"Yes"} toggle={remoteWipeFn} />
              <ButtonComp text={"No"} toggle={toggle} />
            </div>
          )}
        </Cards>
      </Modal>

      {/* MODAL FOR DELETING USER */}
      <Modal
        isOpen={deleteModal}
        toggle={toggleDelete}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "100%",
              height: "50% !important",
            }}
            src={
              "https://ubiq.co/analytics-blog/wp-content/uploads/2020/07/create-project-management-dashboard.jpeg"
            }
            alt="Card Image"
          />

          {/* <CardBody> */}
          <H6 color="gray">MessageMe™ Delete User</H6>
          <Paragraph>
            This feature should only be used under the supervision of Enterprise
            Executives. The employee will no longer be having access to his/her
            application data hereafter.
          </Paragraph>

          {/* </CardBody> */}

          <div
            style={{
              marginLeft: "20px",
              top: "-20px",
            }}
          >
            <div className="d-flex align-items-center mb-2 text-gray">
              <IoCheckmarkCircle size={20} color="#3107cb" className="mr-1" />{" "}
              Caution! Irreversible action, Confirm before deleting user.
            </div>
            <div className="d-flex align-items-center mb-2 text-gray">
              <IoCheckmarkCircle size={20} color="#3107cb" className="mr-1" />{" "}
              Caution! The details of the user will be remotely wiped.
            </div>
          </div>
          <div
            style={{
              marginLeft: "25px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={deleteU}
                  onChange={({ target: { checked } }) => {
                    setDeleteU(checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                  color="primary"
                />
              }
              label="Accept Terms and Conditions"
              style={{
                color: "#141414",
                fontWeight: "300",
                // paddingRight: "4rem",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "20px",
              paddingBottom: "10px",
            }}
            className="btns"
          >
            <Button
              disabled={!deleteU}
              onClick={deleteUser}
              variant="primary"
              style={{ borderRadius: "50px", width: "100%" }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                toggleDelete();
                setDeleteU(false);
              }}
              variant="light"
              style={{ borderRadius: "50px", width: "100%" }}
            >
              No
            </Button>
          </div>
        </Cards>
      </Modal>

      {/* MODAL FOR NEW ADMIN */}
      <Modal
        isOpen={adminModal}
        toggle={newAdmin}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "100%",
              height: "50% !important",
            }}
            src={
              "https://ubiq.co/analytics-blog/wp-content/uploads/2020/07/create-project-management-dashboard.jpeg"
            }
            alt="Card Image"
          />
          {/* <CardBody> */}
          <H6 color="gray">MessageMe™ Add Admin</H6>

          <Input
            type="email"
            placeholder="Enter the email address to invite"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {/* </CardBody> */}
          <div
            style={{
              // marginLeft: "20px",
              marginTop: "10px",
              top: "-20px",
            }}
            className="pt-2"
          >
            <p
              className="d-flex align-items-center mb-2"
              style={{ color: "#141414" }}
            >
              <IoCheckmarkCircle size={20} color="#3107cb" className="mr-1" />{" "}
              The above user is an Admin, not a Super Admin.
            </p>
          </div>
          <div className="d-flex text-black pt-2 flex-col">
            <h1 className="font-bold pb-2">Roles & Permissions</h1>
            <p style={{ color: "#141414" }}>
              Below permissions can be granted to new admin regarding users:
            </p>

            <div className="d-flex align-items-center ">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.delete}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="delete"
                    size="small"
                    color="primary"
                  />
                }
                label="Delete"
                style={{
                  color: "#141414",
                  fontWeight: "300",
                  paddingRight: "4.2rem",
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.wipe}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="wipe"
                    size="small"
                    color="primary"
                  />
                }
                label="Remote Wipe"
                style={{ color: "#141414" }}
              />
            </div>
            <div className="d-flex align-items-center ">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.FSL}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="FSL"
                    size="small"
                    color="primary"
                  />
                }
                label="File Size Limiter"
                style={{ color: "#141414" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermissions.groupSettings}
                    onChange={handleChange}
                    size="small"
                    name="groupSettings"
                    color="primary"
                  />
                }
                label="Group Settings"
                style={{ color: "#141414" }}
              />
            </div>

            <div className="d-flex align-items-center mt--3">
              <div className="w-40 align-items-center mt-4 text-black">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={terms}
                      onChange={({ target: { checked } }) => {
                        setTerms(checked);
                      }}
                      size="small"
                      color="primary"
                    />
                  }
                  label="Accept T&C"
                  style={{ color: "#141414" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  // marginLeft: "20px",
                  // paddingBottom: "10px",
                }}
                className="btns d-flex align-items-center w-50 mt-4"
              >
                {email.length === 0 ? (
                  <Button
                    disabled
                    variant="primary"
                    style={{ width: "100%", borderRadius: "50px" }}
                  >
                    Invite
                  </Button>
                ) : (
                  <Button
                    disabled={!terms}
                    onClick={addNewAdmin}
                    variant="primary"
                    style={{ width: "100%", borderRadius: "50px" }}
                  >
                    Invite
                  </Button>
                )}
                <Button
                  onClick={() => {
                    newAdmin();
                    setAdminPermissions(false);
                    setTerms(false);
                  }}
                  variant="light"
                  style={{ width: "100%", borderRadius: "50px" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>

          {/* ROLES AND PERMISSION */}
        </Cards>
      </Modal>

      {/* MODAL FOR DISPLAYING ALL GROUP MEMBERS */}
      <Modal
        isOpen={groupMembersModal}
        toggle={toggleGroupMembers}
        centered
        // size="md"
        style={{
          height: "200px",
          color: "black",
          overflowY: "auto",
        }}
      >
        <img
          src={subs}
          style={{
            objectFit: "none",
            width: "100%",
            height: "auto",
            borderTopLeftRadius: "1.3rem",
            borderTopRightRadius: "1.3rem",
          }}
        />

        <div
          className="d-flex absolute align-items-center"
          style={{ left: "1.6rem", top: "15px" }}
        >
          <img
            alt="dp"
            src={
              popupGroupMembers?.display_picture?.url === null
                ? "https://cdn6.aptoide.com/imgs/1/2/2/1221bc0bdd2354b42b293317ff2adbcf_icon.png"
                : popupGroupMembers?.display_picture?.url
            }
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "100%",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25",
              // position: "absolute",
              color: "white",
            }}
            className="d-none d-md-block"
          />

          <div className="d-flex flex-col ml-3" style={{ color: "white" }}>
            {popupGroupMembers.group_name && (
              <div>
                <h2 style={{ fontSize: "15px" }} className="font-bold">
                  {popupGroupMembers.group_name}
                </h2>
                <p style={{ fontSize: "11px" }}>
                  Created on {formatDate(popupGroupMembers.date)} at{" "}
                  {new Date(popupGroupMembers.date).toLocaleString("en-IN", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
                <p style={{ fontSize: "11px" }}>
                  Admin: {popupGroupMembers.createdBy}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="p-4">
          <h1 className="mb-3">
            Members List ({popupGroupMembers?.members?.length})
          </h1>
          <div className="d-flex flex-col">
            {popupGroupMembers?.members?.map((m) => (
              <div className="d-flex pl-2 align-items-center mb-3">
                <img
                  src={m.display_picture.url}
                  alt="dp"
                  key={m.user_id}
                  style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                  className="mr-4"
                />
                <p>{m.Name}</p>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      {/* MODAL FOR DISPLAYING EXPORT USER */}
      <Modal isOpen={userExport} toggle={toggleUserExport} centered size="md">
        <Card
          style={{
            width: "600px",
            height: "350px",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            borderRadius: "15px",
            backgroundColor: "#F6F7F9",
            padding: "15px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1 className="font-bold text-black">Download the User logs:</h1>
            <MdCancel
              color="#d3d3d3"
              className="font-bold cursor-pointer"
              size={20}
              onClick={toggleUserExport}
            />
          </div>
          <div className="d-flex flex-col">
            <p className="font-bold text-black">Type of report</p>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "53.5%" }}
            >
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="pdf"
                  label="React"
                  value={"pdf"}
                  checked={selectedFormat === "pdf"}
                  onChange={(e) => {
                    setSelectedFormat(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>PDF</p>
              </div>
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="excel"
                  label="React"
                  value={"excel"}
                  checked={selectedFormat === "excel"}
                  onChange={(e) => {
                    setSelectedFormat(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>Excel</p>
              </div>
            </div>
            <p className="font-bold text-black">Which users to be exported?</p>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "66%" }}
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  color="primary"
                  id="react"
                  name="all"
                  label="React"
                  value="all"
                  checked={selectUserType.all}
                  onChange={handleChangeUserExport}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>All Users</p>
              </div>
              <div className="d-flex align-items-center ">
                <Checkbox
                  color="primary"
                  id="react"
                  name="onboarded"
                  label="React"
                  value={"onboarded"}
                  checked={selectUserType.onboarded}
                  onChange={handleChangeUserExport}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  Onboarded Users
                </p>
              </div>
            </div>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "71.7%" }}
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  color="primary"
                  id="react"
                  name="pending"
                  label="React"
                  value={"pending"}
                  checked={selectUserType.pending}
                  onChange={handleChangeUserExport}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  Pending Users
                </p>
              </div>
              <div className="d-flex align-items-center">
                <Checkbox
                  color="primary"
                  id="react"
                  name="wiped"
                  label="React"
                  value={"wiped"}
                  checked={selectUserType.wiped}
                  onChange={handleChangeUserExport}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  Remotely Wiped Users
                </p>
              </div>
            </div>

            <div className="d-flex flex-col mt-2">
              <p className="text-black font-bold">
                The exported report would consist below columns:{" "}
              </p>
              <p
                className="text-black ml-2"
                style={{ fontWeight: "400", fontSize: "13px" }}
              >
                Name, Email Address, User Status (Pending, Onboarded, Wiped)
              </p>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                style={{
                  backgroundColor: " #d3d3d3",
                  borderRadius: "10px",
                  color: "black",
                  borderColor: "#d3d3d3",
                  outline: "none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none",
                  marginRight: "20px",
                  cursor: users.length > 0 ? "pointer" : "not-allowed",
                }}
                disabled={!users}
                onClick={() => {
                  if (users.length > 0) {
                    downloadExportedData();
                  }
                }}
              >
                Export
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      {/* MODAL FOR DISPLAYING EXPORT GROUP */}
      <Modal isOpen={groupExport} toggle={toggleGroupExport} centered size="md">
        <Card
          style={{
            width: "600px",
            height: "350px",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            borderRadius: "15px",
            backgroundColor: "#F6F7F9",
            padding: "15px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1 className="font-bold text-black">Download the Group logs:</h1>
            <MdCancel
              color="#d3d3d3"
              className="font-bold cursor-pointer"
              size={20}
              onClick={toggleGroupExport}
            />
          </div>
          <div className="d-flex flex-col">
            <p className="font-bold text-black">Type of report</p>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "53.5%" }}
            >
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="pdf"
                  label="React"
                  value={"pdf"}
                  checked={selectedGroupFormat === "pdf"}
                  onChange={(e) => {
                    setSelectedGroupFormat(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>PDF</p>
              </div>
              <div className="d-flex align-items-center">
                <Radio
                  color="primary"
                  id="react"
                  name="excel"
                  label="React"
                  value={"excel"}
                  checked={selectedGroupFormat === "excel"}
                  onChange={(e) => {
                    setSelectedGroupFormat(e.target.value);
                  }}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>Excel</p>
              </div>
            </div>
            <p className="font-bold text-black">Which groups to be exported?</p>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "63.2%" }}
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  color="primary"
                  id="react"
                  name="all"
                  label="React"
                  value="all"
                  checked={selectGroupType.all}
                  onChange={handleChangeGroupExport}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  All Groups
                </p>
              </div>
              <div className="d-flex align-items-center ">
                <Checkbox
                  color="primary"
                  id="react"
                  name="locked"
                  label="React"
                  value={"locked"}
                  checked={selectGroupType.locked}
                  onChange={handleChangeGroupExport}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  Locked Groups
                </p>
              </div>
            </div>
            <div
              className="d-flex align-items-center text-black justify-content-between"
              style={{ width: "71.7%" }}
            >
              <div className="d-flex align-items-center">
                <Checkbox
                  color="primary"
                  id="react"
                  name="unlocked"
                  label="React"
                  value={"unlocked"}
                  checked={selectGroupType.unlocked}
                  onChange={handleChangeGroupExport}
                />
                <p style={{ fontSize: "13px", fontWeight: "400" }}>
                  Unlocked Groups
                </p>
              </div>
            </div>

            <div className="d-flex flex-col mt-2">
              <p className="text-black font-bold">
                The exported report would consist below columns:{" "}
              </p>
              <p
                className="text-black ml-2"
                style={{ fontWeight: "400", fontSize: "13px" }}
              >
                Group Name, Admin, Member List, Group Status (Locked, Unlocked)
              </p>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                style={{
                  backgroundColor: " #d3d3d3",
                  borderRadius: "10px",
                  color: "black",
                  borderColor: "#d3d3d3",
                  outline: "none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none",
                  marginRight: "20px",
                  cursor: groups.length > 0 ? "pointer" : "not-allowed",
                }}
                disabled={!groups}
                onClick={() => {
                  if (groups.length > 0) {
                    downloadExportedData();
                  }
                }}
              >
                Export
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Modal
        style={{
          marginTop: "1.5em",
          width: "40%",
          marginLeft: "30%",
        }}
        isOpen={warning}
        toggle={() => closeWarning()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Cards>
          <div className="d-flex flex-col ">
            <div className="d-flex  align-items-center justify-content-between">
              <div className="d-flex flex-col">
                <p style={{ color: "red" }}>Warning</p>
                <Heading6 color="gray">File Type Limiter</Heading6>
              </div>

              <div className="d-flex">
                <img src={Privacy} />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Image
              style={{
                width: "50%",
                height: "20% !important",
              }}
              src={newVersion}
              alt="Card Image"
            />
          </div>

          <div
            className=" text-black"
            style={{
              overflowY: "scroll",
              maxHeight: "250px",
              // scrollbarWidth: "2px",
            }}
          >
            <ul>
              <li className="font-bold">File type limiter must have at least one file selected</li>
              <br />
              <p className="pl-2 pb-2 " style={{ fontSize: "13px" }}>
                <span className="text-muted"> We have detected that your platform has restricted file sharing for all file types which could hamper user experience on employee application. We suggest you to atleast allow one file type within</span> <span className="font-bold">"Application Settings"</span>.
              </p>
            </ul>
          </div>

          {/*<div className="d-flex align-items-center text-black mt-1">
                    <Checkbox
                      className="mr-1"
                      checked={selectVersion}
                      onChange={handleChangeVersion}
                      inputProps={{ "aria-label": "controlled" }}
                      color="primary"
                    />
                     // <p style={{ fontSize: "13px", marginTop: "10px" }} className="pl-4">
                    //   I've read and agree to the Terms & Conditions & all the
                    //   features available on the platform
                    // </p> 
                  </div> */}
          <div className="d-flex justify-content-end">

            <Button
              style={{
                background: "#3107cb",
                borderRadius: "8px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderColor: "#3107cb",
                width: "200px",
                height: "40px",
              }}
              onClick={naviagteToAppSettings}
            >
              Go to App Settings
            </Button>
          </div>
        </Cards>
      </Modal>
      <Header />

      <div style={{ margin: "30px", display: "flex", justifyContent: "end" }}>
        {(isPremiumPurchased === "basic" || whichPlanSubscribed === "basic") &&
          adminData?.data?.message?.length >= 2 ? (
          <LightTooltip title="Upgrade to Premium Plan">
            <Button
              onClick={() => {
                handleNewAdmin(email);
                setDisable(true);
              }}
              style={{ marginRight: "110px" }}
              disabled={
                (superAdmin === false &&
                  isPremiumPurchased === "Trial Version" &&
                  adminData?.data?.message?.length >= 2) ||
                ((isPremiumPurchased === "basic" ||
                  whichPlanSubscribed === "basic") &&
                  adminData?.data?.message?.length >= 2)
              }
              className={`${superAdmin === false && "cursor-not-allowed"}`}
            >
              Add Admin
            </Button>
          </LightTooltip>
        ) : (
          <Button
            onClick={() => {
              handleNewAdmin(email);
              setDisable(true);
            }}
            style={{ marginRight: "110px" }}
            disabled={
              (superAdmin === false &&
                isPremiumPurchased === "Trial Version" &&
                adminData?.data?.message?.length >= 2) ||
              ((isPremiumPurchased === "basic" ||
                whichPlanSubscribed === "basic") &&
                adminData?.data?.message?.length >= 2)
            }
            className={`${superAdmin === false && "cursor-not-allowed"}`}
          >
            Add Admin
          </Button>
        )}
      </div>
      <div
        style={{
          margin: "22px",
          // paddingBottom: groups.length === 0 ? "100px" : "",
          marginBottom:
            users.length === 0 ? "210px" : users.length === 1 ? "215px" : "5px",
        }}
      >
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3
                  className={
                    usersList === true
                      ? `${"mb-0 cursor-pointer mr-4 activeListColor"}`
                      : "mb-0 text-black cursor-pointer mr-4"
                  }
                  onClick={() => {
                    setUsersList(true);
                    setGroupList(false);
                  }}
                >
                  Users
                </h3>
                {(permission?.groupSettings === true || superAdmin) && (
                  <h3
                    className={
                      groupList === true
                        ? `${"mb-0 cursor-pointer activeListColor"}`
                        : "mb-0 text-black cursor-pointer mr-4"
                    }
                    onClick={() => {
                      setUsersList(false);
                      setGroupList(true);
                    }}
                  >
                    Groups
                  </h3>
                )}

                <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                  <div
                    onClick={() => {
                      usersList === true
                        ? toggleUserExport()
                        : toggleGroupExport();
                    }}
                    className="d-flex align-items-center justify-content-center ml-2 px-1"
                    style={{ marginTop: "-10px", cursor: "pointer" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#e9ecef",
                        color: "black",
                        borderColor: "#e9ecef",
                        outline: "none !important",
                        outlineOffset: "none !important",
                        boxShadow: "none",
                        marginRight: "10px",
                      }}
                      className="rounded-full"
                    >
                      <span className="d-flex align-items-center justify-content-center gap-2">
                        Export
                        <FaFileExport
                          width={150}
                          height={150}
                          disabled={!users}
                          color="#757070"
                        />
                      </span>
                    </Button>
                  </div>

                  {/* UNCOMMENT WHEN ITS ADDED IN APPLICATION */}
                  {/* <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    style={{ marginTop: "-10px" }}
                  >
                    <DropdownToggle size="sm" color="#ffffff" className="drop">
                      <FcGenericSortingAsc
                        style={{
                          // color: "#fff",
                          width: "25px",
                          height: "25px",
                          // paddingRight: "10px",
                        }}
                      />
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setAlphabeticalOrder(true);
                          setDeptOrder(false);
                        }}
                      >
                        Alphabetical (A-Z)
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setDeptOrder(true);
                          setAlphabeticalOrder(false);
                        }}
                      >
                        User Department
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown> */}
                  <FormGroup className="mb-0 form-group">
                    <InputGroup className="input-group-alternative">
                      <div className="input-group">
                        <InputGroupText>
                          <i className="fas fa-search text-black" />
                        </InputGroupText>
                      </div>

                      <Input
                        placeholder="Search"
                        type="text"
                        id="searchId"
                        value={
                          groupList === true ? searchGroupTerm : searchTerm
                        }
                        onChange={(e) => {
                          if (groupList === true) {
                            setSearchGroupTerm(e.target.value);
                          } else {
                            setSearchTerm(e.target.value);
                          }
                        }}
                        onKeyUp={searchHandler}
                        style={{ color: "black" }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardHeader>

              {usersList === true ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th
                        scope="col"
                        colSpan="2"
                        style={{
                          // width: "1px"
                          // backgroundColor:'yellow',
                          paddingRight: "120px",
                          textAlign: "center",
                        }}
                      >
                        Actions
                      </th>
                      {/* <th scope='col'></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!searchTerm &&
                      (loadingUsers === true ? (
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ marginLeft: "370%" }}
                        >
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#3107cb"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        currentPageData
                      ))}
                    {searchedTerm &&
                      searchTerm &&
                      searchedTerm
                        ?.sort((a, b) =>
                          alphabeticalOrder
                            ? a.Name > b.Name
                              ? 1
                              : -1
                            : a.dept > b.dept
                              ? 1
                              : -1
                        )
                        .map((user) => (
                          <tr key={user.user_id}>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={user?.display_picture.url}
                                alt=""
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                              />
                            </td>

                            <td
                              style={{
                                color: "black",
                              }}
                            >
                              {user.Name ? (
                                <h2>{user.Name}</h2>
                              ) : (
                                <h2 style={{ color: "gray" }}>
                                  Not Onboarded Yet
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "#FF4500",
                                      color: "white",
                                      marginLeft: "10px",
                                      fontSize: "12px",
                                      padding: "0px 5px",
                                      borderRadius: "5px",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Pending
                                  </button>
                                </h2>
                              )}
                            </td>

                            <td
                              style={{
                                color: "black",
                              }}
                            >
                              {user.Email}
                            </td>

                            <td
                              style={
                                {
                                  // backgroundColor: "red",
                                }
                              }
                            >
                              {user.remote_wipe_request ? (
                                <Button
                                  disabled={user.remote_wipe_request}
                                  onClick={() => {
                                    setDisable(true);
                                    handleClick(user);
                                  }}
                                >
                                  Remote Wipe
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    handleClick(user);
                                    setDisable(true);
                                  }}
                                  disabled={
                                    !user.Name || superAdmin === false
                                      ? permission.remoteWipe === false
                                      : ""
                                  }
                                >
                                  Remote Wipe
                                </Button>
                              )}
                            </td>

                            {/* This is for DELETE USER functionality */}
                            <td
                              style={{
                                paddingRight: "-1px",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  handleDeleteUser(user);
                                  setDisable(true);
                                }}
                                disabled={
                                  superAdmin === false
                                    ? permission.deleteUser === false
                                    : ""
                                }
                              >
                                Delete User
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Group Name</th>
                      <th scope="col">Admin</th>

                      <th scope="col">Members</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!searchGroupTerm &&
                      (loadingUsers === true ? (
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ marginLeft: "370%" }}
                        >
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#3107cb"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        currentGroupPageData
                      ))}
                    {searchedGroupTerm &&
                      searchGroupTerm &&
                      searchedGroupTerm
                        ?.sort((a, b) =>
                          alphabeticalOrder
                            ? a.group_name.toUpperCase() >
                              b.group_name.toUpperCase()
                              ? 1
                              : -1
                            : a.dept > b.dept // change it when flutter side gets updated
                              ? 1
                              : -1
                        )
                        .map((group) => (
                          <tr key={group.group_id}>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={group?.display_picture.url}
                                alt=""
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                              />
                            </td>

                            <td
                              style={{
                                color: "black",
                              }}
                            >
                              {group.group_name && (
                                <div>
                                  <h2 style={{ fontSize: "15px" }}>
                                    {group.group_name}
                                  </h2>
                                  <p style={{ fontSize: "11px" }}>
                                    Created on {formatDate(group.date)} at{" "}
                                    {new Date(group.date).toLocaleString(
                                      "en-IN",
                                      {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }
                                    )}
                                  </p>
                                </div>
                              )}
                            </td>

                            <td
                              style={{
                                color: "black",
                              }}
                            >
                              {group.createdBy}
                            </td>

                            <td
                              style={
                                {
                                  // backgroundColor: "red",
                                }
                              }
                            >
                              <div className="d-flex">
                                {group.members.slice(0, 3).map((it) => (
                                  <img
                                    src={it?.display_picture.url}
                                    alt="group_members"
                                    key={it.user_id}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      toggleGroupMembers();
                                      setPopupGroupMembers(group);
                                    }}
                                  />
                                ))}
                                {group.members.length > 3 && (
                                  <div
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      marginRight: "5px",
                                      backgroundColor: "gray",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "white",
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                              </div>
                            </td>

                            <td
                              style={{
                                // paddingRight: "-1px",
                                alignItems: "center",
                              }}
                            >
                              {group.isGroupLocked === true ? (
                                <Button
                                  onClick={() => {
                                    handleLockGroup(group);
                                  }}
                                  variant="light"
                                  disabled={lockBtnLoader[group.group_id]}
                                  style={{ width: "120px" }}
                                  key={group.group_id}
                                >
                                  {lockBtnLoader[group.group_id] === true ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <ThreeDots
                                        height="20"
                                        width="20"
                                        radius="9"
                                        color="#3107cb"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />
                                    </div>
                                  ) : (
                                    "Unlock Group"
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    handleLockGroup(group);
                                  }}
                                  disabled={lockBtnLoader[group.group_id]}
                                  style={{ width: "120px" }}
                                  key={group.group_id}
                                >
                                  {lockBtnLoader[group.group_id] === true ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <ThreeDots
                                        height="20"
                                        width="20"
                                        radius="9"
                                        color="#ffffff"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />
                                    </div>
                                  ) : (
                                    " Lock Group"
                                  )}
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              )}
            </Card>
          </div>
        </Row>
      </div>
      {users.length > 9 && usersList === true ? (
        <div className="d-flex align-items-center justify-content-center">
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        groupList === true &&
        groups.length > 9 && (
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCountGroup}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handleGroupPageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        )
      )}
    </>
  );
};

export default UsersTable;
