import * as React from "react";
import { ReactMultiEmailCustom } from "react-multi-email-custom";
import "../../assets/css/emailInvite.css";
import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../components/Headers/Header";
import CardMedia from "@material-ui/core/CardMedia";
import { CardContent, Tooltip } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import invite from "../../assets/Invite.png";
import { Button, Label } from "reactstrap";
import { MdCancel } from "react-icons/md";
import { sendEmployeeInvite, checkEmail } from "../../network/ApiAxios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/login.css";
import excel from "../../assets/img/illustrations/excelImport.png";
import { useDispatch, useSelector } from "react-redux";
import { getDataAction } from "components/redux/dataaction";
import { useRef } from "react";
import * as XLSX from "xlsx";
import { RiFileExcel2Fill } from "react-icons/ri";
import customToast from "./ToastComponent";

const Basic = () => {
  const [emails, setEmails] = useState([]);
  const [isHovering, setIsHovering] = useState(false);
  const userLimit = JSON.parse(localStorage.getItem("keys"))?.onBoardUserLimit;
  const orgToken = JSON.parse(localStorage.getItem("keys"))?.org_token;
  const { data } = useSelector((state) => state.getDataReducer);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const sendInvite = async () => {
    const AdminEmail = JSON.parse(localStorage.getItem("user"))?.email;

    // checking for if entered email already exists
    var email = [];
    await checkEmail(emails, AdminEmail).then((res) => {
      if (res.data.success === 1) {
        customToast(
          "",
          true,
          "",
          `${res.data.message} already exist`,
          "Kindly use another email address or delete the existing",
          "Go to dashboard",
          "/admin/index"
        );
      }
      // else if (res.data.success === true) {
      // 	toast.success(
      // 		<span>
      // 			<i className='fas fa-times'></i>&nbsp;&nbsp;{res.data.message}
      // 		</span>,
      // 		{
      // 			position: "top-right",
      // 			autoClose: 5000,
      // 			hideProgressBar: false,
      // 			closeOnClick: true,
      // 			pauseOnHover: true,
      // 			draggable: true,
      // 			progress: undefined,
      // 		}
      // 	);
      // }

      email = res.data.email;
    });

    // sends email to new users
    if (email?.length > 0) {
      await sendEmployeeInvite(email, AdminEmail, orgToken)
        .then((res) => {
          if (res.data.success === 2) {
            // email sent
            console.log("ressstruee", res);
            customToast(
              true,
              "",
              "",
              `${res.data.message} invited successfully`,
              "Invitation has been sent to email address/es",
              "Go to dashboard",
              "/admin/index"
            );
            setEmails("");
          } else if (res.data.success === 1) {
            console.log("resss111", res);

            customToast(
              "",
              true,
              "",
              `${res.data.message} `,
              "",
              "Go to dashboard",
              "/admin/index"
            );
          }
          // else {
          // 	toast.error(
          // 		<span>
          // 			<i className='fas fa-times'></i>&nbsp;&nbsp;Failed to send Email
          // 		</span>,
          // 		{
          // 			position: "top-right",
          // 			autoClose: 5000,
          // 			hideProgressBar: false,
          // 			closeOnClick: true,
          // 			pauseOnHover: true,
          // 			draggable: true,
          // 			progress: undefined,
          // 		}
          // 	);
          // }
        })
        .catch(() => {
          customToast(
            "",
            true,
            "",
            `Something went wrong`,
            "Try sign out and sign in again!",
            "Go to dashboard",
            "/admin/index"
          );
        });
    }

    dispatch(getDataAction());
  };

  const handleFileChange = (event) => {
    console.log("hii 2nd time");
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: "A" });
      const extractedEmails = jsonData.map((item) => item.A);
      setEmails([...emails, ...extractedEmails]);
    };

    reader.readAsArrayBuffer(file);
    event.target.value = null;
  };

  const handleMouseOver = () => {
    setIsHovering(true);
    console.log("hello");
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />

      <div style={{ marginBottom: "30px" }}>
        <div className="justify-content-center">
          <div
            className="card d-flex mx-auto"
            style={{ margin: "10px", width: "50%" }}
          >
            <div>
              <div className="card-text" style={{ margin: "0", padding: "0" }}>
                <div style={{ position: "relative" }}>
                  <img
                    className="card-img"
                    src={invite}
                    style={{ height: "225px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      color: "white",
                      bottom: "40%",
                      left: "30%",
                      transform: "translateX(-50%)",
                    }}
                    className="inviteText"
                  >
                    <h4
                      style={{
                        fontWeight: "bolder",
                        fontSize: "calc(0.85em + 1.25vmin)",
                      }}
                    >
                      Invite members to MessageMe™
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card-body"
              style={{
                position: "relative",
                width: "100%",
                textAlign: "center",
                color: "#3e3e3e",
                marginTop: "5%",
              }}
            >
              <h6
                className="card-text"
                style={{
                  fontSize: "calc(0.75em + 1vmin)",
                }}
              >
                Onboarding made easy with our solution
              </h6>

              <div
                style={{
                  width: "96%",
                  marginLeft: "2%",
                }}
              >
                <label
                  style={{
                    color: data?.users === userLimit ? " #E60B0B" : "#585858",
                    marginTop: "3%",
                  }}
                >
                  {data?.users === userLimit
                    ? "You have exceeded number of license, to invite more users please upgrade."
                    : "Enter Email Addresses"}
                </label>
                {data?.users !== userLimit && (
                  <ReactMultiEmailCustom
                    delimiter=" "
                    emails={emails}
                    onChange={(_emails) => {
                      console.log(_emails);
                      setEmails(_emails);
                      console.log([...emails, _emails]);
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#eae6fa",
                            borderRadius: "5px",
                            padding: "3px",
                            margin: "2px",
                            fontSize: "calc(0.5em + 0.8vmin)",
                          }}
                        >
                          <Label key={index} style={{ color: "#3107CB" }}>
                            {email}
                            <MdCancel
                              onClick={() => removeEmail(index)}
                              style={{ padding: "1px" }}
                            />
                          </Label>
                        </div>
                      );
                    }}
                  />
                )}

                {data?.users !== userLimit && (
                  <div className="d-flex justify-content-end">
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      style={{ display: "none" }}
                      ref={inputRef}
                      onChange={(e) => handleFileChange(e)}
                    />
                    <p
                      style={{
                        color: "#585858",
                        cursor: "pointer",
                      }}
                      onClick={() => inputRef.current.click()}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      Import emails from xlsx file
                    </p>
                  </div>
                )}
              </div>

              {emails.length === 0 && data?.users !== userLimit ? (
                <Tooltip title="Please Enter Email">
                  <Button
                    disabled
                    style={{
                      width: "100%",
                      backgroundColor: "#5227f2",
                      color: "white",
                      borderRadius: "0",
                      marginTop: "3%",
                      fontWeight: "bold",
                      opacity: "0.8",
                      cursor: "not-allowed",
                    }}
                  >
                    Invite
                  </Button>
                </Tooltip>
              ) : data.users === userLimit ||
                emails.length > userLimit - data.users ? (
                <Tooltip
                  title={`You have only ${
                    userLimit - data.users
                  } licenses left.`}
                >
                  <Button
                    disabled
                    style={{
                      width: "100%",
                      backgroundColor: "#5227f2",
                      color: "white",
                      borderRadius: "0",
                      marginTop: "3%",
                      fontWeight: "bold",
                      opacity: "0.8",
                      cursor: "not-allowed",
                    }}
                  >
                    Invite
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={sendInvite}
                  style={{
                    width: "100%",
                    color: "white",
                    borderRadius: "0",
                    marginTop: "3%",
                    fontWeight: "bold",
                    opacity: "0.8",
                  }}
                  className="bg-meme"
                >
                  Invite
                </Button>
              )}
            </div>
          </div>
        </div>
        {isHovering && (
          <Card
            style={{
              color: "#000000",
              width: "30rem",
              height: "30rem",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: "4px",
              position: "absolute",
              marginTop: "-37rem",
              marginLeft: "20rem",
            }}
          >
            <Card.Body>
              <div className="d-flex align-items-center justify-content-around">
                <div className="d-flex flex-col align-items-start">
                  <Card.Title>
                    Format to import email from excel file
                  </Card.Title>
                  <Card.Text style={{ color: "#5A5A5C" }}>
                    Kindly follow the excel format to auto-fetch the email
                    addresses
                  </Card.Text>
                </div>
                <div>
                  <RiFileExcel2Fill
                    color="#47BE9B"
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <img
                  src={excel}
                  alt="import format"
                  style={{
                    borderRadius: "15px",
                    height: "22rem",
                    width: "100%",
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};
const sendEmployeeInvite1 = async (emails) => {
  console.log("hello");
  const response = await sendEmployeeInvite(emails);
  const { data } = response;
  console.log(data);
  // if (data.success) {
  //     console.log(data);
  //     props.history.push("/auth/login");
  // } else {
  //     setError(data.errors[0].msg);
  // }
};
export default Basic;
