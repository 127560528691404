import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { getDataReducer, userProfileReducer, userReducer } from "./datareducer";
import {
  getAdminReducer,
  getAuditReducer,
  // getForwardsDataReducer,
  setAppVersionReducer,
  setCurrentDateReducer,
  setFileSizeReducer,
  setOnboardUsersReducer,
  setPaymentInfoReducer,
  setUserIdReducer,
  logReducer,
} from "./adminReducers";
import { saveSuccessfulPaymentDetails } from "./adminActions";

const rootreducer = combineReducers({
  getDataReducer: getDataReducer,
  userReducer: userReducer,
  getAdminReducer: getAdminReducer,
  getAuditReducer: getAuditReducer,
  // getForwardsDataReducer: getForwardsDataReducer,
  setUserIdReducer: setUserIdReducer,
  setCurrentDateReducer: setCurrentDateReducer,
  setAppVersionReducer: setAppVersionReducer,
  setOnBoardUsersReducer: setOnboardUsersReducer,
  // userProfileReducer: userProfileReducer,
  setFileSizeReducer: setFileSizeReducer,
  setPaymentInfoReducer: setPaymentInfoReducer,
  saveSuccessfulPaymentDetailsReducer: saveSuccessfulPaymentDetails,
  logReducer: logReducer,
});

const middleware = [thunk];

const store = createStore(
  rootreducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
