const generateFileName = (duration) => {
  const today = new Date();
  if (duration === "today") {
    return `AuditReport-${today.toISOString().split("T")[0]}`;
  } else if (duration === "week") {
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return `AuditReport-${oneWeekAgo.toISOString().split("T")[0]}-${
      today.toISOString().split("T")[0]
    }`;
  } else if (duration === "month") {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `AuditReport-${monthNames[today.getMonth()]}-${today.getFullYear()}`;
  } else if (duration === "sixtyDays") {
    const sixtyDaysAgo = new Date(today);
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    return `AuditReport-${sixtyDaysAgo.toISOString().split("T")[0]}-${
      today.toISOString().split("T")[0]
    }`;
  }
  return "AuditReport"; // Default if none of the options match
};

const generateFileNameForUserExport = (userCriteria) => {
  // console.log(userCriteria, "uu");
  if (userCriteria.all === true) {
    return `UserLogs - AllUsers`;
  } else if (userCriteria.wiped === true) {
    return "UserLogs - Wiped";
  } else if (userCriteria.onboarded === true) {
    return `UserLogs - Onboarded`;
  } else if (userCriteria.pending === true) {
    return `UserLogs - Pending`;
  }
  return "UserLogs"; // Default if none of the options match
};

const generateFileNameForGroupExport = (groupCriteria) => {
  // console.log(groupCriteria, "uu");
  if (groupCriteria.all === true) {
    return `GroupLogs - AllGroups`;
  } else if (groupCriteria.locked === true) {
    return `GroupLogs - LockedGroups`;
  } else if (groupCriteria.unlocked === true) {
    return `GroupLogs - UnlockedGroups`;
  }
  return "GroupLogs"; // Default if none of the options match
};

export {
  generateFileName,
  generateFileNameForUserExport,
  generateFileNameForGroupExport,
};
