import { Heading2, Heading6, Paragraph } from "@material-tailwind/react";
import { Radio, TextField, Tooltip } from "@material-ui/core";
import Header from "components/Headers/Header";
import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { FaRupeeSign } from "react-icons/fa";
import { GrAppleAppStore } from "react-icons/gr";
import Button from "react-bootstrap/Button";
import { Collapse, ModalHeader } from "reactstrap";
import {
  AiFillCheckCircle,
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiOutlineMinus,
  AiOutlinePlus,
} from "react-icons/ai";
import Cards from "@material-tailwind/react/Card";
import H6 from "@material-tailwind/react/Heading6";
import { Modal } from "reactstrap";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import memeLogo from "../../assets/img/icons/common/memeLogo.png";
// import { css } from "@emotion/css";
import "../../assets/css/subscription.css";
import subs from "../../assets/img/subscription/subs1.svg";
import { purchasePlan } from "network/ApiAxios";
import { getDataAction } from "components/redux/dataaction";
import { useDispatch, useSelector } from "react-redux";
import { findOrganizationDetails } from "network/ApiAxios";
import { savePaymentInfo } from "components/redux/adminActions";
import { logout } from "network/ApiAxios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { css } from "@emotion/react";
import customToast from "./ToastComponent";
import { buyPlans } from "network/ApiAxios";
import { saveSuccessfulPaymentDetails } from "components/redux/adminActions";
import { applyCoupoun } from "network/ApiAxios";
import { getPaymentDetails } from "components/redux/adminActions";

const Subscriptions = () => {
  const currentUserLimit = JSON.parse(
    localStorage.getItem("keys")
  )?.onBoardUserLimit;
  const [basic, setBasic] = useState("");
  const [premium, setPremium] = useState("");
  const [enterprise, setEnterprise] = useState("");
  const [price, setPrice] = useState("");
  const [basicPrice, setBasicPrice] = useState(currentUserLimit * 129);
  const [premiumPrice, setPremiumPrice] = useState(currentUserLimit * 169);
  const [totalAmount, setTotalAmount] = useState(0);
  const [basicGst, setBasicGst] = useState(0.0);
  const [premiumGst, setPremiumGst] = useState(0.0);
  const [userLimit, setUserLimit] = useState(currentUserLimit);
  const [isOpenBasic, setIsOpenBasic] = useState(false);
  const [isOpenPremium, setIsOpenPremium] = useState(false);
  const [isOpenEnterprise, setIsOpenEnterprise] = useState(false);
  const [comparison, setComparison] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSub, setIsOpenSub] = useState(false);
  const dispatch = useDispatch();
  const toggleComparison = () => setComparison(!comparison);
  const orgId = JSON.parse(localStorage.getItem("keys"));
  const daysLeftInPlan = useSelector((state) => state.getDataReducer.data);
  const [heightBasic, setHeightBasic] = useState(0);
  const [heightPremium, setHeightPremium] = useState(0);
  const [heightEnterprise, setHeightEnterprise] = useState(0);
  const [code, setCode] = useState("");
  const refBasic = useRef(null);
  const refPremium = useRef(null);
  const refEnterprise = useRef(null);

  const isSuperAdmin = JSON.parse(localStorage.getItem("user"))?.isSuperAdmin;
  const orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { whichPlanSubscribed, amountReceived } = useSelector(
    (state) => state.setPaymentInfoReducer
  );
  const { data, loading, error } = useSelector((state) => state.getDataReducer);
  const [initialPlan, setInitialPlan] = useState("");

  // useEffect(() => {
  //   if (
  //     refBasic.current.clientHeight ||
  //     refPremium.current.clientHeight ||
  //     refEnterprise.current.clientHeight
  //   ) {
  //     setTimeout(() => {
  //       setHeightBasic(refBasic?.current?.clientHeight);
  //       setHeightPremium(refPremium?.current?.clientHeight);
  //       setHeightEnterprise(refEnterprise?.current?.clientHeight);
  //     }, 1000);
  //   }
  //   // if (ref.current.clientHeight || fslRef.current.clientHeight) {
  //   //   setTimeout(() => {
  //   //   }, 1000);
  //   // }
  // }, [window.innerWidth, isOpenBasic, isOpenEnterprise, isOpenPremium]);

  useEffect(() => {
    // const f = async () => {
    //   const data = await findOrganizationDetails(orgToken);
    //   // console.log(hi, "hello");
    //   if (data.success === true) {
    //     setInitialPlan(data.msg.planPurchased);
    //   }
    // };
    // f();

    dispatch(getPaymentDetails(orgToken));
  }, []);

  const handleClick = (e) => {
    if (e.target.value === basic) {
      // means basic is already clicked
      setBasic("");
      setUserLimit("");
      setIsOpenBasic(!isOpenBasic); // already click so contract
    } else if (e.target.value === premium) {
      setPremium("");
      setUserLimit("");
      setIsOpenPremium(!isOpenPremium);
    } else if (e.target.value === enterprise) {
      setEnterprise("");
      setUserLimit("");
      setIsOpenEnterprise(!isOpenEnterprise);
    } else {
      setBasic(e.target.value);
      setPremium(e.target.value);
      setEnterprise(e.target.value);
    }
  };

  // Premium to basic is allowed after billing cycle completes
  const premiumToBasic = () => {
    if (whichPlanSubscribed === "premium" && basic && data?.dayDiff > 1) {
      customToast(
        "",
        "",
        true,
        `Action Not Allowed`,
        "You're currently on Premium plan, you can change it after the current plan expires",
        "Show existing subscription",
        "/admin/subscription"
      );
    }
  };

  // const closeBtn = (
  //   <button className='close' onClick={toggleComparison} type='button'>
  //     &times;
  //   </button>
  // );

  const saveEnterpriseSubscription = async () => {
    try {
      // REAL PAYMENT STARTS
      try {
        const res = await buyPlans(
          orgToken,
          basic,
          premium,
          enterprise,
          userLimit - currentUserLimit,
          totalAmount,
          orgId._id,
          currentUserLimit,
          email
        );
        console.log(res, "res");
        if (res.success) {
          localStorage.setItem(
            "planSelected",
            JSON.stringify(res.planSelected)
          );
          localStorage.setItem("totalUsers", JSON.stringify(res.totalUsers));
          // dispatch(
          //   saveSuccessfulPaymentDetails(res.planSelected, res.totalUsers)
          // );
        }
        // setTimeout(() => {}, 3000);
        if (res.url) {
          window.location.href = res.url;
        }
      } catch (error) {
        console.log(error, "I am buy plan");
      }
      // await purchasePlan(
      //   orgToken,
      //   basic,
      //   premium,
      //   enterprise,
      //   userLimit,
      //   basic
      //     ? ((basicPrice * 18) / 100 + basicPrice).toFixed(2)
      //     : premium
      //     ? ((premiumPrice * 18) / 100 + premiumPrice).toFixed(2)
      //     : enterprise
      //     ? "NA"
      //     : ""
      // );
      // dispatch(getDataAction());
      // findOrganizationDetails(orgToken);

      // // UPDATING LOCALSTORAGE AS WELL
      // const pPlan = localStorage.getItem("keys");
      // const parsedObject = JSON.parse(pPlan);
      // parsedObject.planPurchased = basic
      //   ? "basic"
      //   : premium
      //   ? "premium"
      //   : "enterprise";
      // const modifiedPlan = JSON.stringify(parsedObject);
      // localStorage.setItem("keys", modifiedPlan);

      // dispatch(
      //   savePaymentInfo(
      //     basic ? basic : premium ? premium : enterprise,
      //     basic
      //       ? ((basicPrice * 18) / 100 + basicPrice).toFixed(2)
      //       : premium
      //       ? ((premiumPrice * 18) / 100 + premiumPrice).toFixed(2)
      //       : enterprise
      //       ? "NA"
      //       : ""
      //   )
      // );
      // customToast(
      //   true,
      //   "",
      //   "",
      //   `Subscription purchased, Kindly re-login`,
      //   "To take the effect, you will be logged out automatically.",
      //   "",
      //   ""
      // );
      // LOGGING THE USER OUT
      // const response = await logout(orgToken);
      // localStorage.clear();
    } catch (error) {
      customToast(
        "",
        true,
        "",
        `Something went wrong`,
        "Try again later",
        "Go to dashboard",
        "/admin/index"
      );
    }
  };

  // console.log(totalAmount, "totalAmount");

  useEffect(() => {
    let calculatedAmount = 0;
    const getNumberOfDaysInCurrentMonth = () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1

      const lastDayOfMonth = new Date(year, month, 0);

      const numberOfDays = lastDayOfMonth.getDate();

      return numberOfDays;
    };
    const daysInAMonth = getNumberOfDaysInCurrentMonth();

    // const calculateDaysLeft = (expiryDateString) => {
    //   const expiryDate = new Date(expiryDateString);
    //   const currentDate = new Date();

    //   const differenceInMilliseconds = expiryDate - currentDate;
    //   const differenceInDays = Math.ceil(
    //     differenceInMilliseconds / (1000 * 60 * 60 * 24)
    //   );

    //   return differenceInDays;
    // };

    // const remainingDaysLeft = calculateDaysLeft(orgId?.expiry_date);
    // console.log(remainingDaysLeft, "remainingDaysLeft");

    if (basic && whichPlanSubscribed === "Trial Version") {
      calculatedAmount = ((basicPrice * 18) / 100 + basicPrice).toFixed(2);
      // const perDayAmount = calculatedAmount / daysInAMonth; // per day amount of the selected plan
      // const numberOfDays = daysLeftInPlan.dayDiff; // this is basically number of days left  in your plan
      // calculatedAmount = (perDayAmount * numberOfDays).toFixed(2);
    } else if (premium && whichPlanSubscribed === "Trial Version") {
      calculatedAmount = ((premiumPrice * 18) / 100 + premiumPrice).toFixed(2);
      // const perDayAmount = calculatedAmount / daysInAMonth; // per day amount of the selected plan
      // const numberOfDays = daysLeftInPlan.dayDiff; // this is basically number of days left  in your plan
      // calculatedAmount = (perDayAmount * numberOfDays).toFixed(2);
    } else if (enterprise) {
      calculatedAmount = "NA";
    } else if (
      whichPlanSubscribed === "basic" &&
      premium &&
      userLimit === currentUserLimit
    ) {
      calculatedAmount = (
        (premiumPrice * 18) / 100 +
        premiumPrice -
        amountReceived
      ).toFixed(2);
      if (data.dayDiff >= 1) {
        const perDayAmount = calculatedAmount / daysInAMonth; // per day amount of the selected plan
        const numberOfDays = daysLeftInPlan.dayDiff; // this is basically number of days left  in your plan
        calculatedAmount = (perDayAmount * numberOfDays).toFixed(2);
      }
    } else if (
      whichPlanSubscribed === "basic" &&
      premium &&
      userLimit > currentUserLimit
    ) {
      calculatedAmount = (
        (premiumPrice * 18) / 100 +
        premiumPrice -
        amountReceived +
        (userLimit - currentUserLimit) * 169 +
        // TAX CALCULATION
        ((userLimit - currentUserLimit) * 169 * 18) / 100
      ).toFixed(2);
      // console.log("check", calculatedAmount);
      if (data.dayDiff >= 1) {
        const perDayAmount = calculatedAmount / daysInAMonth; // per day amount of the selected plan
        // console.log("check per day", perDayAmount);
        const numberOfDays = daysLeftInPlan.dayDiff; // this is basically number of days left  in your plan
        // console.log("numberofdays", numberOfDays);
        calculatedAmount = (perDayAmount * numberOfDays).toFixed(2);
        // console.log("final amt", calculatedAmount);
      }
    } else if (
      whichPlanSubscribed === "premium" &&
      premium &&
      userLimit > currentUserLimit &&
      (data?.dayDiff <= 1 || data.dayDiff > 1)
    ) {
      calculatedAmount = (
        parseFloat((userLimit - currentUserLimit) * 169) +
        // TAX CALCULATION
        parseFloat(((userLimit - currentUserLimit) * 169 * 18) / 100)
      ).toFixed(2);
      if (data.dayDiff >= 1) {
        const perDayAmount = calculatedAmount / daysInAMonth; // per day amount of the selected plan
        const numberOfDays = daysLeftInPlan.dayDiff; // this is basically number of days left  in your plan
        calculatedAmount = (perDayAmount * numberOfDays).toFixed(2);
      }
    }
    // BASIC TO BASIC
    else if (
      whichPlanSubscribed === "basic" &&
      basic &&
      userLimit > currentUserLimit &&
      (data?.dayDiff <= 1 || data.dayDiff > 1)
    ) {
      calculatedAmount = (
        parseFloat((userLimit - currentUserLimit) * 129) +
        // TAX CALCULATION
        parseFloat(((userLimit - currentUserLimit) * 129 * 18) / 100)
      ).toFixed(2);
      if (data.dayDiff >= 1) {
        const perDayAmount = calculatedAmount / daysInAMonth; // per day amount of the selected plan
        const numberOfDays = daysLeftInPlan.dayDiff; // this is basically number of days left  in your plan
        calculatedAmount = (perDayAmount * numberOfDays).toFixed(2);
      }
    }
    // plan degradation
    else if (whichPlanSubscribed === "premium" && basic && data.dayDiff < 1) {
      calculatedAmount = ((basicPrice * 18) / 100 + basicPrice).toFixed(2);
    }
    // premium to premium when plan expired with same number of users existing
    else if (
      whichPlanSubscribed === "premium" &&
      premium &&
      data.dayDiff <= 1 &&
      userLimit <= currentUserLimit
    ) {
      calculatedAmount = ((premiumPrice * 18) / 100 + premiumPrice).toFixed(2);
    }
    // basic to basic when plan expired with same number of users existing
    else if (
      whichPlanSubscribed === "basic" &&
      basic &&
      data.dayDiff <= 1 &&
      userLimit <= currentUserLimit
    ) {
      calculatedAmount = ((basicPrice * 18) / 100 + basicPrice).toFixed(2);
    }

    // Update the totalAmount state with the calculated value
    setTotalAmount(calculatedAmount);
  }, [
    basic,
    premium,
    whichPlanSubscribed,
    userLimit,
    currentUserLimit,
    data,
    basicPrice,
    premiumPrice,
    amountReceived,
  ]);

  const apply = async () => {
    const org_token = JSON.parse(localStorage.getItem("user"))?.orgToken;
    const { data } = await applyCoupoun(code, org_token);
    if (data.success) {
      const newExp = await findOrganizationDetails(org_token);
      if (newExp.success === true) {
        localStorage.setItem(
          "expiryDate",
          JSON.stringify(newExp.msg.expiry_date)
        );
        const userLimit = localStorage.getItem("keys");
        const parsedObject = JSON.parse(userLimit);
        parsedObject.onBoardUserLimit = newExp.msg.onBoardUserLimit;
        const modifiedNdStringifiedForStorage = JSON.stringify(parsedObject);
        localStorage.setItem("keys", modifiedNdStringifiedForStorage);
      }

      customToast(
        true,
        "",
        "",
        "Enterprise coupon activated!",
        "Your license has been activated, kindly re-login to take the effect.",
        "Go to Subscription",
        "/adminTest/subscription"
      );
      window.location.reload();
    } else {
      customToast(
        "",
        true,
        "",
        "Some error occurred",
        "Failed to apply the coupon",
        "Go to Profile",
        "/adminTest/edit-profile"
      );

      customToast(
        "",
        true,
        "",
        "Enterprise coupon failed",
        "Failed to apply the coupon",
        "Go to Subscription",
        "/adminTest/subscription"
      );
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        isOpen={comparison}
        toggle={toggleComparison}
        centered
        size="lg"
        // style={{
        //   height: "200px",
        // }}
      >
        <Card
          style={{
            width: "1100px",
            height: "650px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            borderRadius: "15px",
            marginLeft: "-100px",
            backgroundColor: "#F6F7F9",
          }}
        >
          <div className="fixed smallGreenCircle d-none d-lg-block "></div>
          <div className="fixed smallYellowCircle d-none d-lg-block "></div>
          <div className="fixed smallYellowCircle2 d-none d-lg-block "></div>
          <div className="fixed smallWhitishBlueCircle d-none d-lg-block "></div>

          <div className="align-items-start d-flex justify-content-start mb-4">
            <img
              src={memeLogo}
              alt="logo"
              style={{
                top: "7px",
                position: "absolute",
                left: "2px",
                paddingLeft: "20px",
                // paddingBottom: "20px",
                width: "166.6px",
                height: "40.45px",
                // marginBottom: "25px",
              }}
            />
          </div>
          {/* <CardBody> */}
          <H6
            color="gray"
            style={{
              marginTop: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Choose your suitable plan
          </H6>
          <Paragraph
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Now secure your sensitive business communications with{" "}
            <span className="font-bold text-black ml-1"> MessageMe™</span>
          </Paragraph>

          {/* </CardBody> */}
          <div className="d-flex align-items-center justify-content-between">
            <Card
              style={{
                color: "#000000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "15px",
                // width: "28rem",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                marginRight: "20px",
              }}
            >
              <Card.Text className="font-bold mb-2">Basic</Card.Text>
              <Card.Title
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <span className="font-bold d-flex">
                  <FaRupeeSign /> 129/{" "}
                </span>{" "}
                <Card.Text> User per Month</Card.Text>
              </Card.Title>

              <Card.Body
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "27px",
                }}
              >
                <li>Quantum End to End Encryption</li>
                <li>One to One and group chats</li>
                <li>Granular control over group</li>
                <li>Admin Panel controls (limited)</li>
                <li>Administrator Remote Wiper</li>
                <li>2FA on Admin panel</li>
                <li>Data stored on MessageMe server</li>
              </Card.Body>
              <Button
                style={{
                  backgroundColor: "#3107CB",
                  color: "#fff",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                  width: "132px",
                  height: "34px",
                }}
              >
                GET STARTED
              </Button>
            </Card>

            {/* PREMIUM CARD */}
            <Card
              style={{
                color: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "15px",
                // width: "28rem",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                marginRight: "20px",
                backgroundColor: "#292F59",
              }}
            >
              <Heading6
                style={{
                  color: "#ffffff",
                  backgroundColor: "#3107CB",
                  width: "100%",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                MOST POPULAR
              </Heading6>
              <Card.Text className="font-bold mb-2 text-white">
                Premium
              </Card.Text>
              <Card.Title
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <span className="font-bold d-flex">
                  <FaRupeeSign /> 169/{" "}
                </span>{" "}
                <Card.Text> User per Month</Card.Text>
              </Card.Title>

              <Card.Body
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "27px",
                }}
              >
                <li>Everything from Basic plan</li>
                <li>Consent based file sharing</li>
                <li>Critical Timer Messaging</li>
                <li>Forwards Tracker </li>
                <li>Hyper Secure Vault</li>
                <li>Admin - File Size Limiter</li>
                <li>File sharing Audit report generation</li>
              </Card.Body>
              <Button
                style={{
                  backgroundColor: "#3107CB",
                  color: "#fff",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                  width: "132px",
                  height: "34px",
                }}
              >
                GET STARTED
              </Button>
            </Card>

            {/* ENTERPRISE CARD */}
            <Card
              style={{
                color: "#000000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "15px",
                // width: "28rem",
                // marginRight: "20px",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <Card.Text className="font-bold mb-2">Enterprise</Card.Text>
              <Card.Title
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card.Text className="font-bold d-flex">
                  Contact Sales
                </Card.Text>
              </Card.Title>

              <Card.Body
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "27px",
                }}
              >
                <li>Everything from Premium</li>
                <li>Data storage on-premise</li>
                <li>Priority customer support </li>
                <li>Dedicated technology manager</li>
                <li>On-demand new feature release </li>
                <li>On-site Corporate training provided</li>
                <li>Slab pricing for large enterprises</li>
              </Card.Body>
              <Button
                style={{
                  backgroundColor: "#3107CB",
                  color: "#fff",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                  width: "132px",
                  height: "34px",
                }}
              >
                GET STARTED
              </Button>
            </Card>
          </div>

          <div className="d-flex align-items-center justify-content-center mt-4">
            <div className="d-flex flex-column align-items-center">
              <h1 className="font-bold text-black mb-2">
                MessageMe™ is available on
              </h1>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#000000",
                    color: "#ffffff",
                    width: "10rem",
                  }}
                  href={process.env.REACT_APP_PLAYSTORE_LINK}
                  target="_blank"
                >
                  <div className="d-flex  justify-content-around align-items-center">
                    <IoLogoGooglePlaystore size="2em" />
                    <div className="d-flex flex-column align-items-start">
                      <p
                        style={{
                          backgroundColor: "#000000",
                          color: "#ffffff",
                          fontSize: "10px",
                        }}
                      >
                        GET IT ON
                      </p>{" "}
                      <span className="font-bold"> Google Play</span>
                    </div>
                  </div>
                </Button>
                <Button
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#000000",
                    color: "#ffffff",
                    width: "10rem",
                  }}
                  href={process.env.REACT_APP_APPLESTORE_LINK}
                  target="_blank"
                >
                  <div className="d-flex  justify-content-around align-items-center">
                    <GrAppleAppStore size="2em" />
                    <div className="d-flex flex-column align-items-start">
                      <p
                        style={{
                          backgroundColor: "#000000",
                          color: "#ffffff",
                          fontSize: "10px",
                        }}
                      >
                        Download on the
                      </p>{" "}
                      <span className="font-bold">App Store</span>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Modal>

      <Header />

      <div className="d-flex justify-content-between">
        {/* Left side content */}

        <div className="pl-4 pr-4 pt-4">
          <Card
            style={{
              color: "#000000",
              marginBottom: "10px",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <div className="placeTextAbove">
              <img
                src={subs}
                style={{
                  objectFit: "none",
                  // objectPosition: "-115px ",
                  // height: "5%",
                  // position: "absolute",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              />
              <div
                className="d-flex align-items-start justify-content-start  absolute flex-col"
                style={{
                  top: " 45%",
                  left: "33%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <h1
                  style={{
                    fontWeight: "400",
                    fontSize: "19px",
                    lineHeight: "20px",
                    /* identical to box height, or 105% */

                    letterSpacing: "0.02em",

                    color: "#FFFFFF",
                  }}
                >
                  You are currently on a{" "}
                  <span className="font-bold">
                    {whichPlanSubscribed === "Trial Version"
                      ? "Trial Version"
                      : whichPlanSubscribed === "premium"
                      ? "Premium Version"
                      : "Basic Version"}
                  </span>{" "}
                </h1>
                <p>
                  Full Access Activated for{" "}
                  <span className="font-bold">
                    {whichPlanSubscribed === "Trial Version"
                      ? "30 days"
                      : whichPlanSubscribed === "premium"
                      ? "30 days"
                      : "30 days"}
                  </span>
                </p>
              </div>
            </div>
            <Card.Body>
              {/* <div className="d-flex align-items-center justify-content-center"></div> */}

              <div className="d-flex flex-col gap-4">
                <div className="d-flex align-items-center justify-content-start p-2">
                  {/* CHECKMARK DESIGN */}

                  {/* <div className="circle">
                    <div className="checkMark"></div>
                  </div> */}

                  <AiFillCheckCircle
                    style={{
                      color: "#35a080",
                      width: "30px",
                      height: "30px",
                      paddingRight: "5px",
                    }}
                  />

                  <p
                    style={{
                      // fonSize: "13px",
                      lineHeight: "20px",
                      // color: "#585858",

                      color: "#000000A6",
                      // lineBreak: "auto",
                      // marginBottom: "30px",
                      fontSize: "15px",
                      letterSpacing: "0.07em",
                    }}
                  >
                    Get full access to the platform with ultimate{" "}
                    <span className="font-bold">Control and Monitoring</span>{" "}
                    capabilities
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-start p-2">
                  {/* <div className="circle">
                    <div className="checkMark"></div>
                  </div> */}
                  <AiFillCheckCircle
                    style={{
                      color: "#35a080",
                      width: "35px",
                      height: "35px",
                      paddingRight: "5px",
                    }}
                  />
                  <p
                    style={{
                      // fonSize: "13px",
                      lineHeight: "20px",
                      // color: "#585858",

                      // fonSize: "13px",
                      // lineHeight: "16px",
                      // color: "#585858",

                      color: "#000000A6",
                      // lineBreak: "auto",
                      // marginBottom: "30px",
                      fontSize: "15px",
                      letterSpacing: "0.07em",
                    }}
                  >
                    Upgrade to the Premium version and enjoy the benefits for
                    all the team members, up to{" "}
                    <span className="font-bold">200 members</span>
                  </p>
                </div>

                <div className="d-flex justify-content-end">
                  <Button
                    className={css`
                      border: 0px;
                      &:focus {
                        outline: none !important;
                        outline-offset: none !important;
                        border: 0px;
                      }
                      &:hover {
                        outline: none;
                        outline-offset: none;
                        border: 0px;
                      }
                    `}
                    style={{
                      backgroundColor: " #FAAE995C",
                      borderRadius: "5px",
                      color: "black",
                      borderColor: "#FAAE995C",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                    }}
                    onClick={toggleComparison}
                  >
                    Compare Plans
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
          <div>
            {/* <p style={{ color: "#3107CB", marginTop: "40px" }}>
              Welcome to MessageMe™
            </p> */}
            <h1
              style={{
                color: "#000000",
                fontWeight: "700",
                fontSize: "19px",
                lineHeight: "20px",
                /* identical to box height, or 105% */
                marginTop: "40px",
                letterSpacing: "0.02em",
              }}
              className="font-bold"
            >
              Select your MessageMe plan
            </h1>
            <div className=" d-flex align-items-center  justify-content-between">
              <h2
                style={{
                  color: "#000000",
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "27px",
                  /* identical to box height */
                  letterSpacing: "0.02em",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                So how does the 21 Day free trial work?
              </h2>

              {isOpen === true ? (
                <AiFillMinusCircle
                  style={{
                    color: "#CBCFDA",
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  width={100}
                  height={100}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                />
              ) : (
                <AiFillPlusCircle
                  style={{
                    color: "#CBCFDA",
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  width={100}
                  height={100}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                />
              )}
            </div>
            <Collapse isOpen={isOpen}>
              <p
                style={{
                  color: "#000000A6",
                  // lineBreak: "auto",
                  marginBottom: "30px",
                  fontSize: "15px",
                }}
              >
                You’ll get the access to all the premium features and benefits
                during 21 days free trial. <br /> You can also purchase the
                subscription before trial ends. You’ll only be charged after
                your free trial ends
              </p>
            </Collapse>
            <div className=" d-flex align-items-center justify-content-between">
              <h2
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "27px",
                  /* identical to box height */

                  letterSpacing: "0.02em",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: "#000000",
                }}
              >
                How can I cancel my subscription?
              </h2>
              {isOpenSub === true ? (
                <AiFillMinusCircle
                  style={{
                    color: "#CBCFDA",
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  width={100}
                  height={100}
                  onClick={() => {
                    setIsOpenSub(!isOpenSub);
                  }}
                />
              ) : (
                <AiFillPlusCircle
                  style={{
                    color: "#CBCFDA",
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  width={100}
                  height={100}
                  onClick={() => {
                    setIsOpenSub(!isOpenSub);
                  }}
                />
              )}
            </div>

            <Collapse isOpen={isOpenSub}>
              <p
                style={{
                  color: "#000000A6",
                  // lineBreak: "auto",
                  marginBottom: "30px",
                  fontSize: "15px",
                }}
              >
                You can cancel/resume your subscription anytime, without any
                restriction. <br />
                Once you pay for any subscription, we’ll not be able to roll
                back to the purchased subscription anyway.
              </p>
            </Collapse>
          </div>
        </div>

        {/* Right side content */}
        <div className="pl-4 pr-4 pt-4">
          <Card
            border={basic === "basic" ? "primary" : ""}
            style={{
              color: "#000000",
              marginBottom: "10px",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
            // ref={refBasic}
            onClick={premiumToBasic}
          >
            {/* <div
              className="d-flex align-items-start"
              style={{
                width: "9px",
                height: `${heightBasic + "px"}`,
                background: " #47BE9B",
                borderRadius: "10px 0px 0px 10px",
                position: "absolute",
              }}
            ></div> */}
            <Card.Body>
              <div className="d-flex align-items-center justify-content-around">
                <Radio
                  color="primary"
                  id="react"
                  name="basic"
                  label="React"
                  value={"basic"}
                  checked={basic === "basic"}
                  onChange={(e) => {
                    setIsOpenBasic(true);
                    setIsOpenPremium(false);
                    setIsOpenEnterprise(false);
                    setBasic("basic");
                    setPremium("");
                    setEnterprise("");
                    setBasicPrice(currentUserLimit * 129);
                    setBasicGst(162.54);
                    setUserLimit(currentUserLimit);
                  }}
                  onClick={handleClick}
                  disabled={
                    whichPlanSubscribed === "premium" && data?.dayDiff > 2
                  }
                />
                <div>
                  <Card.Title>Basic</Card.Title>
                  <Card.Text
                    style={{
                      color: "#5A5A5C",
                      // fonSize: "13px",
                      lineHeight: "20px",
                      // color: "#585858",

                      color: "#000000A6",
                      // lineBreak: "auto",
                      // marginBottom: "30px",
                      fontSize: "15px",
                      letterSpacing: "0.07em",
                    }}
                  >
                    Our Quantum based End-to-End encryption technology secures
                    your data through
                  </Card.Text>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="d-flex align-items-center">
                    <FaRupeeSign /> <span className="font-bold">129</span>
                  </p>
                  <p
                    style={{ fontSize: "14px" }}
                    className="d-flex align-items-center"
                  >
                    User/Month
                  </p>
                </div>
              </div>
              <Collapse isOpen={isOpenBasic}>
                <div
                  className="d-flex justify-content-between align-items-center mt-2"
                  style={{ paddingLeft: "40px" }}
                >
                  <div>
                    <h1 style={{ color: "#000000" }}>Users</h1>
                    <p
                      style={{
                        color: "#5A5A5C",
                        // fonSize: "13px",
                        lineHeight: "20px",
                        // color: "#585858",

                        color: "#000000A6",
                        // lineBreak: "auto",
                        // marginBottom: "30px",
                        fontSize: "15px",
                        letterSpacing: "0.07em",
                      }}
                    >
                      Starting at 7 users
                    </p>
                  </div>
                  <Card
                    style={{
                      width: "6rem",
                      height: "2rem",
                    }}
                  >
                    <Card.Text
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        color: "#000000",
                      }}
                    >
                      <AiOutlineMinus
                        color="#00000080"
                        onClick={() => {
                          // userLimit === data.users
                          if (
                            userLimit !== currentUserLimit &&
                            userLimit >= 2
                          ) {
                            console.log("hello 1");
                            setUserLimit(userLimit - 1);
                            setBasicPrice(basicPrice - 129);
                            setBasicGst((basicPrice * 18) / 100);
                          } else {
                            if (data?.dayDiff > 0 && data?.dayDiff !== 1) {
                              customToast(
                                "",
                                true,
                                "",
                                `Can't edit`,
                                "This will be editable when one day is left in your plan",
                                "Not Allowed"
                              );
                            } else if (data?.dayDiff <= 1) {
                              if (
                                (currentUserLimit === userLimit &&
                                  currentUserLimit - data?.users === 0) ||
                                userLimit === data?.users
                              ) {
                                console.log("condition 1");
                                if (data?.users === 0 && userLimit === 0) {
                                  console.log("condition 2");
                                } else {
                                  customToast(
                                    "",
                                    true,
                                    "",
                                    `Remove some users`,
                                    "Remove onBoarded users to reduce it"
                                  );
                                }
                              } else if (
                                currentUserLimit - data?.users > 0 &&
                                userLimit >= 0
                              ) {
                                console.log("condition 3");
                                // no employees are onboarded yet
                                // console.log('inside check');

                                setUserLimit(userLimit - 1);
                                setBasicPrice(basicPrice - 129);
                                setBasicGst((basicPrice * 18) / 100);
                              } else {
                                // console.log("hi");
                                // if rem days 1 ==
                                if (
                                  currentUserLimit - data?.users >=
                                  currentUserLimit - userLimit
                                ) {
                                  console.log("hi2");
                                  setUserLimit(userLimit - 1);
                                  setBasicPrice(basicPrice - 129);
                                  setBasicGst((basicPrice * 18) / 100);
                                }
                              }
                              console.log(userLimit, "user");
                            }
                          }
                        }}
                        style={{
                          cursor: `${
                            data?.dayDiff >= 1 || data?.dayDiff < 0
                              ? "pointer"
                              : "not-allowed"
                          }`,
                        }}
                      />
                      {userLimit}
                      <AiOutlinePlus
                        color="#00000080"
                        onClick={() => {
                          setBasicPrice(basicPrice + 129);
                          setBasicGst(basicGst + 23.22);
                          userLimit <= 200 && setUserLimit(userLimit + 1);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Card.Text>
                  </Card>
                </div>
              </Collapse>
            </Card.Body>
          </Card>

          {/* Second card */}
          <Card
            border={premium === "premium" ? "primary" : ""}
            style={{
              color: "#000000",
              marginBottom: "10px",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
            // ref={refPremium}
          >
            {/* <div
              className="d-flex align-items-start"
              style={{
                width: "9px",
                height: `${heightPremium + "px"}`,
                background: " #FFD86E",
                borderRadius: "10px 0px 0px 10px",
                position: "absolute",
              }}
            ></div> */}
            <Card.Body>
              <div className="d-flex align-items-center justify-content-around">
                <Radio
                  id="react"
                  name="type"
                  label="React"
                  color="primary"
                  value={"premium"}
                  checked={premium === "premium"}
                  onChange={(e) => {
                    setIsOpenPremium(true);
                    setIsOpenBasic(false);
                    setIsOpenEnterprise(false);
                    setBasic("");
                    setPremium("premium");
                    setEnterprise("");
                    setPremiumPrice(currentUserLimit * 169);
                    setUserLimit(currentUserLimit);
                    setPremiumGst(212.94);
                  }}
                  onClick={handleClick}
                  disabled={
                    whichPlanSubscribed === "basic" && data?.dayDiff > 2
                  }
                />
                <div>
                  <Card.Title>Premium</Card.Title>
                  <Card.Text
                    style={{
                      color: "#5A5A5C",
                      lineHeight: "20px",
                      color: "#000000A6",
                      fontSize: "15px",
                      letterSpacing: "0.07em",
                    }}
                  >
                    Our Quantum based End-to-End encryption technology secures
                    your data through
                  </Card.Text>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="d-flex align-items-center">
                    <FaRupeeSign /> <span className="font-bold">169</span>
                  </p>
                  <p
                    style={{ fontSize: "14px" }}
                    className="d-flex align-items-center"
                  >
                    User/Month
                  </p>
                </div>
              </div>
              <Collapse isOpen={isOpenPremium}>
                <div
                  className="d-flex justify-content-between align-items-center mt-2"
                  style={{ paddingLeft: "40px" }}
                >
                  <div>
                    <h1 style={{ color: "#000000" }}>Users</h1>
                    <p
                      style={{
                        color: "#5A5A5C",
                        lineHeight: "20px",
                        color: "#000000A6",
                        fontSize: "15px",
                        letterSpacing: "0.07em",
                      }}
                    >
                      Starting at 7 users
                    </p>
                  </div>
                  {/* <div className='d-flex align-items-center'> */}
                  <Card
                    style={{
                      width: "6rem",
                      height: "2rem",
                    }}
                  >
                    <Card.Text
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        color: "#000000",
                      }}
                    >
                      {/* will be disabled for current month and will be enabled next month */}
                      {/* Next month - current users present - 8, need to decrease then show error remove onboard users */}
                      <AiOutlineMinus
                        color="#00000080"
                        onClick={() => {
                          // don't decrease in current month but decrease till currentUser limit
                          if (
                            userLimit !== currentUserLimit &&
                            userLimit > data.users
                          ) {
                            console.log("premium cond4");

                            setUserLimit(userLimit - 1);
                            setPremiumPrice(premiumPrice - 169);
                            setPremiumGst(premiumGst - 30.42);
                          } else {
                            console.log("else block");
                            if (data?.dayDiff > 1) {
                              customToast(
                                "",
                                true,
                                "",
                                `Can't edit`,
                                "This will be editable when one day is left in your plan",
                                "Not Allowed"
                              );
                            }
                            // decreasing users when plan has ended
                            else if (data?.dayDiff <= 1) {
                              console.log("premium cond3");

                              if (
                                (currentUserLimit === userLimit &&
                                  currentUserLimit - data?.users === 0) ||
                                userLimit === data?.users
                              ) {
                                console.log("premium cond5");

                                if (data?.users === 0 && userLimit === 0) {
                                  console.log("premium cond2");
                                } else {
                                  customToast(
                                    "",
                                    true,
                                    "",
                                    `Can't edit`,
                                    "Remove onBoarded users to reduce it",
                                    "Go to Dashboard",
                                    "/admin/index"
                                  );
                                }
                              } else if (
                                data.dayDiff < 0 &&
                                userLimit >= data.users
                              ) {
                                console.log("premium cond7");

                                setUserLimit(userLimit - 1);
                                setPremiumPrice(premiumPrice - 169);
                                setPremiumGst(premiumGst - 30.42);
                              } else {
                                console.log("hi");
                                // if rem days 1 ==
                                if (
                                  currentUserLimit - data?.users >=
                                  currentUserLimit - userLimit
                                ) {
                                  console.log("hi2");
                                  setUserLimit(userLimit - 1);
                                  setPremiumPrice(premiumPrice - 169);
                                  setPremiumGst(premiumGst - 30.42);
                                }
                              }
                            }
                          }
                        }}
                        style={{
                          cursor: `${
                            data?.dayDiff >= 1 ||
                            userLimit >= currentUserLimit ||
                            data?.dayDiff < 0
                              ? "pointer"
                              : "not-allowed"
                          }`,
                        }}
                      />
                      {userLimit}
                      <AiOutlinePlus
                        color="#00000080"
                        onClick={() => {
                          userLimit <= 200 && setUserLimit(userLimit + 1);
                          setPremiumPrice(premiumPrice + 169);
                          setPremiumGst(premiumGst + 30.42);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Card.Text>
                  </Card>
                </div>
              </Collapse>
            </Card.Body>
          </Card>
          {/* Third card */}

          <Card
            style={{
              color: "#000000",
              // width: "30rem",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
            border={enterprise === "enterprise" ? "primary" : ""}
            // ref={refEnterprise}
          >
            {/* <div
              className="d-flex align-items-start"
              style={{
                width: "9px",
                height: `${heightEnterprise + "px"}`,
                background: " #3107CB",
                borderRadius: "10px 0px 0px 10px",
                position: "absolute",
              }}
            ></div> */}
            <Card.Body>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <Radio
                  id="react"
                  name="type"
                  label="React"
                  color="primary"
                  value={"enterprise"}
                  checked={enterprise === "enterprise"}
                  onChange={(e) => {
                    setIsOpenPremium(false);
                    setIsOpenBasic(false);
                    setIsOpenEnterprise(true);
                    setEnterprise("enterprise");
                    setBasic("");
                    setPremium("");
                    setPrice("NA");
                  }}
                  onClick={handleClick}
                  disabled={
                    whichPlanSubscribed !== "Trial Version" && data?.dayDiff > 2
                  }
                />

                <div>
                  <Card.Title>Enterprise/On-premise</Card.Title>
                  <Card.Text
                    style={{
                      color: "#5A5A5C",
                      lineHeight: "20px",
                      color: "#000000A6",
                      fontSize: "15px",
                      letterSpacing: "0.07em",
                    }}
                  >
                    Our Quantum based End-to-End encryption technology secures
                    your data through
                  </Card.Text>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      width: "100px",
                    }}
                    className="d-flex align-items-center"
                  >
                    Contact Sales
                  </p>
                </div>
              </div>

              {/* NEXT CONTENT */}
              <Collapse isOpen={isOpenEnterprise}>
                <div
                  className="d-flex align-items-center justify-content-between pl-5 pr-4"
                  // style={{ paddingLeft: "-2px" }}
                >
                  <div className="d-flex flex-column">
                    <h1 style={{ fontSize: "14px" }}>Coupon Code</h1>
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#5A5A5C",
                        lineHeight: "20px",
                        color: "#000000A6",
                        fontSize: "15px",
                        letterSpacing: "0.07em",
                      }}
                    >
                      Your Enterprise License Code
                    </p>
                  </div>

                  <TextField
                    id="filled-basic"
                    label="Code"
                    variant="filled"
                    placeholder="Please enter the Code"
                    size="small"
                    style={{ width: "8rem" }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  {/* <div
                    className="d-flex align-items-center mr--4  w-40"
                    // style={{ marginLeft: "-2px" }}
                  >
                  </div> */}
                </div>
              </Collapse>
            </Card.Body>
          </Card>

          {/* DISPLAYING VALUES ON THE BASIS OF RADIO BUTTON */}
          <div className="mt-4">
            <div className="d-flex justify-content-between mb-2">
              <h1
                style={{
                  color: "#000000",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                }}
                className="font-bold"
              >
                Number Of Users:
              </h1>
              <h1 style={{ color: "#000000" }} className="font-bold">
                {enterprise ? "On/Premise" : userLimit}
              </h1>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <h1
                style={{
                  color: "#000000",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                }}
                className="font-bold"
              >
                Total Subscription Cost:
              </h1>
              <h1
                style={{ color: "#000000" }}
                className="font-bold  d-flex align-items-center"
              >
                {(basic || premium) && <FaRupeeSign />}{" "}
                {basic
                  ? basicPrice
                  : premium
                  ? premiumPrice
                  : enterprise
                  ? price
                  : ""}
              </h1>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <h1
                style={{
                  color: "#979797",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                }}
                className="font-bold"
              >
                GST on Total Cost (18%):
              </h1>
              <h1
                style={{ color: "#000000" }}
                className="font-bold  d-flex align-items-center"
              >
                {(basic || premium) && <FaRupeeSign />}{" "}
                {basic
                  ? ((basicPrice * 18) / 100).toFixed(2)
                  : premium
                  ? ((premiumPrice * 18) / 100).toFixed(2)
                  : enterprise
                  ? "NA"
                  : ""}
              </h1>
            </div>
            <div className="d-flex justify-content-between ">
              <h1
                style={{
                  color: "#000000",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "17px",
                }}
                className="font-bold"
              >
                Total Payable Amount:
              </h1>
              <h1
                style={{ color: "#000000" }}
                className="font-bold  d-flex align-items-center"
              >
                {(basic || premium) && <FaRupeeSign />}{" "}
                {/* {basic && whichPlanSubscribed === "Trial Version"
                  ? ((basicPrice * 18) / 100 + basicPrice).toFixed(2)
                  : // DIRECTLY SHIFTING FROM TRIAL TO PREMIUM
                  premium && whichPlanSubscribed === "Trial Version"
                  ? ((premiumPrice * 18) / 100 + premiumPrice).toFixed(2)
                  : enterprise
                  ? "NA"
                  : // SHIFTING FROM BASIC TO PREMIUM - works if users are same
                  whichPlanSubscribed === "basic" &&
                    premium &&
                    userLimit === currentUserLimit
                  ? (
                      ((premiumPrice * 18) / 100 + premiumPrice).toFixed(2) -
                      amountReceived
                    ).toFixed(2)
                  : whichPlanSubscribed === "basic" &&
                    premium &&
                    userLimit > currentUserLimit
                  ? // premiumPrice - basicPrice + rest premium price user
                    (premiumPrice * 18) / 100 +
                    premiumPrice -
                    amountReceived +
                    ((userLimit - currentUserLimit) * 169 +
                      // TAX CALCULATION
                      (
                        ((userLimit - currentUserLimit) * 169 * 18) /
                        100
                      ).toFixed(2))
                  : // admin wants to increase users in current month then dayDiff should be more than 3
                  whichPlanSubscribed === "premium" &&
                    premium &&
                    userLimit > currentUserLimit &&
                    data?.dayDiff > 1
                  ? (
                      parseFloat((userLimit - currentUserLimit) * 169) +
                      // TAX CALCULATION
                      parseFloat(
                        ((userLimit - currentUserLimit) * 169 * 18) / 100
                      )
                    ).toFixed(2)
                  : ""} */}
                {(basic || premium || enterprise) && totalAmount}
              </h1>
            </div>
          </div>

          {/* Admins cant purchase the plan */}
          {(basic || premium) && isSuperAdmin === true ? (
            <>
              {/* {console.log("first match")} */}
              <div className="d-flex align-items-center justify-content-center mt-4">
                <Button
                  variant="primary"
                  style={{ borderRadius: "10px", width: "100%" }}
                  onClick={saveEnterpriseSubscription}
                  disabled={totalAmount === 0}
                >
                  Proceed Payment
                </Button>
              </div>
              {/* <p
                style={{
                  color: "#868585",
                  textDecoration: "underline",
                  display: "flex",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
                onClick={toggleComparison}
              >
                Show full comparison
              </p> */}
            </>
          ) : // SHIFTING FROM PREMIUM TO BASIC BEFORE BILL CYCLE ENDS
          whichPlanSubscribed === "premium" && basic && data?.dayDiff !== 1 ? (
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Tooltip title="Plan degradation not allowed">
                <Button
                  variant="primary"
                  style={{ borderRadius: "10px", width: "100%" }}
                  disabled
                >
                  Proceed Payment
                </Button>
              </Tooltip>
            </div>
          ) : // SHIFTING  FROM TRIAL TO BASIC/PREMIUM
          (basic || premium) &&
            isSuperAdmin === true &&
            whichPlanSubscribed === "Trial Version" ? (
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <Button
                  variant="primary"
                  style={{ borderRadius: "10px", width: "100%" }}
                  onClick={saveEnterpriseSubscription}
                >
                  Proceed Payment
                </Button>
              </div>
              {/* <p
                style={{
                  color: "#868585",
                  textDecoration: "underline",
                  display: "flex",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
                onClick={toggleComparison}
              >
                Show full comparison
              </p> */}
            </>
          ) : // ENTERPRISE IS SELECTED AND THERE IS NO CODE ENTERED
          enterprise === "enterprise" &&
            !code &&
            (whichPlanSubscribed === "basic" ||
              whichPlanSubscribed === "premium" ||
              whichPlanSubscribed === "Trial Version") ? (
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Tooltip title="Enter code">
                <Button
                  variant="primary"
                  style={{ borderRadius: "10px", width: "100%" }}
                  disabled
                >
                  Proceed Payment
                </Button>
              </Tooltip>
            </div>
          ) : // NOTHING IS SELECTED DISABLE THE PROCEED BUTTON
          !basic && !premium && !enterprise ? (
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Tooltip title="Select any plan">
                <Button
                  variant="primary"
                  style={{ borderRadius: "10px", width: "100%" }}
                  disabled
                >
                  Proceed Payment
                </Button>
              </Tooltip>
            </div>
          ) : // CODE IS ENTERED AND ENTERPRISE IS SELECTED
          enterprise && code ? (
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <Button
                  variant="primary"
                  style={{ borderRadius: "10px", width: "100%" }}
                  onClick={apply}
                >
                  Apply Coupon Code
                </Button>
              </div>
              {/* <p
                style={{
                  color: "#868585",
                  textDecoration: "underline",
                  display: "flex",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
                onClick={toggleComparison}
              >
                Show full comparison
              </p> */}
            </>
          ) : (
            // OTHERWISE YOU ARE NOT SUPER ADMIN
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Tooltip title="You are not super admin">
                <Button
                  variant="primary"
                  style={{ borderRadius: "10px", width: "100%" }}
                  disabled
                >
                  Proceed Payment
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
