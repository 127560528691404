import "@material-tailwind/react/tailwind.css";
import Buttons from "@material-tailwind/react/Button";
import React from "react";

const ButtonComp = ({ text, toggle }) => {
	if (text === "Invite") {
		return (
			<Buttons
				color='blue'
				className='neww'
				rounded={true}
				ripple='light'
				onClick={toggle}
			>
				Invite
			</Buttons>
		);
	} else if (text === "Yes") {
		return (
			<Buttons
				color='blue'
				className='neww'
				rounded={true}
				ripple='light'
				onClick={toggle}
			>
				Yes
			</Buttons>
		);
	} else {
		return (
			<Buttons
				buttonType='outline'
				size='regular'
				rounded={true}
				block={false}
				iconOnly={false}
				ripple='dark'
				className='bt'
				onClick={toggle}
			>
				No
			</Buttons>
		);
	}
};

export default ButtonComp;
