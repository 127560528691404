/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

const EditHeader = () => {
  const username = JSON.parse(localStorage.getItem("user"))?.name;

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "60px",
          backgroundImage:
            "url(" + require("assets/img/theme/profile-cover.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className=" bg-meme" />
        {/* Header container */}
        {/*
                <Container className="align-items-center" fluid>
                    <Row>
                        <Col>
                            <h3 className="text-black font-weight-bold pt-3" style={{ letterSpacing: 1 }}>Hello {username}</h3>
                            <h6 className='text-black'>This is your profile page where you can see and edit all your information.</h6>
                            {/*<p className="text-white mt-0 mb-5">*/}
        {/*    This is your profile page. You can see the progress you've*/}
        {/*    made with your work and manage your projects or assigned tasks*/}
        {/*</p>*/}
        {/* <Button
                                color="info"
                                onClick={() => history.push('/admin/user-profile')}
                            >
                                Back to your HomePage
                            </Button> */}
        {/* </Col>
                    </Row>
                </Container>
                        */}
      </div>
    </>
  );
};

export default EditHeader;
