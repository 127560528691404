/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import ConfirmEmail from "./views/examples/ConfirmEmail";
import EditProfile from "./views/examples/EditProfile";
import UsersTable from "./views/examples/UsersTable";
import ResetPassword from "./views/examples/ResetPassword";
import ConfirmPassword from "./views/examples/ConfirmPassword";
import ResetPasswordSuccess from "./views/examples/ResetPasswordSuccess";
import OTP from "views/examples/Otp.js";
import Integrations from "views/examples/Integrations";
import EmployeeInvite from "views/examples/EmployeeInvite";
import Basic from "views/examples/EmailInvite";
import ForwardsPage from "./views/examples/ForwardsPage";
import AuditSession from "./views/examples/AuditSession";
import Subscriptions from "./views/examples/Subscriptions";
import NewAdmin from "views/examples/NewAdmin";
import TwoFactorAuth from "views/examples/TwoFactorAuth";
import CheckoutSuccess from "views/examples/CheckoutSuccess";
import Payments from "views/examples/Payments";
import LogManagement from "views/examples/LogManagement";
import NewLogin from "views/examples/NewLogin";
import DeleteAccountInformation from "./views/examples/delete_form";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "dashboard.png",
    component: UsersTable,
    layout: "/admin",
    api: false,
  },

  // {
  //   path: "/users",
  //   name: "Users",
  //   icon: "user.png",
  //   component: UsersTable,
  //   layout: "/admin",
  //   api: true
  // },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: NewLogin,
    layout: "/auth",
    api: true,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    api: true,
  },
  {
    path: "/newAdmin",
    name: "Add Admin",
    icon: "ni ni-circle-08 text-pink",
    component: NewAdmin,
    layout: "/auth",
    api: true,
  },
  {
    path: "/OTP",
    name: "OTP",
    icon: "ni ni-circle-08 text-pink",
    component: OTP,
    layout: "/auth",
    api: true,
  },
  {
    path: "/twoFaOtp",
    name: "TwoFactorAuth",
    icon: "ni ni-circle-08 text-pink",
    component: TwoFactorAuth,
    layout: "/auth",
    api: true,
  },
  {
    path: "/confirm-email/:id",
    name: "Confirm Email",
    icon: "ni ni-check-bold text-green",
    component: ConfirmEmail,
    layout: "/auth",
    api: true,
  },
  {
    path: "/edit-profile",
    name: "My Profile",
    icon: "user.png",
    component: EditProfile,
    layout: "/adminTest",
    api: true,
  },
  {
    path: "/integrations",
    name: "App Settings",
    icon: "integrations.png",
    component: Integrations,
    layout: "/admin",
    api: true,
  },
  {
    path: "/employee-invite",
    name: "Employee Invites",
    icon: "employeeInvite.png",
    component: Basic,
    layout: "/admin",
    api: true,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    icon: "ni ni-folder-17 text-pink",
    component: ResetPassword,
    layout: "/auth",
    api: true,
  },
  {
    path: "/delete-form",
    name: "delete form",
    icon: "ni ni-folder-17 text-pink",
    component: DeleteAccountInformation,
    layout: "/auth",
    api: true,
  },
  {
    path: "/confirm-password",
    name: "Confirm Password",
    icon: "ni ni-folder-17 text-pink",
    component: ConfirmPassword,
    layout: "/auth",
    api: true,
  },
  {
    path: "/reset-success",
    name: "Password Reset Confirmed",
    icon: "ni ni-folder-17 text-pink",
    component: ResetPasswordSuccess,
    layout: "/auth",
    api: false,
  },
  {
    path: "/forwards-page",
    name: "Track Forwards",
    icon: "dataForensics.png",
    component: ForwardsPage,
    layout: "/admin",
    api: false,
  },
  {
    path: "/audit",
    name: "Audit & Sessions",
    icon: "audit.jpg",
    component: AuditSession,
    layout: "/admin",
    api: false,
  },
  {
    path: "/subscription",
    name: "Subscriptions",
    icon: "subscription.jpg",
    component: Subscriptions,
    layout: "/admin",
    api: false,
  },
  {
    path: "/checkout-success",
    name: "CheckoutSuccess",
    component: CheckoutSuccess,
    layout: "/adminTest",
    api: true,
  },
  {
    path: "/payments",
    name: "Credits & Payments",
    component: Payments,
    layout: "/admin",
    api: true,
  },
  {
    path: "/logManagement",
    name: "Log Management",
    component: LogManagement,
    layout: "/admin",
    api: true,
  },
];
export default routes;
