import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
} from "react";
// import memeLogo from "../../../assets/img/icons/common/memeLogo.png";
import memeLogo from "../../assets/img/icons/common/MeMeOg.png";
import {
    Button,
    //Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label,
    Row,
    Col,
} from "reactstrap";
import ValidatedInput from "../../functions/ValidatedInput";
import {
    getTwoFa,
    login,
    loginCheck,
    sendOtpFor2FA,
    verifyRecaptcha,
    validateLogin,
    sendOtp,
} from "../../network/ApiAxios";
import { RotatingLines } from "react-loader-spinner";
import { sha256 } from "js-sha256";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserId } from "../../components/redux/adminActions";
import customToast from "./ToastComponent";
import ReCAPTCHA from "react-google-recaptcha";
import "../../assets/css/login.css";
import Banner from "../ReusableComponents/Banner";
// import Footer from "views/footer/Footer";
import Footer from "components/Footers/AdminFooter";

const DeleteAccountInformation = ({ props }) => {
    const emailLink = <a href="mailto: technology@arishti.in" className="font-bold" style={{ color: "blue" }}>technology@arishti.in</a>;

    return (
        <>
            {/* <Banner />, */}
            <div className="fixed smallNewGreenCircleBottom d-none d-lg-block"></div>
            <div
                className="d-flex align-items-center pt-4"
            // style={{ backgroundColor: "#edf0f5" }}
            >
                <div className="card-body text-black">
                    {/* <div className="fixed loginGreenCircleHollow d-none d-lg-block "></div> */}
                    <div className="d-flex align-items-center justify-content-center">
                        <img
                            src={memeLogo}
                            alt="logo"
                            style={{
                                width: "200px",
                                height: "54px",
                            }}
                        />
                    </div>
                    <h3 className="h3heading text-center mt-5">Delete account request</h3>
                    <br></br>
                    <div className="d-flex align-items-center justify-content-center">
                        <hr className="hr1" />
                    </div>
                    <br />
                    <b>Data Deletion Right</b>
                    <div className="rowkey">
                        <p>
                            If a user contacts us via {emailLink} for bug report or help & feedback, their email will be collected by us for
                            the sole purpose of communication and resolving the issue. However, if the user wishes to exercise their Data Deletion Right, they can
                            request us to delete their email and associated data from our records by contacting us through the same email address. We will
                            promptly respond to the user's request and take necessary actions to delete their data from our records.
                        </p>
                    </div>
                    <hr className="hr2" />
                    <br></br>
                    <b>Instructions for Requesting Data Deletion</b>
                    <div className="rowkey">
                        <p>
                            Instructions for Requesting Data Deletion
                            To request data deletion, users may send an email to {emailLink} from the email address that is valid or
                            registered with the app data. The subject line of the email should read 'MessageMe data deletion request' and the email should
                            include all necessary details.
                        </p>
                    </div>
                    <hr className="hr2" />
                    <br></br>
                    <b>Read Our Privacy Policy to Understand Data Use</b>
                    <div className="rowkey">
                        <p>
                            We take our users' privacy seriously and are committed to protecting their personal information. To better understand how your data
                            is used by us or our app, we encourage you to read our <a href="https://arishti.in/privacy.html" style={{ color: "red" }}>privacy policy</a>. Our policy provides a detailed explanation of the types of data
                            we collect, how we use it, and who we share it with. We also outline the measures we take to ensure the security and confidentiality of
                            your information.
                            By familiarizing yourself with our privacy policy, you can be assured that your personal information is being handled in a responsible
                            and transparent manner. If you have any questions or concerns about our policy or how your data is being used, please do not hesitate
                            to contact us. We value your trust and appreciate the opportunity to serve you.
                        </p>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
};

export default DeleteAccountInformation;
