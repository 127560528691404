/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { ReactMultiEmailCustom } from "react-multi-email-custom";
import "../../assets/css/emailInvite.css";

// ----------------------------------------------------------Employee Invite Part
import Basic from "./EmailInvite.js";

import CardMedia from "@material-ui/core/CardMedia";
import { Card, CardContent } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import invite from "../../assets/Invite.png";
// -------------------------------------------------------------------------------
// reactstrap components
import {
  Button,
  // Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { MdCancel } from "react-icons/md";
import {
  forgotPassword,
  login,
  sendEmployeeInvite,
} from "../../network/ApiAxios";
import Toast from "react-bootstrap/Toast";
import config from "../../config";

import logo from "../../assets/Message Me - Logo on Dark.png";
import banner from "../../assets/Sign In Illustration.svg";
import banner1 from "../../assets/Sign In Illustration – 2.svg";

import "../../assets/css/bootstrap.css";
import "../../assets/css/login.css";

import emails from "./EmailInvite";

const EmployeeInvite = (props) => {
  const [emailOtp, setEmailOtp] = useState("");
  const [email, setEmail] = useState("");
  const [numberOtp, setNumberOtp] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);
  const [open, setOpen] = useState(false);

  // const sendEmployeeInvite1 = async () => {

  //     // const response = await sendEmployeeInvite(email);
  //     // const {data} = response;
  //     // if (data.success) {
  //     //     console.log(data);
  //     //     props.history.push("/auth/login");
  //     // } else {
  //     //     setError(data.errors[0].msg);
  //     // }

  // }

  // const handleClick = (event) => {
  //     event.preventDefault()
  //     sendEmployeeInvite1();
  // }

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <div>
        <Dialog
          open={open}
          onClose={onCloseModal}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              borderRadius: "0.5em",
              padding: "0",
            },
          }}
          center
        >
          <DialogContent style={{ padding: "0" }}>
            <Card style={{ margin: "0", padding: "0" }}>
              <div>
                <CardContent style={{ margin: "0", padding: "0" }}>
                  <div style={{ position: "relative" }}>
                    <CardMedia component="img" image={invite} height="225px" />
                    <div
                      style={{
                        position: "absolute",
                        color: "white",
                        bottom: "40%",
                        left: "30%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: "bolder",
                          fontSize: "calc(0.85em + 1.25vmin)",
                        }}
                      >
                        Invite members to MeMe
                      </h4>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>
            <div
              style={{
                position: "relative",
                width: "100%",
                left: "50%",
                transform: "translate(-50%)",
                textAlign: "center",
                color: "#3e3e3e",
                marginTop: "5%",
              }}
            >
              <h6 style={{ fontSize: "calc(0.75em + 1vmin)" }}>
                Onboarding made easy with our solution
              </h6>
            </div>
            <div
              style={{
                width: "96%",
                marginLeft: "2%",
              }}
            >
              <label
                style={{
                  color: "#585858",
                  marginTop: "3%",
                }}
              >
                Enter Email Addresses{" "}
              </label>
              <Basic />
            </div>
          </DialogContent>
          <DialogActions>
            <div
              className="btn-group-vertical"
              style={{
                position: "relative",
                width: "100%",
                transform: "translate(-50%)",
                textAlign: "center",
                color: "#3107CB",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Button
                onClick={onCloseModal}
                style={{
                  backgroundColor: "#3107CB",
                  color: "white",
                  borderRadius: "0",
                  marginTop: "3%",
                  left: "50%",
                  fontWeight: "bold",
                  opacity: "0.8",
                }}
              >
                Invitess
              </Button>
              <Link
                onClick={onCloseModal}
                className="inline-block-child forgettext"
                style={{ marginLeft: "95%" }}
              >
                Cancel
              </Link>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div className="row no-gutters">
        <div className="col-md-8 no-gutters">
          <div className="rightside">
            {/* <Form role="form" onSubmit={() => {
                        handleClick(values)
                      }} > */}
            <div className="centered">
              {/* <h3 className="h2heading">Invite Employee</h3> */}
              <br></br>
              {/* <FormGroup className=" form-group p-sm-1">
                            <div className="floating-label">
                                <input
                                    id="email"
                                    className="form-control"
                                    type="text"
                                    name="emails"
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    required />
                                <label for="first">Email</label>
                            </div>
                          </FormGroup>  */}

              {/* <FormGroup className="form-group p-sm-1">
                    <div className="floating-label">
                      <input
                          id="emailOtp"
                          className="form-control"
                          type="number"
                          name="emailOtp"
                          onChange={e => setEmailOtp(e.target.value)}
                          value={emailOtp}
                          autoComplete="email"
                          required />
                      <label htmlFor="first">Email OTP</label>
                  </div>
                  </FormGroup> */}
              {/* <FormGroup  className=" form-group p-sm-1">
                      <div className="floating-label">
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          name="password"
                          onChange={e => setPassword(e.target.value)}
                          value={password}
                          autoComplete="password"
                          required/>
                        <label htmlFor="password">Password</label>
                    </div>
                  </FormGroup> */}
              {/*<div className="custom-control custom-control-alternative custom-checkbox">*/}
              {/*    <input*/}
              {/*        className="custom-control-input"*/}
              {/*        id=" customCheckLogin"*/}
              {/*        type="checkbox"*/}
              {/*    />*/}
              {/*    <label*/}
              {/*        className="custom-control-label"*/}
              {/*        htmlFor=" customCheckLogin"*/}
              {/*    >*/}
              {/*        <span className="text-muted">Remember me</span>*/}
              {/*    </label>*/}
              {/*</div>*/}
              {/* {error ?
                      <div className="text-muted font-italic">
                          <small>
                              error:{" "}
                              <span className="text-red font-weight-700">{error}</span>
                          </small>
                      </div> : null } */}

              <Button
                color="primary"
                type="submit"
                className="signIn"
                onClick={onOpenModal}
              >
                Invitessss
              </Button>
            </div>
            {/* </Form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInvite;

//                 aria-live="polite"
//                 aria-atomic="true"
//                 style={{
//                     position: 'fixed',
//                     minHeight: '100px',
//                     width: "35%",
//                     right: 10,
//                     bottom: 100,
//                     zIndex: 50
//                 }}
//             >
//                 <Toast style={{
//                     position: 'absolute',
//                     top: 0,
//                     right: 0,
//                     backgroundColor: "white",
//                     padding: 10,
//                     borderRadius: 10
//                 }} onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide={!config.DEMO}>
//                     <Toast.Header>
//                         <img style={{height: "30px", width: "100px"}} src={require("assets/img/brand/argon-react.png").default}  alt="..."/>
//                     </Toast.Header>
//                     <Toast.Body>
//                         {toastMessage}
//                         {config.DEMO ?
//                             <a href={config.DOMAIN_NAME + '/auth/confirm-password/' + userID}>
//                                 {config.DOMAIN_NAME + '/auth/confirm-password/' + userID}
//                             </a> : null}
//                     </Toast.Body>
//                 </Toast>
//             </div>
//             <Col lg="5" md="7">
//                 <Card className="bg-secondary shadow border-0">
//                     <CardBody className="px-lg-5 py-lg-5">
//                         <Form role="form">
//                             <FormGroup className="mb-3">
//                                 <InputGroup className="input-group-alternative">
//                                     <InputGroupAddon addonType="prepend">
//                                         <InputGroupText>
//                                             <i className="ni ni-key-25"/>
//                                         </InputGroupText>
//                                     </InputGroupAddon>
//                                     <Input placeholder="Email OTP" type="number" value={emailOtp}
//                                            onChange={e => setEmailOtp(e.target.value)}/>
//                                 </InputGroup>
//                             </FormGroup>
//                             <FormGroup className="mb-3">
//                                 <InputGroup className="input-group-alternative">
//                                     <InputGroupAddon addonType="prepend">
//                                         <InputGroupText>
//                                             <i className="ni ni-key-25"/>
//                                         </InputGroupText>
//                                     </InputGroupAddon>
//                                     <Input placeholder="Email" type="number"  value={numberOtp}
//                                            onChange={e => setNumberOtp(e.target.value)}/>
//                                 </InputGroup>
//                             </FormGroup>
//                             {error ?
//                                 <div className="text-muted font-italic">
//                                     <small>
//                                         error:{" "}
//                                         <span className="text-red font-weight-700">{error}</span>
//                                     </small>
//                                 </div> : null }
//                             <div className="text-center">
//                                <a href={config.DOMAIN_NAME + '/auth/confirm-email/' + props.location.customNameData}> <Button className="my-4" color="primary" type="button" onClick={verifyOtp}>
//                                     Register Admin
//                                 </Button> </a>
//                             </div>
//                         </Form>
//                     </CardBody>
//                 </Card>
//             </Col>
//         </>
