/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import {
  forgotPassword,
  login,
  sendOtp,
  updateRegisterModel,
  verifyOtp,
} from "../../network/ApiAxios";
import Toast from "react-bootstrap/Toast";
import config from "../../config";

import logo from "../../assets/Message Me - Logo on Dark.png";
import banner from "../../assets/Sign In Illustration.svg";
import banner1 from "../../assets/Sign In Illustration – 2.svg";
import memeLogo from "../../assets/img/icons/common/memeLogo.png";

import "../../assets/css/bootstrap.css";
import "../../assets/css/login.css";
import registrationSvg from "../../assets/registration.svg";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { useSelector } from "react-redux";
import Countdown, { zeroPad } from "react-countdown";
import { useEffect } from "react";
import OTPInput from "react-otp-input";
import customToast from "./ToastComponent";

const OTP = (props) => {
  const [emailOtp, setEmailOtp] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [disableResend, setDisableResend] = useState(true);
  const [timer, setTimer] = useState(Date.now());
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);
  const { userId } = useSelector((state) => state.setUserIdReducer);
  const { currentDate } = useSelector((state) => state.setCurrentDateReducer);
  const [email, setEmail] = useState("");
  const verifyOtp1 = async () => {
    const response = await verifyOtp(props.location.customNameData, emailOtp);
    const { data } = response;
    console.log(data, "data otp");
    if (data.success === true) {
      console.log(data);
      customToast(
        true,
        "",
        "",
        "OTP sent",
        "Kindly check your registered mail address and enter the OTP",
        "Check Email",
        ""
      );
      if (!userId) {
        props.history.push(`/auth/login`);
      }
      props.history.push("/auth/login");
    } else {
      setError(data.errors);
      customToast(
        "",
        true,
        "",
        "Enter correct OTP",
        `${data.message}`,
        "Try Again",
        ""
      );
      setEmailOtp("");
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    verifyOtp1();
  };

  useEffect(() => {
    if (!userId) {
      props.history.push(`/auth/login`);
    }
  }, []);

  useEffect(() => {
    if (disableResend === true) {
      setTimeout(() => {
        setDisableResend(false);
      }, 5 * 60 * 1000);
    }
  }, [disableResend]);

  useEffect(() => {
    setTimer(Date.now() + 5 * 60 * 1000);
  }, []);

  const callAgain = async () => {
    setTimeout(() => {
      setDisableResend(false);
    }, 5 * 60 * 1000);

    setTimer(Date.now() + 5 * 60 * 1000);

    if (!userId) {
      props.history.push(`/auth/login`);
    } else {
      const { data } = await sendOtp(userId);
      if (data.success === true) {
        // console.log(data);
        setDisableResend(true);
        customToast(
          true,
          "",
          "",
          "OTP sent",
          "Kindly check your registered mail address and enter the OTP",
          "Check Email",
          ""
        );
      } else {
        setError(data.errors);

        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          "Entered OTP is wrong or it has been expired",
          "Try Again",
          ""
        );
        setEmailOtp("");
      }
    }
  };

  const renderer = ({ minutes, seconds }) => {
    // Render a countdown
    // console.log("hii");
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const handleChangeOtp = (emailOtp) => {
    setEmailOtp(emailOtp);
  };
  console.log(props);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="">
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col col-xl-9 mid">
              <div
                // className="card"
                style={{
                  borderRadius: "8px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  border: "1px solid rgba(0,0,0,0.125)",
                }}
              >
                <div className="">
                  {/* <div className="col-md-6 d-none d-md-block resetImage overflow-hidden"></div> */}
                  <div className="d-flex align-items-center justify-content-around">
                    <div>
                      <img
                        src={registrationSvg}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        className="d-flex align-items-center justify-content-center pt-4 "
                      />
                    </div>
                    <div
                      className=" d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <div className=" text-black p-4 p-md-2">
                        <img
                          src={memeLogo}
                          alt="logo"
                          style={{
                            top: "7px",
                            position: "absolute",
                            right: "0px",
                            paddingRight: "15px",
                          }}
                        />
                        <h3 className="h2heading text-center mt-md--2">
                          {props.location.accountConfirmation === false
                            ? "Account Verification"
                            : "OTP Verification"}
                        </h3>
                        <p className="text-center">
                          Enter the verification code we sent to
                        </p>
                        <p className="text-center font-bold">
                          {props.location.email}
                        </p>
                        <br></br>
                        {/* 
                      <Form
                        role="form"
                        onSubmit={(values) => {
                          handleClick(values);
                        }}
                      >
                        <FormGroup className="form-group p-sm-1">
                          <div className="floating-label">
                            {/* <input
                              id="emailOtp"
                              className="form-control"
                              type="number"
                              name="emailOtp"
                              onChange={(e) => setEmailOtp(e.target.value)}
                              value={emailOtp}
                              autoComplete="email"
                              required
                            /> */}
                        {/* <label htmlFor="first">Email OTP</label> */}
                        {/* </div>
                        </FormGroup>
                      </Form> */}
                        <div className="d-flex justify-content-center w-100 align-items-center bg-grey flex-col">
                          <p className="d-flex align-items-start  w-100 pl-2 pb-2">
                            Type your 6 digit security code
                          </p>
                          <OTPInput
                            value={emailOtp}
                            onChange={handleChangeOtp}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            inputStyle="inputStyle"
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        {disableResend === true ? (
                          <div className="d-flex align-items-center mb-2 pt-2">
                            <p className="mr-2 pl-2 ">Time Remaining: </p>{" "}
                            <Countdown
                              date={timer}
                              renderer={renderer}
                              zeroPadTime={2}
                              autoStart={true}
                              key={timer}
                            />
                          </div>
                        ) : (
                          <div className="d-flex align-items-center mb-2 pt-2">
                            <p className="mr-2 pl-2">Time Remaining: </p>{" "}
                            <Countdown
                              date={timer}
                              renderer={renderer}
                              zeroPadTime={2}
                              autoStart={true}
                              key={timer}
                            />
                          </div>
                        )}
                        <div className="d-flex align-items-center justify-content-between pt-2 ml-2">
                          <a
                            href={
                              config.DOMAIN_NAME +
                              "/auth/confirm-email/" +
                              props.location.customNameData
                            }
                          >
                            <Button
                              // color="primary"
                              type="submit"
                              className="signIn"
                              onClick={(e) => handleClick(e)}
                              style={{
                                boxShadow: "none",
                                borderColor: "rgba(217, 217, 217, 0.48)",
                                width: "22rem",
                                cursor:
                                  !emailOtp || timer === 1698497713679
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              disabled={!emailOtp || timer === 1698497713679}
                            >
                              Verify OTP
                            </Button>{" "}
                          </a>
                          <Button
                            color="primary"
                            className={`${
                              disableResend === true
                                ? "resend ml-2 cursor-not-allowed"
                                : "resend ml-2"
                            }`}
                            style={{
                              boxShadow: "none",
                              marginRight: "5px",
                              background: "rgba(217, 217, 217, 0.48)",
                              borderColor: "rgba(217, 217, 217, 0.48)",
                              color: "#6A6969",
                              width: "12rem",
                            }}
                            onClick={callAgain}
                            disabled={disableResend}
                          >
                            Resend OTP
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="p-3 my-2 rounded"
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "15%",
          right: 10,
          top: 50,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: 20,
            backgroundColor: "white",
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header
            style={{
              color: "#3107CB",
              padding: 5,
              icon: "danger",
            }}
          >
            <img
              style={{ height: "30px", width: "100px" }}
              src={require("assets/img/brand/message-me.png").default}
            />
          </Toast.Header>
          <Toast.Body
            style={{
              color: "red",
              padding: 5,
            }}
          >
            {toastMessage}
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default OTP;

// <>
//             <div
//                 aria-live="polite"
//                 aria-atomic="true"
//                 style={{
//                     position: 'fixed',
//                     minHeight: '100px',
//                     width: "35%",
//                     right: 10,
//                     bottom: 100,
//                     zIndex: 50
//                 }}
//             >
//                 <Toast style={{
//                     position: 'absolute',
//                     top: 0,
//                     right: 0,
//                     backgroundColor: "white",
//                     padding: 10,
//                     borderRadius: 10
//                 }} onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide={!config.DEMO}>
//                     <Toast.Header>
//                         <img style={{height: "30px", width: "100px"}} src={require("assets/img/brand/argon-react.png").default}  alt="..."/>
//                     </Toast.Header>
//                     <Toast.Body>
//                         {toastMessage}
//                         {config.DEMO ?
//                             <a href={config.DOMAIN_NAME + '/auth/confirm-password/' + userID}>
//                                 {config.DOMAIN_NAME + '/auth/confirm-password/' + userID}
//                             </a> : null}
//                     </Toast.Body>
//                 </Toast>
//             </div>
//             <Col lg="5" md="7">
//                 <Card className="bg-secondary shadow border-0">
//                     <CardBody className="px-lg-5 py-lg-5">
//                         <Form role="form">
//                             <FormGroup className="mb-3">
//                                 <InputGroup className="input-group-alternative">
//                                     <InputGroupAddon addonType="prepend">
//                                         <InputGroupText>
//                                             <i className="ni ni-key-25"/>
//                                         </InputGroupText>
//                                     </InputGroupAddon>
//                                     <Input placeholder="Email OTP" type="number" value={emailOtp}
//                                            onChange={e => setEmailOtp(e.target.value)}/>
//                                 </InputGroup>
//                             </FormGroup>
//                             <FormGroup className="mb-3">
//                                 <InputGroup className="input-group-alternative">
//                                     <InputGroupAddon addonType="prepend">
//                                         <InputGroupText>
//                                             <i className="ni ni-key-25"/>
//                                         </InputGroupText>
//                                     </InputGroupAddon>
//                                     <Input placeholder="Email" type="number"  value={numberOtp}
//                                            onChange={e => setNumberOtp(e.target.value)}/>
//                                 </InputGroup>
//                             </FormGroup>
//                             {error ?
//                                 <div className="text-muted font-italic">
//                                     <small>
//                                         error:{" "}
//                                         <span className="text-red font-weight-700">{error}</span>
//                                     </small>
//                                 </div> : null }
//                             <div className="text-center">
//                                <a href={config.DOMAIN_NAME + '/auth/confirm-email/' + props.location.customNameData}> <Button className="my-4" color="primary" type="button" onClick={verifyOtp}>
//                                     Register Admin
//                                 </Button> </a>
//                             </div>
//                         </Form>
//                     </CardBody>
//                 </Card>
//             </Col>
//         </>
