import axios from "axios";
import { logout } from "network/ApiAxios";
import config from "../../config";
import { HeaderAnalytics } from "network/ApiAxios";
import customToast from "views/examples/ToastComponent";

export const getDataAction = () => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));

  let token = user?.orgToken;
  try {
    dispatch({ type: "GET_DATA_REQ" });
    try {
      const { data } = await HeaderAnalytics(token);
      // console.log(data, "helo ser");
      if (data.success === false) {
        dispatch({ type: "LOGOUT" });
        localStorage.clear();
      } else {
        dispatch({ type: "GET_DATA_SUCCESS", payload: data });
      }
    } catch (error) {
      console.log(error.response, "error");
      if (error.status === 403) {
        customToast(
          "",
          true,
          "",
          "Too many requests",
          `${error.data.message}`,
          "Try Again after 10 minutes",
          ""
        );
      }
    }
  } catch (e) {
    dispatch({ type: "GET_DATA_FAIL" });
  }
};

// export const loadUser = () => async (dispatch) => {
//   try {
//     dispatch({ type: "LOAD_USER_REQUEST" });
//     const { data } = await instance.get("MEMEUsers/VerifyToken");
//     dispatch({ type: "LOAD_USER_SUCCESS", payload: data.user });
//   } catch (error) {
//     dispatch({ type: "LOAD_USER_FAIL", payload: error.response.data.message });
//   }
// };

// export const updateUserProfile = () => async (dispatch) => {
// 	try {
// 		dispatch({ type: "USER_UPDATE_PROFILE_REQUEST" });
// 		const data = await instance.post("users/edit");
// 		dispatch({ type: "USER_UPDATE_PROFILE_SUCCESS", payload: data });
// 	} catch (error) {
// 		dispatch({
// 			type: "USER_UPDATE_PROFILE_FAIL",
// 			payload: error.response,
// 		});
// 	}
// };
