import React from "react";
import "./styles/Banner.css";

const Banner = () => {
  return (
    <div className="banner">
      <span className="confetti">🎊</span>
      <p
        className="text-white d-flex align-items-center justify-content-center mt-1"
        style={{ fontWeight: "700", fontSize: "14px" }}
      >
        A Finxcelerator product supported by RBIH, ICICI Bank & BITS Pillani{" "}
        <span style={{ fontSize: "20px" }}>🎊</span>
      </p>
    </div>
  );
};

export default Banner;
