import React, { useState } from "react";
import { TiChevronLeftOutline, TiChevronRightOutline } from "react-icons/ti";
import { Fade, Slide } from "react-slideshow-image";
// import slide1 from "../../../assets/img/Auth/SlideShow/slide1.svg";
// import slide2 from "../../../assets/img/Auth/SlideShow/slide2.png";
// import slide3 from "../../../assets/img/Auth/SlideShow/slide3.png";
// import slide4 from "../../../assets/img/Auth/SlideShow/slide4.png";

const slideImages = [
  {
    url: "https://arishti.in/images/traceability.png",
    // url: "https://ik.imagekit.io/a0utmg2rz/profile_pictures/profile_picture_VlD8a8XFN.jpg",
    caption: "Traceability",
    description:
      "MessageMe can facilitates tracking everything they share within the platform, enabling better governance within organization",
  },
  {
    url: "https://arishti.in/images/platform.png",
    caption: "Ensure Communication Safety",
    description:
      "MessageMe ensure your data stays safe & secure with its innovative Quantum technology",
  },
  {
    url: "https://arishti.in/images/audit.png",
    caption: "Control & Monitor",
    description:
      "With MessageMe - enterprise can customize the policy control and monitor the employee communications efficiently.",
  },
  {
    url: "https://arishti.in/images/platform.png",
    caption: "Ensure Communication Safety",
    description:
      "MessageMe ensure your data stays safe & secure with its innovative Quantum technology",
  },
];

const CARDS = slideImages.length;
const MAX_VISIBILITY = 2;
const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "310px",
  width: "700px",
  // borderRadius: "20px",
  // boxShadow:
  //   "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px ",
};

const Card = () => (
  <div className="slide-container">
    <Fade
      arrows={false}
      autoplay={true}
      pauseOnHover={true}
      canSwipe={true}
      duration={4000}
    >
      {slideImages.map((slideImage, index) => (
        <React.Fragment>
          <div className="d-flex flex-col align-items-center justify-content-center">
            <p className="pb-4 font-bold" style={{ fontSize: "1.2rem" }}>
              {slideImage.caption}
            </p>
            <p className="pb-4 d-flex align-items-center justify-content-center text-center font-semibold">
              {slideImage.description}
            </p>
          </div>
          <div
            key={index}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              style={{
                ...divStyle,
                backgroundImage: `url(${slideImage.url})`,
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                // boxShadow:
                // "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                // border: "5px white",
              }}
              className="shadow shadow-xl shadow-intensity-xl carouselImage"
            ></div>
          </div>
          {/* <div className="d-flex align-items-center justify-content-center py-4 flex-col">
                <p className="text-white font-bold">{slideImage.caption}</p>
                <p className="text-white">{slideImage.description}</p>
              </div> */}
        </React.Fragment>
      ))}
    </Fade>
  </div>
);

const Carousel = ({ children }) => {
  console.log(children, "child");
  const [active, setActive] = useState(2);
  const count = React.Children.count(children);

  return (
    <div className="carousel">
      {/* {active > 0 && (
        <button className="nav left" onClick={() => setActive((i) => i - 1)}>
          <TiChevronLeftOutline size={30} />
        </button>
      )} */}
      {React.Children.map(children, (child, i) => (
        <div
          className="carousel-card-container"
          style={{
            "--active": i === active ? 1 : 0,
            "--offset": (active - i) / 3,
            "--direction": Math.sign(active - i),
            "--abs-offset": Math.abs(active - i) / 3,
            "pointer-events": active === i ? "auto" : "none",
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
            display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
          }}
        >
          {child}
        </div>
      ))}
      {/* {active < count - 1 && (
        <button className="nav right" onClick={() => setActive((i) => i + 1)}>
          <TiChevronRightOutline size={30} />
        </button>
      )} */}
    </div>
  );
};

const MainCarousel = () => (
  <div className="d-flex align-items-center justify-content-center pt-2">
    <Carousel>
      {[...new Array(CARDS)].map((_, i) => (
        <Card />
      ))}
    </Carousel>
  </div>
);

export default MainCarousel;
