import Header from "components/Headers/Header";
import { getLogsActions } from "components/redux/adminActions";
import { istConversion } from "functions/timeConversion";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Card, Collapse, Row, Table } from "reactstrap";
import Paginate from "views/ReusableComponents/Paginate";
import { BiSolidDownArrow, BiSolidRightArrow } from "react-icons/bi";
import { answerBasedOnAction } from "functions/randomFunctions";

const LogManagement = () => {
  const dispatch = useDispatch();
  const { loading, logs } = useSelector((state) => state.logReducer);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [currPage, setCurrPage] = useState(0);
  const [openDetails, setOpenDetails] = useState({});

  useEffect(() => {
    dispatch(getLogsActions(userDetails._id));
  }, []);

  const PER_PAGE = 10;
  const offset = currPage * PER_PAGE;
  const pageCount = Math.ceil(logs.length / PER_PAGE);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrPage(selectedPage);
  };

  return (
    <>
      <Header />
      <div
        className="p-4 text-black"
        style={{ marginBottom: logs.length === 0 ? "310px" : "0" }}
      >
        <h1 className="mb-4">Track the activities of your enterprise</h1>

        <Row>
          <div className="col">
            <Card className="shadow">
              <Table className="table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Incident/Activity</th>
                    <th scope="col">IP Address</th>
                    <th scope="col">Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginLeft: "112%" }}
                    >
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#3107cb"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    logs.slice(offset, offset + PER_PAGE).map((l) => (
                      <tr key={l._id}>
                        <td>
                          {/* {openDetails ? (
                            <BiSolidDownArrow />
                          ) : (
                            <BiSolidRightArrow />
                          )} */}
                          {answerBasedOnAction(
                            l.action,
                            l,
                            openDetails,
                            setOpenDetails
                          )}
                        </td>
                        <td>{l.ipAddress}</td>
                        <td>{istConversion(l.createdAt)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </div>
      {!loading && logs.length > 9 && (
        <Paginate pageCount={pageCount} handlePageClick={handlePageClick} />
      )}
    </>
  );
};

export default LogManagement;
