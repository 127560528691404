/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Checkbox,
  FormControl,
  Input,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal as ReactstrapModal,
} from "reactstrap";
import {
  applyCoupoun,
  changeCurrentPassword,
  findOrganizationDetails,
  forwards,
  getPolicyStatus,
  getUser,
  hashPassword,
  sendOtpToNewEmail,
  sendPrivacyDetails,
  updateRollOutVersion,
  userCount,
  verifiedOtp,
} from "../../network/ApiAxios";
import "../../assets/css/reactCalendar.css";
import { getDataAction } from "../redux/dataaction";
import Cards from "@material-tailwind/react/Card";
import Image from "react-bootstrap/Image";
import Paragraph from "@material-tailwind/react/Paragraph";
import Heading6 from "@material-tailwind/react/Heading6";
import { Zoom, toast } from "react-toastify";
import { Oval, ThreeDots } from "react-loader-spinner";
import { Heading1, Heading5 } from "@material-tailwind/react";
import { AiFillAndroid, AiFillCalendar } from "react-icons/ai";
import { setAppVersions } from "components/redux/adminActions";
import { GrAppleAppStore, GrPrevious } from "react-icons/gr";
import termsImg from "../../assets/img/illustrations/termsAndService.png";
import Privacy from "../../assets/img/PrivacyPolicy/Privacy.svg";
import newVersion from "../../assets/img/updates/newUpdates.svg";
import customToast from "views/examples/ToastComponent";
import { MdSimCardDownload } from "react-icons/md";
import H6 from "@material-tailwind/react/Heading6";
import {
  Bar,
  BarChart,
  CartesianGrid,
  DefaultTooltipContent,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useScreenshot, createFileName } from "use-react-screenshot";
import moment from "moment";
import subs from "../../assets/img/subscription/subs1.svg";
import memeLogo from "../../assets/MeMe White Logo.png";
import memeLogoBlue from "../../assets/img/icons/common/memeLogo.png";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import QRCode from "react-qr-code";
import "../../assets/css/new.css";
import "../../assets/css/scrollbar.css";
import Scrollbars from "react-custom-scrollbars-2";
import ValidatedInput from "functions/ValidatedInput";
import { sha256 } from "js-sha256";
import Countdown, { zeroPad } from "react-countdown";
import { FaRupeeSign } from "react-icons/fa";
const Header = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.getDataReducer);
  const { appVersion } = useSelector((state) => state.setAppVersionReducer);
  // const { message } = appVersion;
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("Default");
  const [loader, setLoader] = useState(false);
  const [openTerms, setOpenTerms] = useState(true);
  const [openChangePassword, setOpenChangePassword] = useState(true);
  const [openUpdates, setOpenUpdates] = useState(true);
  const [close, setIsClose] = useState(false);
  const [closeTerms, setIsCloseTerms] = useState(false);
  const [closeChangePassword, setCloseChangePassword] = useState(false);
  const [closeUpdate, setIsCloseUpdate] = useState(false);
  const [code, setCode] = useState("");
  const [remDay, setRemDay] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [selectVersion, setSelectVersion] = useState(false);
  const [IPAnalytics, setIPAnalytics] = useState(false);
  const [appVersionPopup, setAppVersionPopup] = useState(false);
  const [policyStatus, setPolicyStatus] = useState("");
  const [forwardsData, setForwardsData] = useState([]);
  const [IPFileData, setIPFileData] = useState([]);
  const [YearlyIPFileData, setYearlyIPFileData] = useState([]);
  const [MonthlyIPFileData, setMonthlyIPFileData] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [activeBar, setActiveBar] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [currPassword, setCurrPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [newError, setNewError] = useState("");
  const [password, setPassword] = useState("");
  const [loadOtp, setLoadOtp] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [loadChangePassword, setLoadChangePassword] = useState(false);
  const [modalChangeOTP, setModalChangeOTP] = useState(false);
  const [modalOTP, setModalOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  const [timer, setTimer] = useState(Date.now());

  const user = JSON.parse(localStorage.getItem("user"));
  const userLimit = JSON.parse(localStorage.getItem("keys"))?.onBoardUserLimit;
  const showChangePasswordPopup = JSON.parse(localStorage.getItem("showPopup"));
  let userId = JSON.parse(localStorage.getItem("user"))?._id;

  let token = user?.orgToken;
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const downloadScreenshot = (
    image,
    { name = "IP File Forwards Analytics", extension = "png" } = {}
  ) => {
    // const time = new Date(); // normal time
    const time = moment().format("DD/MM/YYYY HH:mm:ss"); // formatted time

    // Create a temporary image element
    const tempImage = new window.Image();
    tempImage.src = image;
    const waitForImageLoad = new Promise((resolve) => {
      tempImage.onload = resolve;
    });
    waitForImageLoad.then(() => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = tempImage.width;
      canvas.height = tempImage.height;

      ctx.drawImage(tempImage, 0, 0);

      ctx.font = "20px Montserrat";
      ctx.textAlign = "center";
      ctx.fillStyle = "black";
      const timestampX = canvas.width / 2;
      const timestampY = canvas.height - 10;
      // ctx.fillText(time.toString(), 10, 30); // if you want to print TIME at top
      ctx.fillText(time.toString(), timestampX, timestampY);

      const dataURL = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.href = dataURL;
      a.download = createFileName(extension, name);
      a.title = time;
      a.click();
    });
  };

  const getImage = () => {
    // disabled download btn when api is loading or forwards are zero
    if (!loader && data?.forwards > 0) {
      takeScreenshot(ref.current).then((image) => downloadScreenshot(image));
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeVersion = (e) => setSelectVersion(e.target.checked);

  useEffect(() => {
    console.log("Token : ", token);
    if (!data || data === null || data === undefined || !data?.success) {
      if (token && token != undefined) {
        token && dispatch(getDataAction());
      }
    }
  }, []);

  const currentDate = new Date();
  const newFormat = currentDate.toLocaleString();
  const [userDate, setUserDate] = useState("");

  const fetchPolicyStatus = async () => {
    const { msg } = await getPolicyStatus(user.email);
    setPolicyStatus(msg);
  };
  useEffect(() => {
    try {
      if (!appVersion) {
        dispatch(setAppVersions());
      }
      // userDetails();
      fetchPolicyStatus();
    } catch (error) {
      console.log(error, "privacy error");
      if (
        error.response &&
        (error.response.status === 429 || error.response.status === 403)
      ) {
        console.log("I am inside privacy", error);
        customToast(
          "",
          true,
          "",
          "Too many requests",
          `${error.response.message}`,
          "Reload the page",
          ""
        );
      }
    }
  }, [policyStatus]);

  useEffect(() => {
    // console.log(data?.isPaid);
    if (data && data?.isPaid === false) {
      if (
        window.location.pathname === "/admin/subscription" ||
        window.location.pathname === "/adminTest/checkout-success"
      ) {
        setOpen(false); // modal opens
        // console.log("heilo", window.location.pathname);
      } else {
        setOpen(true);
      }

      // DATE CALCULATION
      // var d1 = new Date();
      // console.log(d1, "d1");
      // var d2 = new Date(moment(expiryDate, "YYYY-MM-DDTHH:mm:ss"));
      // console.log(d2, "d2");
      // const startDate = new Date(moment(d1, "MMMM-DD-YYYYTHH:mm:ss"));
      // const endDate = new Date(moment(d2, "MMMM-DD-YYYYTHH:mm:ss"));
      // var diff = startDate.getTime() - endDate.getTime();
      // var dayDiff = Math.trunc(diff / (1000 * 60 * 60 * 24));
      // console.log(dayDiff, "dayDiff");
      setRemDay(data?.dayDiff);
      if (
        data.isPaid === false &&
        data.isShow === true
        // data.dayDiff >= -3 &&
        // data.dayDiff <= -1
      ) {
        // when day difference is between 0 t0 3
        setIsClose(true);
      } else if (
        data?.isShow === false &&
        data?.isPaid === false
        // data?.dayDiff < -3
      ) {
        // when day difference is less than -3, means plan expired
        if (
          window.location.pathname === "/admin/subscription" ||
          window.location.pathname === "/adminTest/checkout-success"
        ) {
          setIsClose(true);
        } else {
          setIsClose(false);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    forwardsDateData();
  }, [data?.forwards]);

  const forwardsDateData = async () => {
    const orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
    setLoader(true);
    const response = await forwards(orgToken);
    const { data } = response;
    setForwardsData(data);
    setLoader(false);
    const aggregatedData = {};
    const monthlyData = {};
    const yearlyData = {};
    data.forwards?.forEach((item) => {
      item.data?.map((it) => {
        const date = new Date(it.timestamp);
        // date.setHours(0, 0, 0, 0); // Set time to midnight
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Adjust for time zone offset

        const monthlyFormattedDate = `${year}/${month
          .toString()
          .padStart(2, "0")}`;

        const formattedDate = date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
        });

        if (yearlyData[year]) {
          yearlyData[year]++;
        } else {
          yearlyData[year] = 1;
        }

        if (monthlyData[monthlyFormattedDate]) {
          monthlyData[monthlyFormattedDate]++;
        } else {
          monthlyData[monthlyFormattedDate] = 1;
        }

        if (aggregatedData[formattedDate]) {
          aggregatedData[formattedDate]++;
        } else {
          aggregatedData[formattedDate] = 1;
        }
      });
    });

    const formattedData = Object.keys(aggregatedData).map((date) => ({
      date,
      value: aggregatedData[date],
    }));
    const monthlyFormattedData = Object.keys(monthlyData).map((date) => ({
      date,
      value: monthlyData[date],
    }));
    const yearlyFormattedData = Object.keys(yearlyData).map((date) => ({
      date,
      value: yearlyData[date],
    }));

    setIPFileData(formattedData);
    setMonthlyIPFileData(monthlyFormattedData);
    setYearlyIPFileData(yearlyFormattedData);
  };

  const CustomLabel = ({ value, x, y }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={-8} textAnchor="middle" fill="#666">
        {value}
      </text>
    </g>
  );

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label text-black font-bold">{`${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const togglePopover = (event, data) => {
    setActiveBar(data);
    setPopoverOpen(!popoverOpen);
  };

  const CustomBarShape = (props) => {
    const { x, y, width, height, fill } = props;
    return (
      <g>
        <rect x={x} y={y} width={width} height={height} fill={fill} />
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill="rgba(0, 0, 0, 0.2)"
        />
      </g>
    );
  };

  const closeModal = async () => {
    if (
      window.location.pathname === "/admin/subscription" &&
      window.location.pathname === "/adminTest/checkout-success" &&
      close
    ) {
      setOpen(false);
      console.log("open modal", open);
    }
    if (data.dayDiff >= 0 && data.dayDiff <= 2) {
      setOpen(false);
    }
  };
  const closeModalTerms = async () => {
    if (closeTerms) {
      // console.log("closee");
      setOpenTerms(false);
    }
  };
  const closeModalChangePassword = async () => {
    if (closeChangePassword) {
      // console.log("closee");
      setCloseChangePassword(false);
    }
  };
  const toggleChangeOTP = () => {
    if (modalChangeOTP) {
      setModalChangeOTP(false);
    }
  };

  const closeModalUpdates = () => {
    if (closeUpdate) {
      // console.log("closee");
      setOpenUpdates(false);
    }
  };

  const closeIPAnalytics = () => {
    if (IPAnalytics) {
      setIPAnalytics(false);
    }
  };
  const closeAppVersionPopup = () => {
    if (appVersionPopup) {
      setAppVersionPopup(false);
    }
  };

  const userDateSplit = userDate.split("/");
  const swapDayAndMonth = new Date(
    userDateSplit[2] + "/" + userDateSplit[1] + "/" + userDateSplit[0]
  ).toLocaleString();

  const presentDate = newFormat.split(",")[0];
  const userRegisteredDate = swapDayAndMonth.split(",")[0];

  const apply = async () => {
    const org_token = JSON.parse(localStorage.getItem("user"))?.orgToken;
    const { data } = await applyCoupoun(code, org_token);
    if (data.success) {
      // const newUser = await getUser();
      const newExp = await findOrganizationDetails(org_token);

      if (newExp.success === true) {
        localStorage.setItem(
          "expiryDate",
          JSON.stringify(newExp.msg.expiry_date)
        );
        const userLimit = localStorage.getItem("keys");
        const parsedObject = JSON.parse(userLimit);
        parsedObject.onBoardUserLimit = newExp.msg.onBoardUserLimit;
        const modifiedNdStringifiedForStorage = JSON.stringify(parsedObject);
        localStorage.setItem("keys", modifiedNdStringifiedForStorage);
      }

      customToast(
        true,
        "",
        "",
        `Enterprise coupon activated!`,
        "Your license has been activated, kindly re-login to take the effect.",
        "Go to Subscriptions",
        "/admin/subscription",
        ""
      );
      window.location.reload();
    } else {
      customToast(
        "",
        true,
        "",
        `Enterprise coupon failed!`,
        "Check the entered code and try again",
        "Go to Subscriptions",
        "/admin/subscription",
        ""
      );
    }
  };

  const decline = () => {
    customToast(
      "",
      true,
      "",
      `Policy accept failed`,
      " Regrettably, due to non-compliance with our accepted policies, your session has been terminated.",
      "Go to Subscriptions",
      "/admin/subscription",
      ""
    );
    dispatch({ type: "LOGOUT" });

    localStorage.clear();
  };
  const declineChangePassword = () => {
    customToast(
      "",
      true,
      "",
      `Password updation failed`,
      " Regrettably, due to non-compliance with our accepted policies, your session has been terminated.",
      "Logout",
      "",
      ""
    );
    dispatch({ type: "LOGOUT" });
    localStorage.clear();
  };

  const accept = async () => {
    setOpenTerms(false);
    if (checked) {
      console.log("send mail");
      customToast(
        true,
        "",
        "",
        "Policy accepted",
        "Thank you for accepting the policies of MessageMe.",
        "Welcome",
        "",
        ""
      );
      await sendPrivacyDetails(user.email, checked);
    }
  };

  const updateVersionThings = async () => {
    setOpenUpdates(false);
    const u = JSON.parse(localStorage.getItem("user"));
    await updateRollOutVersion(user.email);

    // updating localStorage
    u.newUpdateRollOut = false;
    const modifiedNdStringifiedForStorage = JSON.stringify(u);
    localStorage.setItem("user", modifiedNdStringifiedForStorage);
  };

  const verifyChangePasswordOTP = async () => {
    var sha = sha256(currPassword);
    try {
      // verifying the entered email
      console.log(data, "dd");
      if (newPassword !== confirmPassword) {
        setNewError("Passwords do not match.");
        setTimeout(() => {
          setNewError("");
        }, 3000);
        setNewPassword("");
        setConfirmPassword("");
      } else if (
        currPassword.length === 0 ||
        newPassword.length === 0 ||
        confirmPassword.length === 0
      ) {
        setNewError("Please fill all the fields");
        setTimeout(() => {
          setNewError("");
        }, 3000);
      } else {
        setLoadChangePassword(true);
        const u = JSON.parse(localStorage.getItem("user"));
        setTimer(Date.now() + 5 * 60 * 1000);
        const { data } = await hashPassword(u.email, sha);
        if (data.success === true) {
          const res = await sendOtpToNewEmail(u.email, userId);
          setLoadChangePassword(false);
          if (res.data.success == true) {
            setModalChangeOTP(true);
            setNewError("");
            customToast(
              true,
              "",
              "",
              "OTP sent",
              "Kindly check your registered mail address and enter the OTP",
              "Check Email",
              ""
            );
          } else {
            setModalChangeOTP(false);
            customToast(
              "",
              true,
              "",
              "Enter correct OTP",
              "Entered OTP is wrong or it has been expired",
              "Go to Dashboard",
              "/admin/index"
            );
          }
        } else {
          customToast(
            "",
            true,
            "",
            "Recheck current password",
            `${data.msg}`,
            "Go to Dashboard",
            "/admin/index"
          );
          setLoadChangePassword(false);
          setCurrPassword("");
          setNewPassword("");
          setConfirmPassword("");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = async () => {
    // var sha = sha256(currPassword);
    try {
      var sha1 = sha256(newPassword);
      // console.log(sha, "lqq");
      const u = JSON.parse(localStorage.getItem("user"));
      const { data } = await verifiedOtp(userId, OTP);
      if (data.success == true) {
        if (
          currPassword.length &&
          newPassword.length &&
          confirmPassword.length
        ) {
          setLoadOtp(true);
          const { data } = await changeCurrentPassword(userId, u.email, sha1);
          setModalChangeOTP(false);
          if (data.success === true) {
            customToast(
              true,
              "",
              "",
              "Password updated, Kindly re-login",
              "You will be logged out of admin panel, kindly re-login to continue",
              "Logout",
              "/auth/login"
            );
            setLoadOtp(false);
            setCurrPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setPassword("");
            const currentValue = localStorage.getItem("showPopup");

            localStorage.setItem("showPopup", currentValue === "false");
          } else {
            customToast(
              "",
              true,
              "",
              "Some error occurred",
              "Try again",
              "Go to Profile",
              "/adminTest/edit-profile"
            );
          }
        } else {
          customToast(
            "",
            true,
            "",
            "Please fill all the fields",
            "Fill all the blanks",
            "Go to Profile",
            "/adminTest/edit-profile"
          );
        }
      } else {
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          `${data.message}`,
          "Go to Dashboard",
          "/admin/index"
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        customToast(
          "",
          true,
          "",
          "Too many wrong attempts",
          "Number of attempts exceeded",
          "Try Again after 10 minutes",
          ""
        );
      }
    }
  };
  const renderer = ({ minutes, seconds }) => {
    // Render a countdown
    // console.log("hii");
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const handlePasswordChange = (e) => {
    const newPass = e.target.value;
    setNewPassword(newPass);

    // Password validation rules
    const hasValidLength = newPass.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPass);
    const hasNumber = /[0-9]/.test(newPass);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(newPass);
    const hasNoDollar = !/\$/.test(newPass); // Excluding $
    const isValid =
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar;
    setIsPasswordValid(isValid);
    setPasswordErrorMessage(
      isValid
        ? ""
        : newPassword.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };

  const handlePasswordBlur = () => {
    // Password validation rules
    const hasValidLength = newPassword.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(
      newPassword
    );
    const hasNoDollar = !/\$/.test(newPassword); // Excluding $
    setIsPasswordValid(
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar
    );
    setPasswordErrorMessage(
      isPasswordValid
        ? ""
        : newPassword.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPass = e.target.value;
    setConfirmPassword(newConfirmPass);

    // Compare with the original password
    const isValid = newConfirmPass === newPassword;

    setIsPasswordMatch(isValid);
    setConfirmPasswordErrorMessage(isValid ? "" : "Passwords do not match");
  };

  const handleConfirmPasswordBlur = () => {
    setIsPasswordMatch(confirmPassword === newPassword);
    if (isPasswordMatch) {
      setConfirmPasswordErrorMessage("");
    } else {
      setConfirmPasswordErrorMessage("Passwords do not match");
    }
  };
  const clearPasswordErrors = () => {
    setIsPasswordValid(true);
  };

  const clearPasswordMatchError = () => {
    setIsPasswordMatch(true);
  };

  const [modalWidth, setModalWidth] = useState(600); // Initial width, adjust as needed
  const [modalHeight, setModalHeight] = useState(300); // Initial height, adjust as needed

  // Function to calculate modal dimensions based on data
  const calculateModalSize = (data) => {
    const numberOfBars = data.length;
    // console.log(numberOfBars, "izz");
    const minWidth = 600; // Minimum width for the modal
    const minHeight = 300; // Minimum height for the modal
    const maxWidth = 1200; // Maximum width for the modal
    const maxHeight = 600; // Maximum height for the modal

    // Calculate width and height based on the number of bars
    const newWidth = Math.min(maxWidth, Math.max(minWidth, numberOfBars * 40)); // Adjust the factor as needed
    const newHeight = Math.min(
      maxHeight,
      Math.max(minHeight, numberOfBars * 20)
    ); // Adjust the factor as needed

    setModalWidth(newWidth);
    setModalHeight(newHeight);
  };

  useEffect(() => {
    // Calculate modal dimensions based on the selected data
    const data =
      selectValue === "Monthly"
        ? MonthlyIPFileData
        : selectValue === "Yearly"
          ? YearlyIPFileData
          : selectValue === "Default"
            ? IPFileData
            : [];

    calculateModalSize(data);
  }, [selectValue, MonthlyIPFileData, YearlyIPFileData, IPFileData]);

  // console.log(open, "open");
  // console.log(
  //   data?.isShow === false &&
  //     data?.isPaid === false &&
  //     window.location.pathname !== "/admin/subscription",
  //   "condition"
  // );
  // console.log(window.location.pathname, "current");
  return loading ? (
    <div
      style={{
        alignItems: "center",
        margin: "auto",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Oval height="100" width="100" ariaLabel="loading" />
    </div>
  ) : (
    <>
      <div className="header bg-meme pb-4 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <br />
            {data?.isShow === false && data?.isPaid === false ? (
              // <Modal
              //   style={{
              //     marginTop: "10%",
              //     width: "35%",
              //     marginLeft: "35%",
              //   }}
              //   open={open}
              //   onClose={() => closeModal()}
              //   aria-labelledby="parent-modal-title"
              //   aria-describedby="parent-modal-description"
              // >
              //   <Cards
              //     style={{
              //       height: "150px",
              //     }}
              //   >
              //     <div
              //       style={{
              //         display: "flex",
              //         alignItems: "center",
              //         flexDirection: "column",
              //       }}
              //     >
              //       <Heading6 color="gray">Plan Over</Heading6>
              //       <Paragraph>Please Contact the Service Provider</Paragraph>
              //     </div>
              //     <div
              //       style={{
              //         display: "flex",
              //         alignItems: "center",
              //         justifyContent: "center",
              //         marginBottom: "18px",
              //         gap: "10px",
              //       }}
              //     >
              //       <TextField
              //         id="filled-basic"
              //         label="Code"
              //         variant="filled"
              //         placeholder="Please enter the Code"
              //         value={code}
              //         onChange={(e) => setCode(e.target.value)}
              //       />
              //       <Button onClick={() => apply()} color="primary">
              //         Apply
              //       </Button>
              //     </div>
              //     <div
              //       style={{
              //         display: "flex",
              //         justifyContent: "center",
              //         alignItems: "center",
              //       }}
              //     >
              //       {/* <div
              // 				style={{
              // 					display: "flex",
              // 					flexDirection: "column",
              // 					alignItems: "center",
              // 				}}
              // 			>
              // 				<h1 style={{ color: "grey", marginBottom: "5px" }}>
              // 					Plan Over
              // 				</h1>

              // 				<Button size='sm'>Subscribe</Button>
              // 			</div> */}
              //       <Image
              //         style={{
              //           marginTop: "5px",
              //           width: "80%",
              //           height: "50% !important",
              //         }}
              //         src={
              //           "https://ubiq.co/analytics-blog/wp-content/uploads/2020/07/create-project-management-dashboard.jpeg"
              //         }
              //         alt="Card Image"
              //       />
              //     </div>
              //   </Cards>
              // </Modal>
              <ReactstrapModal
                isOpen={open}
                onClosed={() => closeModal()}
                // toggle={closeModal}
                centered
                size="lg"
                className="cardShadow shadow-md shadow-intensity-md shadow shadow-offset-down-lg shadow-offset-left-lg"
              >
                <Cards
                  style={{
                    width: "1100px",
                    height: "650px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    borderRadius: "15px",
                    marginLeft: "-100px",
                    backgroundColor: "#F6F7F9",
                  }}
                >
                  <div className="fixed smallGreenCircleTrial d-none d-lg-block "></div>
                  <div className="fixed smallYellowCircleTrial d-none d-lg-block "></div>
                  <div className="fixed smallYellowCircle2Trial d-none d-lg-block "></div>

                  <div className="align-items-start d-flex justify-content-start mb-4">
                    <img
                      src={memeLogoBlue}
                      alt="logo"
                      style={{
                        top: "7px",
                        position: "absolute",
                        left: "2px",
                        paddingLeft: "20px",
                        // paddingBottom: "20px",
                        width: "166.6px",
                        height: "40.45px",
                        // marginBottom: "25px",
                      }}
                    />
                  </div>

                  <H6
                    color="gray"
                    style={{
                      marginTop: "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Thank you for trying MessageMe, Upgrade now to continue
                  </H6>
                  <Paragraph
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Now secure your sensitive business communications with{" "}
                    <span className="font-bold text-black ml-1">
                      {" "}
                      MessageMe™
                    </span>
                  </Paragraph>

                  <div className="d-flex align-items-center justify-content-center">
                    <Card
                      style={{
                        color: "#000000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px",
                        // width: "28rem",
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        marginRight: "30px",
                      }}
                    >
                      <h1
                        style={{
                          color: "#141414",
                          backgroundColor: "#47BE9B",
                          width: "100%",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px",
                        }}
                        className="font-bold"
                      >
                        Basic
                      </h1>
                      <div className="pt-2 px-4">
                        <h1
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <span className="font-bold d-flex">
                            <FaRupeeSign /> 129/{" "}
                          </span>{" "}
                          <h1> User per Month</h1>
                        </h1>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "center",
                            flexDirection: "column",
                            // fontStyle: "normal",
                            // fontWeight: "400",
                            fontSize: "14px",
                            // lineHeight: "27px",
                          }}
                          className="font-light text-blue-gray-700 text-base font-light leading-relaxed mt-0"
                        >
                          <li>Quantum End to End Encryption</li>
                          <li>One to One and group chats</li>
                          <li>Granular control over group</li>
                          <li>Admin Panel controls (limited)</li>
                          <li>Administrator Remote Wiper</li>
                          <li>2FA on Admin panel</li>
                          <li>Data stored on MessageMe server</li>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                          <Button
                            style={{
                              backgroundColor: "#3107CB",
                              color: "#fff",
                              borderRadius: "10px",
                              boxShadow:
                                "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "10px",
                              display: "flex",
                              width: "128px",
                              height: "30px",
                            }}
                            onClick={() =>
                              (window.location.pathname = "/admin/subscription")
                            }
                          >
                            Subscribe Now
                          </Button>
                        </div>
                      </div>
                    </Card>

                    {/* PREMIUM CARD */}
                    <Card
                      style={{
                        color: "#ffffff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px",
                        // width: "28rem",
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        marginRight: "20px",
                        backgroundColor: "#292F59",
                      }}
                    >
                      <h1
                        style={{
                          color: "#ffffff",
                          backgroundColor: "#3107CB",
                          width: "100%",
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px",
                        }}
                        className="font-bold"
                      >
                        Premium
                      </h1>

                      <div className="pt-2 px-4">
                        <h1
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <span className="font-bold d-flex">
                            <FaRupeeSign /> 169/{" "}
                          </span>{" "}
                          <h1> User per Month</h1>
                        </h1>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "center",
                            flexDirection: "column",
                            // fontStyle: "normal",
                            // fontWeight: "400",
                            fontSize: "14px",
                            // lineHeight: "27px",
                          }}
                          className="font-light text-white-700 text-base font-light leading-relaxed mt-0"
                        >
                          <li>Everything from Basic plan</li>
                          <li>Consent based file sharing</li>
                          <li>Critical Timer Messaging</li>
                          <li>Forwards Tracker </li>
                          <li>Hyper Secure Vault</li>
                          <li>Admin - File Size Limiter</li>
                          <li>File sharing Audit report generation</li>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                          <Button
                            style={{
                              backgroundColor: "#3107CB",
                              color: "#fff",
                              borderRadius: "10px",
                              boxShadow:
                                "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "10px",
                              width: "128px",
                              height: "28px",
                            }}
                            onClick={() =>
                              (window.location.pathname = "/admin/subscription")
                            }
                          >
                            Subscribe Now
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </div>

                  <div className="d-flex align-items-center justify-content-center font-bold text-black my-2">
                    <Heading6>OR</Heading6>
                  </div>

                  <div className="d-flex align-items-center justify-content-center text-black">
                    <Heading5 style={{ fontSize: "20px" }}>
                      If you have coupon code, enter here{" "}
                    </Heading5>
                    <TextField
                      // id="filled-basic"
                      label="Code"
                      variant="filled"
                      placeholder="Please enter the Code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      className="mx-2"
                      style={{ width: "20%" }}
                    />
                    <Button
                      onClick={() => apply()}
                      style={{
                        backgroundColor: "#1307cb",
                        borderRadius: "8px",
                      }}
                      disabled={!code}
                    >
                      Apply
                    </Button>
                  </div>
                </Cards>
              </ReactstrapModal>
            ) : (
              <Modal
                style={{
                  marginTop: "10%",
                  width: "35%",
                  marginLeft: "35%",
                }}
                open={open}
                onClose={() => closeModal()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Cards
                  style={{
                    height: "150px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Heading6 color="gray">Plan Is About To Over</Heading6>
                    <Paragraph>Please Contact the Service Provider</Paragraph>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <h7 color="gray">Remaining Days: {remDay}</h7>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "18px",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      id="filled-basic"
                      label="Code"
                      variant="filled"
                      placeholder="Please enter the Code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <Button
                      onClick={() => apply()}
                      disabled={!code}
                      color="primary"
                      className={`${!code}` ? "cursor-not-allowed" : ""}
                    >
                      Apply
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
										>
											<h1 style={{ color: "grey", marginBottom: "5px" }}>
												Plan Over
											</h1>

											<Button size='sm'>Subscribe</Button>
										</div> */}
                    <Image
                      style={{
                        marginTop: "5px",
                        width: "80%",
                        height: "50% !important",
                      }}
                      src={
                        "https://ubiq.co/analytics-blog/wp-content/uploads/2020/07/create-project-management-dashboard.jpeg"
                      }
                      alt="Card Image"
                    />
                  </div>
                </Cards>
              </Modal>
            )}

            <Modal
              style={{
                width: "25%",
                marginLeft: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              open={modalChangeOTP}
              onClose={() => toggleChangeOTP()}
            >
              <Cards>
                <div className="d-flex flex-col">
                  <h1 className="text-black mb-1">
                    Enter the OTP sent on your mail.
                  </h1>

                  <FormGroup
                    className="form-group"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="floating-label">
                      <input
                        id="outlined-size-normal"
                        className="form-control"
                        type="number"
                        style={{
                          backgroundColor: "#EDF2F7",
                          border: "none",
                        }}
                        name="otp"
                        required
                        onChange={(e) => setOTP(e.target.value)}
                        value={OTP}
                      />
                      <label
                        for="otp"
                        style={{
                          color: "#455768",
                          backgroundColor: "#EDF2F7",
                        }}
                      >
                        OTP
                      </label>
                    </div>
                  </FormGroup>
                  <div className="d-flex">
                    <Button
                      size="sm"
                      style={{
                        backgroundColor: "#3107cb",
                        borderColor: "#3107cb",
                        outline: "none !important",
                        outlineOffset: "none !important",
                        boxShadow: "none",
                        cursor: loadOtp ? "not-allowed" : "",
                      }}
                      onClick={changePassword}
                      disabled={loadOtp}
                    >
                      Submit
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        backgroundColor: "#A6AEAB",
                        borderColor: "#A6AEAB",
                        outline: "none !important",
                        outlineOffset: "none !important",
                        boxShadow: "none",
                      }}
                      onClick={() => toggleChangeOTP()}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="d-flex align-items-center justify-content-end text-black">
                    <p className="">Time Remaining: </p>{" "}
                    <Countdown
                      date={timer}
                      renderer={renderer}
                      zeroPadTime={2}
                      autoStart={true}
                      key={timer}
                    />
                  </div>
                </div>
              </Cards>
            </Modal>

            {showChangePasswordPopup === true && (
              <Modal
                style={{
                  // marginTop: "1.75%",
                  width: "35%",
                  marginLeft: "35%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={openChangePassword}
                onClose={() => closeModalChangePassword()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Cards
                  style={
                    {
                      // height: "150px",
                    }
                  }
                >
                  <div className="d-flex flex-col ">
                    <div className="d-flex  align-items-center justify-content-between">
                      <div className="d-flex flex-col">
                        <p style={{ color: "#9F9F9F" }}>Password Expired</p>
                        <Heading6 color="gray">Kindly Reset</Heading6>
                      </div>

                      <div className="d-flex">
                        <img src={Privacy} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <Image
                      style={{
                        marginTop: "5px",
                        width: "50%",
                        height: "20% !important",
                      }}
                      src={termsImg}
                      alt="Card Image"
                    />
                  </div>
                  <Row>
                    <div className="col">
                      <FormGroup
                        className="form-group"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="floating-label">
                          <input
                            id="confpassword"
                            className="form-control"
                            type="password"
                            style={{
                              backgroundColor: "#EDF2F7",
                              border: "none",
                            }}
                            name="confpassword"
                            required
                            onChange={(e) => setCurrPassword(e.target.value)}
                            value={currPassword}
                          />
                          <label
                            for="password"
                            style={{
                              color: "#455768",
                              backgroundColor: "#EDF2F7",
                            }}
                          >
                            Current Password
                          </label>
                        </div>
                      </FormGroup>

                      <ValidatedInput
                        label="Password"
                        onChange={handlePasswordChange}
                        onBlur={handlePasswordBlur}
                        onFocus={clearPasswordErrors}
                        value={newPassword}
                        isValid={isPasswordValid}
                        placeholder="Enter New Password"
                        errorMessage={passwordErrorMessage}
                        type="password"
                      />

                      <ValidatedInput
                        label="Confirm Password"
                        onChange={handleConfirmPasswordChange}
                        onBlur={handleConfirmPasswordBlur}
                        onFocus={clearPasswordMatchError}
                        value={confirmPassword}
                        isValid={isPasswordMatch}
                        placeholder="Confirm New Password"
                        errorMessage={confirmPasswordErrorMessage}
                        type="password"
                      />

                      <Button
                        type="submit"
                        // className="bg-meme"
                        onClick={verifyChangePasswordOTP}
                        size="sm"
                        style={{
                          backgroundColor: "#3107cb",
                          borderColor: "#3107cb",
                          outline: "none !important",
                          outlineOffset: "none !important",
                          boxShadow: "none",
                          cursor:
                            !currPassword ||
                              !newPassword ||
                              !confirmPassword ||
                              loadChangePassword ||
                              passwordErrorMessage.length > 0 ||
                              confirmPasswordErrorMessage.length > 0
                              ? "not-allowed"
                              : "",
                        }}
                        disabled={
                          !currPassword ||
                          !newPassword ||
                          !confirmPassword ||
                          loadChangePassword ||
                          passwordErrorMessage.length > 0 ||
                          confirmPasswordErrorMessage.length > 0
                        }
                      >
                        Submit
                      </Button>
                      <Button
                        type="submit"
                        // className="bg-meme"
                        onClick={declineChangePassword}
                        size="sm"
                        style={{
                          backgroundColor: "#A6AEAB",
                          borderColor: "#A6AEAB",
                          outline: "none !important",
                          outlineOffset: "none !important",
                          boxShadow: "none",
                          cursor:
                            !currPassword ||
                              !newPassword ||
                              !confirmPassword ||
                              loadChangePassword ||
                              passwordErrorMessage.length > 0 ||
                              confirmPasswordErrorMessage.length > 0
                              ? "not-allowed"
                              : "",
                        }}
                        disabled={
                          !currPassword ||
                          !newPassword ||
                          !confirmPassword ||
                          loadChangePassword ||
                          passwordErrorMessage.length > 0 ||
                          confirmPasswordErrorMessage.length > 0
                        }
                      >
                        Decline
                      </Button>
                    </div>
                  </Row>
                </Cards>
              </Modal>
            )}

            {/* INITIAL MODEL  -- only once should be sent*/}
            {
              // presentDate === userRegisteredDate &&

              policyStatus === false && (
                <Modal
                  style={{
                    marginTop: "1.25%",
                    width: "40%",
                    marginLeft: "30%",
                  }}
                  open={openTerms}
                  onClose={() => closeModalTerms()}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Cards
                    style={
                      {
                        // height: "150px",
                      }
                    }
                  >
                    <div className="d-flex flex-col ">
                      <div className="d-flex  align-items-center justify-content-between">
                        <div className="d-flex flex-col">
                          <p style={{ color: "#9F9F9F" }}>AGREEMENT</p>
                          <Heading6 color="gray">Terms of Service</Heading6>
                        </div>

                        <div className="d-flex">
                          <img src={Privacy} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Image
                        style={{
                          marginTop: "5px",
                          width: "50%",
                          height: "20% !important",
                        }}
                        src={termsImg}
                        alt="Card Image"
                      />
                    </div>

                    {/* SCROLLABLE to be added */}
                    <Scrollbars style={{ height: 220 }}>
                      <div
                        className=" text-black"
                        style={{
                          overflowY: "scroll",
                          maxHeight: "220px",
                          // scrollbarWidth: "2px",
                        }}
                      >
                        <ul>
                          <li className="font-bold">1. Terms</li>
                          <p className="pl-4 pb-2" style={{ fontSize: "12px" }}>
                            Welcome to our website. If you continue to browse
                            and use this website you are agreeing to comply with
                            and be bound by the following terms and conditions
                            of use, which together with our privacy policy
                            govern ARISHTI CYBERTECH PRIVATE LIMITED''s
                            relationship with you in relation to this website.
                          </p>
                          <li className="font-bold">
                            2. The use of this website:
                          </li>
                          <p style={{ fontSize: "12px" }} className="pl-4">
                            The content of the pages of this website is for your
                            general information and use only. It is subject to
                            change without notice.
                          </p>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            Neither we nor any third parties provide any
                            warranty or guarantee as to the accuracy,
                            timeliness, performance, completeness or suitability
                            of the information and materials found or offered on
                            this website for any particular purpose. You
                            acknowledge that such information and materials may
                            contain inaccuracies or errors and we expressly
                            exclude liability for any such inaccuracies or
                            errors to the fullest extent permitted by law.
                          </p>
                          <li className="font-bold">3. Privacy Policies</li>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            Security & Privacy is in our DNA and hence data
                            privacy is our highest priority. This security
                            overview provides a high-level overview of the
                            security practices put in place to achieve that
                            objective. Feel free to reach out to us at
                            technology@arishti.in We track the location of the
                            user -- based on the permission granted by her/her
                            will. We will never provide any part of your
                            information to anyone unless consented/bound legally
                            in case of any data breaches.
                          </p>
                          <li className="font-bold">4. Platform Security</li>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            MessageMe’s microservices is hosted and deployed on
                            Azure/AWS Cloud Services. We follow MACH
                            architecture using the most secure services of
                            Azure/AWS. MessageMe strictly follows the Zero Trust
                            Framework, making the platform most secure,
                            high-performing, scalable, and efficient.
                            Distributed Denial-of-Service (DDoS) attacks are
                            prevented using the multi-level defence firewalls
                            and Database hardening at server level.
                          </p>
                          <li className="font-bold">5. Information Security</li>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            We are currently using a Patent-pending Quantum
                            Security architecture, which makes the platform
                            highly secure in itself. All users' data is
                            completely always encrypted. We provide both
                            in-transit and at-rest encryption. The
                            military-grade TLS based encryption algorithm, which
                            helps in protecting users' data from
                            Man-in-the-middle and eavesdropping attacks, are
                            being used to provide the utmost security to the
                            platform. We provide End-to-End enterprise grade AES
                            256 encryption. Our platform has an intelligent
                            logging system which logs and audits the intrusion
                            into the system from any unknown sources. We utilize
                            data replication for data resiliency and disaster
                            recovery as well as backup testing for data
                            reliability. On the top of it, the platform has been
                            configured with rolebased access control to secure
                            the sensitive organizational data.
                          </p>
                          <li className="font-bold">
                            6. Consent Over the Data
                          </li>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            At Arishti, we believe that the Consent is the
                            future of Security and hence the complete platform
                            is being built in consideration of the same. We do
                            not permit the other users to use/share your data
                            without your consent or at some places warning you
                            with the system intimations.
                          </p>
                          <li className="font-bold">
                            7. ISO 27001::2013 Certified
                          </li>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            Our platform processes and the management has been
                            verified and certified through ISO 27001::2013
                            standard. This itself proves our trust and the
                            priority towards our data security and privacy
                            management for the enterprises.
                          </p>
                          <li className="font-bold">8. Legal Disclosure</li>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            We are committed to keeping our customer’s data
                            secure and private. Keeping up with our users'
                            trust, we have implemented the highest grade of
                            security standards. Despite our best efforts, if you
                            are a technology expert or a security researcher and
                            come across any potential security vulnerability
                            problems, we encourage you to report the same
                            responsibly by writing to us at
                            technology@arishti.in along with the encountered
                            issue screenshot and detailed process required to
                            regenerate the vulnerability at our end. We assure
                            you to fix the issue after carefully evaluating the
                            said vulnerability within the minimum time frame.
                            Kindly note that though we appreciate your
                            experience and expertise, if the vulnerability has
                            been used for unlawful gains, we might take legal
                            actions against you. Your report shall be considered
                            confidential. Your private data is not usually
                            shared with our third-party partners at any time,
                            unless it is a legal obligation.
                          </p>
                          <li className="font-bold text-underline pb-2">
                            Terms and Condition
                          </li>
                          <p style={{ fontSize: "12px" }} className="pl-4 pb-2">
                            The Website Owner, including subsidiaries and
                            affiliates (“Website” or “Website Owner” or “we” or
                            “us” or “our”) provides the information contained on
                            the website or any of the pages comprising the
                            website (“website”) to visitors (“visitors”)
                            (cumulatively referred to as “you” or “your”
                            hereinafter) subject to the terms and conditions set
                            out in these website terms and conditions, the
                            privacy policy and any other relevant terms and
                            conditions, policies and notices which may be
                            applicable to a specific section or module of the
                            website. Welcome to our website. If you continue to
                            browse and use this website you are agreeing to
                            comply with and be bound by the following terms and
                            conditions of use, which together with our privacy
                            policy govern ARISHTI CYBERTECH PRIVATE LIMITED''s
                            relationship with you in relation to this website.
                            The term 'ARISHTI CYBERTECH PRIVATE LIMITED' or 'us'
                            or 'we' refers to the owner of the website whose
                            registered/operational office is Building No. 7,
                            Gopinath Nagar, Kothrud, Pune Pune MAHARASHTRA
                            411038. The term 'you' refers to the user or viewer
                            of our website.{" "}
                          </p>
                          <p
                            style={{ fontSize: "13px", color: "#3107cb" }}
                            className="pl-4 pb-2"
                          >
                            The use of this website and its subdomains is
                            subject to the following terms of use:
                          </p>

                          <ul
                            style={{ listStyleType: "disc", fontSize: "12px" }}
                            className="pl-5 pb-2"
                          >
                            <li style={{ fontSize: "12px" }} className="pb-2">
                              The content of the pages of this website is for
                              your general information and use only. It is
                              subject to change without notice
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              Neither we nor any third parties provide any
                              warranty or guarantee as to the accuracy,
                              timeliness, performance, completeness or
                              suitability of the information and materials found
                              or offered on this website for any particular
                              purpose. You acknowledge that such information and
                              materials may contain inaccuracies or errors and
                              we expressly exclude liability for any such
                              inaccuracies or errors to the fullest extent
                              permitted by law.
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              Your use of any information or materials on this
                              website is entirely at your own risk, for which we
                              shall not be liable. It shall be your own
                              responsibility to ensure that any products,
                              services or information available through this
                              website meet your specific requirements.
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              This website contains material which is owned by
                              or licensed to us. This material includes, but is
                              not limited to, the design, layout, look,
                              appearance and graphics. Reproduction is
                              prohibited other than in accordance with the
                              copyright notice, which forms part of these terms
                              and conditions.
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              All trademarks reproduced in this website which
                              are not the property of, or licensed to, the
                              operator are acknowledged on the website.
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              Unauthorized use of this website may give rise to
                              a claim for damages and/or be a criminal offense.
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              From time to time this website may also include
                              links to other websites. These links are provided
                              for your convenience to provide further
                              information.
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              You may not create a link to this website from
                              another website or document without ARISHTI
                              CYBERTECH PRIVATE LIMITED’s prior written consent.
                            </li>
                            <li style={{ fontSize: "12px" }} className=" pb-2">
                              Your use of this website and any dispute arising
                              out of such use of the website is subject to the
                              laws of India or other regulatory authority.
                            </li>
                          </ul>

                          <p
                            className="text-black mb-1"
                            style={{ fontSize: "13px", fontWeight: "400" }}
                          >
                            No cancellations & Refunds are entertained once the
                            subscription/purchase is made.
                          </p>
                          <p
                            className="text-black mb-1"
                            style={{ fontSize: "13px", fontWeight: "400" }}
                          >
                            Shipping is not applicable for business.
                          </p>
                          <p
                            className="text-black mb-1"
                            style={{ fontSize: "13px", fontWeight: "400" }}
                          >
                            Contact Us:
                            <ul
                              className="pl-5 pb-2"
                              style={{ listStyleType: "disc" }}
                            >
                              <li>
                                <span className="font-bold">
                                  Operational Address:{" "}
                                </span>
                                <span>Kothrud, Pune, Maharashtra 411038</span>
                              </li>
                              <li>
                                <span className="font-bold">
                                  Email Address:{" "}
                                </span>
                                <a
                                  href="mailto: contact@arishti.in"
                                  target="_blank"
                                >
                                  contact@arishti.in
                                </a>
                              </li>
                              <li>
                                <span className="font-bold">
                                  Contact Number:{" "}
                                </span>
                                <span>+91-9403311409</span>
                              </li>
                            </ul>
                          </p>
                        </ul>
                      </div>
                    </Scrollbars>

                    <p
                      className="text-black mb-1 mt-2"
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        backgroundColor: "#e5e5e5",
                        borderRadius: "0.75rem",
                        padding: "10px",
                      }}
                    >
                      We as a merchant shall be under no liability whatsoever in
                      respect of any loss or damage arising directly or
                      indirectly out of the decline of authorization for any
                      Transaction, on Account of the Cardholder having exceeded
                      the preset limit mutually agreed by us with our acquiring
                      bank from time to time.
                    </p>

                    <div className="d-flex align-items-center text-black mt-2">
                      <div className="d-flex align-items-center w-50">
                        <Checkbox
                          className="mr-1"
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          color="primary"
                        />
                        <p style={{ fontSize: "13px" }}>
                          Agree to terms, email a copy.
                        </p>
                      </div>
                      <div className="d-flex pl-4 w-50">
                        <Button
                          style={{
                            background: "rgba(217, 217, 217, 0.48)",
                            borderRadius: "8px",
                            borderColor: "rgba(217, 217, 217, 0.48)",
                            color: "#6A6969",
                            width: "102px",
                            height: "40px",
                          }}
                          onClick={decline}
                        >
                          Decline
                        </Button>
                        <Button
                          style={{
                            background: "#3107cb",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            borderColor: "#3107cb",
                            width: "102px",
                            height: "40px",
                          }}
                          onClick={accept}
                          disabled={!checked}
                        >
                          Accept
                        </Button>
                      </div>
                    </div>
                  </Cards>
                </Modal>
              )
            }
            {user?.newUpdateRollOut !== true && (
              <Modal
                style={{
                  marginTop: "1.5em",
                  width: "40%",
                  marginLeft: "30%",
                }}
                open={openUpdates}
                onClose={() => closeModalUpdates()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Cards>
                  <div className="d-flex flex-col ">
                    <div className="d-flex  align-items-center justify-content-between">
                      <div className="d-flex flex-col">
                        <p style={{ color: "#9F9F9F" }}>New Update Available</p>
                        <Heading6 color="gray">What's New</Heading6>
                      </div>

                      <div className="d-flex">
                        <img src={Privacy} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <Image
                      style={{
                        width: "50%",
                        height: "20% !important",
                      }}
                      src={newVersion}
                      alt="Card Image"
                    />
                  </div>

                  <div
                    className=" text-black"
                    style={{
                      overflowY: "scroll",
                      maxHeight: "250px",
                      // scrollbarWidth: "2px",
                    }}
                  >
                    <ul>
                      <li className="font-bold">1. IP File Traceability</li>
                      <p className="pl-4 pb-2" style={{ fontSize: "13px" }}>
                        Administrators can now trace the IP Files shared across all the users within enterprise.
                        A directed path would help build a complete traceability for organizations.
                      </p>
                      <li className="font-bold">2. File type / Size policy enforcement</li>
                      <p style={{ fontSize: "13px" }} className="pl-4">
                        Enforce stringent policies for your enterprise users to control the type of data being shared over MessageMe application.
                        You can also apply the restrictions on size of data to be shared.
                      </p>

                      <li className="font-bold">3. Group Access Control</li>
                      <p style={{ fontSize: "13px" }} className="pl-4 pb-2">
                        Monitor what all groups have been created within the enterprise and their active members. Now you can lock/unlock the access to those groups from dashboard.
                      </p>
                    </ul>
                  </div>

                  <div className="d-flex align-items-center text-black mt-1">
                    <Checkbox
                      className="mr-1"
                      checked={selectVersion}
                      onChange={handleChangeVersion}
                      inputProps={{ "aria-label": "controlled" }}
                      color="primary"
                    />
                    <p style={{ fontSize: "13px", marginTop: "10px" }} className="pl-4">
                      I've read and agree to the Terms & Conditions & all the
                      features available on the platform
                    </p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      style={{
                        background: "#3107cb",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderColor: "#3107cb",
                        width: "102px",
                        height: "40px",
                      }}
                      disabled={!selectVersion}
                      onClick={updateVersionThings}
                    >
                      Proceed
                    </Button>
                  </div>
                </Cards>
              </Modal>
            )}

            {IPAnalytics === true && (
              <Modal
                open={IPAnalytics}
                onClose={() => closeIPAnalytics()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                ref={ref}
                // className="ipAnalytics"
                style={{
                  marginTop: "4.5em",
                  width: modalWidth,
                  height: modalHeight,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Cards>
                  <div className="d-flex flex-col">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-col">
                        <p style={{ color: "#9F9F9F", fontSize: "14px" }}>
                          File Forwards
                        </p>
                        <Heading6 style={{ color: "#3107cb" }}>
                          IP FIle Statistics
                        </Heading6>
                      </div>

                      <div className="d-flex">
                        {/* Dropdown menu will come */}
                        <FormControl
                          variant="filled"
                          size="medium"
                          fullWidth={true}
                        >
                          <Select
                            value={selectValue}
                            disableUnderline
                            style={{ paddingBottom: "12px" }}
                            // displayEmpty
                            onChange={(e) => setSelectValue(e.target.value)}
                          >
                            <MenuItem value="Default">Default</MenuItem>
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Yearly">Yearly</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <p style={{ color: "#9F9F9F", fontSize: "14px" }}>
                      Total Files shared in a week:{" "}
                      <span className="font-bold">{data?.forwards}</span>
                    </p>
                  </div>

                  <div className="d-flex justify-content-end">
                    <p
                      style={{
                        color: "gray",
                        cursor: "pointer",
                      }}
                      className="d-flex align-items-center"
                      onClick={getImage}
                    >
                      Download
                      <span className="pl-1">
                        <MdSimCardDownload
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        />{" "}
                      </span>
                    </p>
                  </div>

                  {/* GRAPH */}
                  {loader === true ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#47be9b"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <BarChart
                      width={500}
                      height={300}
                      data={
                        selectValue === "Monthly"
                          ? MonthlyIPFileData
                          : selectValue === "Yearly"
                            ? YearlyIPFileData
                            : selectValue === "Default" && IPFileData
                      }
                      margin={{ top: 15, right: 5, left: 20, bottom: 20 }}
                    >
                      <XAxis
                        dataKey="date"
                        stroke="#141414"
                        label={{
                          position: "insideBottom",
                          // angle: -90,
                          // dy: "-1.465em",
                          value: "Days in Selected Week",
                          textAnchor: "middle",
                          offset: -15,
                          style: { fontSize: "14px" },
                        }}
                        tick={{ fontSize: "12px" }}
                      />

                      <YAxis
                        stroke="#141414"
                        label={{
                          position: "insideBottomLeft",
                          angle: -90,
                          // dy: "-1.465em",
                          value: "Number of IP File Forwards",
                          textAnchor: "middle",
                          style: { fontSize: "14px" },
                        }}
                        tick={{ fontSize: "12px" }}
                      />
                      <Tooltip content={customTooltip} />
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}

                      {/* IF YOU WANT FIXED VALUE ON TOP OF BARS */}
                      <Bar
                        type="monotone"
                        dataKey="value"
                        fill="#47be9b"
                        yAxisId={0}
                        barSize={20}
                        // label={<CustomLabel />}
                        onMouseEnter={togglePopover}
                        onMouseLeave={togglePopover}
                        shape={<CustomBarShape />}
                        animationDuration={1000}
                      >
                        {/* <LabelList dataKey="value" position="top" /> */}
                      </Bar>

                      {/* IF YOU WANT ON HOVER -- works fine but value is coming a bit down*/}
                      {/* <Bar
                      type="monotone"
                      dataKey="value"
                      fill="#47be9b"
                      yAxisId={0}
                      barSize={20}
                    /> */}
                    </BarChart>
                  )}

                  <div className="d-flex align-items-center mt-2">
                    <p style={{ color: "gray", fontSize: "14px" }}>
                      The above comparison graphs are based on considering just
                      the sensitive IP Files being shared within the enterprise.
                    </p>
                  </div>
                </Cards>
              </Modal>
            )}

            {appVersionPopup === true && (
              <Modal
                style={{
                  // marginTop: "6.5em",
                  width: "40%",
                  marginLeft: "30%",
                  // height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={appVersionPopup}
                onClose={() => closeAppVersionPopup()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <div
                  className=" bg-white shadow-lg"
                  style={{ borderRadius: "1.3rem" }}
                >
                  <img
                    src={subs}
                    style={{
                      objectFit: "none",
                      width: "100%",
                      height: "auto",
                      borderTopLeftRadius: "1.3rem",
                      borderTopRightRadius: "1.3rem",
                    }}
                  />
                  <img
                    src={memeLogo}
                    style={{
                      width: "120px",
                      height: "35px",
                      color: "white",
                      marginTop: "-85px",
                      marginLeft: "25px",
                      // position: "absolute",
                    }}
                    className="resMemeLogo"
                  />
                  <h1
                    style={{
                      marginLeft: "25px",
                      color: "white",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                    className="font-bold resText"
                  >
                    Download the Mobile App now
                  </h1>
                  <div className="d-flex align-items-center justify-content-around pt-5">
                    <div className="d-flex flex-col align-items-center justify-content-center">
                      <Button
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#000000",
                          color: "#ffffff",
                          width: "10rem",
                        }}
                        href={process.env.REACT_APP_PLAYSTORE_LINK}
                        target="_blank"
                      >
                        <div className="d-flex justify-content-around align-items-center">
                          <IoLogoGooglePlaystore size="2em" />
                          <div className="d-flex flex-column align-items-start">
                            <p
                              style={{
                                backgroundColor: "#000000",
                                color: "#ffffff",
                                fontSize: "10px",
                              }}
                            >
                              GET IT ON
                            </p>{" "}
                            <span className="font-bold"> Google Play</span>
                          </div>
                        </div>
                      </Button>

                      <div className="d-flex align-items-center justify-content-center pt-4 pb-4">
                        <QRCode
                          value={process.env.REACT_APP_PLAYSTORE_LINK}
                          size={128}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-col align-items-center justify-content-center">
                      <Button
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#000000",
                          color: "#ffffff",
                          width: "10rem",
                        }}
                        href={process.env.REACT_APP_APPLESTORE_LINK}
                        target="_blank"
                      >
                        <div className="d-flex  justify-content-around align-items-center">
                          <GrAppleAppStore size="2em" />
                          <div className="d-flex flex-column align-items-start">
                            <p
                              style={{
                                backgroundColor: "#000000",
                                color: "#ffffff",
                                fontSize: "10px",
                              }}
                            >
                              Available on the
                            </p>{" "}
                            <span className="font-bold">App Store</span>
                          </div>
                        </div>
                      </Button>
                      <div className="d-flex align-items-center justify-content-center pt-4 pb-4">
                        <QRCode
                          value={process.env.REACT_APP_APPLESTORE_LINK}
                          size={128}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}

            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div
                        className="col"
                        onClick={() => setAppVersionPopup(!appVersionPopup)}
                        style={{ cursor: "pointer" }}
                      >
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          APPLICATION VERSION
                        </CardTitle>
                        <div className="d-flex justify-content-between ">
                          <span className="h2 font-weight-bold mb-0">
                            <div className="d-flex align-items-center">
                              <span style={{ color: "#707070" }}>
                                <AiFillAndroid />{" "}
                              </span>
                              {appVersion?.message?.map(
                                (it) => it.playStoreVersion
                              )}
                            </div>
                          </span>
                          <span className="h2 font-weight-bold mb-0">
                            <div className="d-flex align-items-center">
                              <span style={{ color: "#707070" }}>
                                <GrAppleAppStore />
                              </span>{" "}
                              {appVersion?.message?.map(
                                (it) => it.appleStoreVersion
                              )}
                            </div>
                          </span>
                        </div>
                        <span
                          className="d-flex flex-col "
                          style={{
                            color: "#A0A3AA",
                            fontSize: "15px",
                            // marginTop: "2px",
                          }}
                        >
                          Version auto-updates from source.
                        </span>
                        {/* COMMENTED DATE */}
                        {/* <div
													className='d-flex justify-content-end'
													style={{
														marginRight: "-90px",
														marginBottom: "-22px",
														fontSize: "15px",
													}}
												>
													{message?.map((it) =>
														it.lastUpdateDate
															.split("T")[0]
															.split("-")
															.reverse()
															.join("/")
													)}
												</div> */}
                      </div>
                      {/* ICON */}
                      {/* <Col className='col-auto'>
												<div className='icon icon-shape bg-danger text-white rounded-circle shadow mb-0'>
													<span className='fas'>
														<img
															alt='...'
															src={
																require("assets/img/icons/HeaderCard/message.png")
																	.default
															}
														/>
													</span>
												</div>
											</Col> */}
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <div className="d-flex align-items-center">
                            ON BOARD USERS
                            {/* {userLimit === 14 && (
                              <button
                                type='button'
                                style={{
                                  backgroundColor: "#FF4500",
                                  color: "white",
                                  marginLeft: "10px",
                                  fontSize: "12px",
                                  padding: "0px 5px",
                                  borderRadius: "5px",
                                  opacity: "0.8",
                                }}
                              >
                                Trial Version
                              </button>
                            )} */}
                          </div>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {data?.users}
                        </span>
                        <span
                          className="d-flex flex-col"
                          style={{ color: "#A0A3AA" }}
                        >
                          Remaining user licenses: {userLimit - data?.users}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <span className="fas">
                            <img
                              alt="..."
                              src={
                                require("assets/img/icons/HeaderCard/users.png")
                                  .default
                              }
                            />
                          </span>
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>
                        <span className="text-nowrap">Since last week</span>
                      </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div
                        className="col"
                        onClick={() => setIPAnalytics(!IPAnalytics)}
                        style={{ cursor: "pointer" }}
                      >
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          IP FORWARDS
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {data?.forwards}
                        </span>
                        <span
                          className="d-flex flex-col"
                          style={{ color: "#A0A3AA", fontSize: "15px" }}
                        >
                          Only IP File Forwards tracked
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <span className="fas">
                            <img
                              alt="..."
                              src={
                                require("assets/img/icons/HeaderCard/onboard.png")
                                  .default
                              }
                            />
                          </span>
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 1.10%
                        </span>
                        <span className="text-nowrap">Since yesterday</span>
                      </p> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
