// /*!

// =========================================================
// * Argon Dashboard React - v1.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
// import React from "react";
// // react plugin used to create google maps
// import {
//     withScriptjs,
//     withGoogleMap,
//     GoogleMap,
//     Marker
// } from "react-google-maps";

// // reactstrap components
// import {Card, Container, Row} from "reactstrap";

// // core components
// import Header from "components/Headers/Header.js";
// // mapTypeId={google.maps.MapTypeId.ROADMAP}
// const MapWrapper = withScriptjs(
//     withGoogleMap(props => (
//         <GoogleMap
//             defaultZoom={12}
//             defaultCenter={{lat: 40.748817, lng: -73.985428}}
//             defaultOptions={{
//                 scrollwheel: false,
//                 styles: [
//                     {
//                         featureType: "administrative",
//                         elementType: "labels.text.fill",
//                         stylers: [{color: "#444444"}]
//                     },
//                     {
//                         featureType: "landscape",
//                         elementType: "all",
//                         stylers: [{color: "#f2f2f2"}]
//                     },
//                     {
//                         featureType: "poi",
//                         elementType: "all",
//                         stylers: [{visibility: "off"}]
//                     },
//                     {
//                         featureType: "road",
//                         elementType: "all",
//                         stylers: [{saturation: -100}, {lightness: 45}]
//                     },
//                     {
//                         featureType: "road.highway",
//                         elementType: "all",
//                         stylers: [{visibility: "simplified"}]
//                     },
//                     {
//                         featureType: "road.arterial",
//                         elementType: "labels.icon",
//                         stylers: [{visibility: "off"}]
//                     },
//                     {
//                         featureType: "transit",
//                         elementType: "all",
//                         stylers: [{visibility: "off"}]
//                     },
//                     {
//                         featureType: "water",
//                         elementType: "all",
//                         stylers: [{color: "#5e72e4"}, {visibility: "on"}]
//                     }
//                 ]
//             }}
//         >
//             <Marker position={{lat: 40.748817, lng: -73.985428}}/>
//         </GoogleMap>
//     ))
// );

// class Maps extends React.Component {
//     render() {
//         return (
//             <>
//                 <Header/>
//                 {/* Page content */}
//                 <Container className="mt--7" fluid>
//                     <Row>
//                         <div className="col">
//                             <Card className="shadow border-0">
//                                 <MapWrapper
//                                     googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
//                                     loadingElement={<div style={{height: `100%`}}/>}
//                                     containerElement={
//                                         <div
//                                             style={{height: `600px`}}
//                                             className="map-canvas"
//                                             id="map-canvas"
//                                         />
//                                     }
//                                     mapElement={
//                                         <div style={{height: `100%`, borderRadius: "inherit"}}/>
//                                     }
//                                 />
//                             </Card>
//                         </div>
//                     </Row>
//                 </Container>
//             </>
//         );
//     }
// }

// export default Maps;
