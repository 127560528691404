import React from "react";
import "../../assets/css/login.css";
import { Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { Tooltip } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaRupeeSign,
} from "react-icons/fa";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { RiRefundFill } from "react-icons/ri";
import { MdPayments } from "react-icons/md";

const FAQ = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div
      className="fixed faq"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ zIndex: "9" }}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="none"
        >
          <circle cx="27" cy="27" r="27" fill="url(#paint0_linear_1580_157)" />
          <text
            x="15"
            y="30"
            font-family="Inter"
            font-size="12"
            fill="white"
            fontWeight="700"
          >
            FAQ
          </text>
          <defs>
            <linearGradient
              id="paint0_linear_1580_157"
              x1="27"
              y1="0"
              x2="27"
              y2="54"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.385417" stop-color="#119750" />
              <stop offset="1" stop-color="#119750" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div>
        {isHovering && (
          <Card
            style={{
              color: "#000000",
              width: "408px",
              height: "450px",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: "0px 19px 19px 0px",
              position: "absolute",
              zIndex: "99",
              marginTop: "-30rem",
              marginLeft: "4rem",
              overflowY: "scroll",
            }}
            className="faq-card shadow-xl shadow-intensity-xl shadow shadow-offset-down-lg shadow-offset-left-lg cardShadow card"
          >
            <div
              className="d-flex align-items-center justify-content-center w-full"
              style={{
                backgroundColor: "#47BE9B",
                borderRadius: "0px 19px 19px 0px",
                height: "60px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
            >
              <h1 className="text-white font-bold">FAQ</h1>
            </div>
            <div
              className="d-flex flex-col mt-4 p-2 mx-2"
              style={{
                backgroundColor: "rgba(216, 216, 216, 0.33)",
                borderRadius: "10px",
              }}
            >
              {/* <FaArrowAltCircleUp size={32} color="green" /> */}
              <div className="d-flex align-items-center">
                <HiOutlineCurrencyRupee
                  size={20}
                  className="mr-2"
                  color="black"
                />
                <h1
                  onClick={() => {
                    setFaq1(!faq1);
                    setFaq2(false);
                    setFaq3(false);
                  }}
                  style={{ color: "#455768", cursor: "pointer" }}
                >
                  How will new members be billed ?
                </h1>
              </div>
              {/* <FaArrowAltCircleDown size={32} color="red" /> */}
              <Collapse isOpen={faq1}>
                <p className="p-3" style={{ fontSize: "12px" }}>
                  We’ll make a prorated charge to your credit card to cover your
                  new team member’s account for the remainder of the current
                  billing period. <br />
                  <b>As an example,</b> suppose your team is on the Pro
                  subscription, which costs ₹655 per active user per month when
                  billed monthly. You add a new team member ten days into your
                  billing period, and there are 20 remaining days in the month.
                  The per-day cost for each user is ₹655/30, so we will make a
                  one-time charge of ₹436.67 (₹655/30 days * 20 days) to your
                  team’s credit card on file.
                </p>
              </Collapse>
            </div>

            {/* SECOND COLLAPSIBLE */}
            <div
              className="d-flex flex-col mt-4 p-2 mx-2"
              style={{
                backgroundColor: "rgba(216, 216, 216, 0.33)",
                borderRadius: "10px",
              }}
            >
              {/* <FaArrowAltCircleUp size={32} color="green" /> */}
              <div className="d-flex align-items-center">
                <RiRefundFill size={20} className="mr-2" color="black" />
                <h1
                  onClick={() => {
                    setFaq2(!faq2);
                    setFaq1(false);
                    setFaq3(false);
                  }}
                  style={{ color: "#455768", cursor: "pointer" }}
                >
                  How can I cancel my subscription? Will I get the refund?
                </h1>
              </div>
              {/* <FaArrowAltCircleDown size={32} color="red" /> */}
              <Collapse isOpen={faq2}>
                <p className="p-3" style={{ fontSize: "12px" }}>
                  Sorry, we don't process any refunds as of now.
                </p>
              </Collapse>
            </div>

            {/* THIRD COLLAPSIBLE */}
            <div
              className="d-flex flex-col mt-4 p-2 mx-2"
              style={{
                backgroundColor: "rgba(216, 216, 216, 0.33)",
                borderRadius: "10px",
              }}
            >
              {/* <FaArrowAltCircleUp size={32} color="green" /> */}
              <div className="d-flex align-items-center">
                <MdPayments size={20} className="mr-2" color="black" />
                <h1
                  onClick={() => {
                    setFaq3(!faq3);
                    setFaq1(false);
                    setFaq2(false);
                  }}
                  style={{ color: "#455768", cursor: "pointer" }}
                >
                  What are available payment options?
                </h1>
              </div>
              {/* <FaArrowAltCircleDown size={32} color="red" /> */}
              <Collapse isOpen={faq3}>
                <p className="p-3" style={{ fontSize: "12px" }}>
                  We only support payments through card as of now.
                </p>
              </Collapse>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default FAQ;
