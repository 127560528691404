import React from "react";
import "../SubComponent/NewLogin/NewLogin.css";
import LoginForm from "views/SubComponent/NewLogin/LoginForm";
import Slideshow from "views/SubComponent/NewLogin/Slideshow";

const NewLogin = (props) => {
  //   const fontStylee = {
  //     fontFamily: "AvertaStd-Regular",
  //   };
  return (
    // <>
    <div className="d-flex centerOnIpad">
      {/* For medium and larger screens */}
      <div className="d-none d-md-block custom-no-padding col-md-7">
        <Slideshow />
      </div>

      {/* For smaller screens */}
      <div className="col-md-5 ">
        <LoginForm props={props} />
      </div>
    </div>
    // </>
  );
};

export default NewLogin;
