import React from "react";
import cx from "classnames";
import "../../styles/ToggleSwitch.css";

const ToggleSwitch = ({
	roundedSwitch = true,
	isToggled,
	onToggle,
	enable,
	disable,
}) => {
	const t = JSON.parse(localStorage.getItem("twoFa"));

	const sliderCX = cx("slideToggle", { roundedSwitch: roundedSwitch });
	// console.log(isToggled);
	return (
		<label className='switch'>
			<input
				type='checkbox'
				checked={t && isToggled}
				onChange={onToggle}
				onClick={t ? disable : enable}
			/>
			<span className={sliderCX}></span>
		</label>
	);
};

export default ToggleSwitch;
